import React, { useEffect, useState } from "react";
// import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import { useHistory } from 'react-router-dom';

//from lib
import toast from "react-hot-toast";
import moment from "moment";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';

//App components
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../BottomNav/BottomNav";
import SplashLoader from "../SplashLoader";
import api from "../../Service/apiService";
//API URL
import CONSTANTS from "../../Constants/global";
import TermsAndCondition from "../../Constants/termsAndcond";

export default function SingleQuizDetail({ broadcastMessage }) {
  const navigate = useNavigate();
  //const history = useHistory();
  const [obj, setObj] = useState(broadcastMessage);
  const location = useLocation();
  const [locationState, setLocationState] = useState({ contestName: "" });
  const [currentContestId, setCurrentContestId] = useState();
  const [currentContestName, setCurrentContestName] = useState();
  const [entryFee, setEntryFee] = useState();
  const [activePlayerCount, setActivePlayerCount] = useState();
  const [appliedPlayerCount, setAppliedPlayerCount] = useState();
  const [totalPrize, setTotalPrize] = useState();
  const [isApplied, setIsApplied] = useState();
  const [quizStartTime, setQuizStartTime] = useState();
  const [newpoll, setNewPoll] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [lgShowsss, setLgShowsss] = useState(false);
  const [startsin, setStartsin] = useState();
  const [contestEndTime, setContestEndTime] = useState();
  const [showPlaybtn, setShowPlaybtn] = useState(false);

  const [detailsLoading, setDetailsLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [showTermsAndConds, setTermsandCondsShow] = useState(false);
  const handleTermsandCondsClose = () => setTermsandCondsShow(false);
  const handleTermsandCondsShow = () => setTermsandCondsShow(true);

  useEffect(() => {
    console.log("value of 'a' changed to", broadcastMessage);
    console.log("isApplied", isApplied, locationState)
    if(broadcastMessage.length && broadcastMessage[broadcastMessage.length-1].eventType == "CONTEST_STARTED" && isApplied){
      PlayNow(locationState.contestid, broadcastMessage[broadcastMessage.length-1])
    }
  }, [broadcastMessage]);

  const calculateDifference = (contestTime) => {
    let contestDateTime = moment(contestTime);
    // console.log(contestDateTime)
    let currentdate = new Date();
    let currentTime = moment(currentdate).format("h:mm:ss");
    // console.log(currentdate)
    let newdifference = contestDateTime - currentdate;
    // console.log(newdifference)
    return Math.floor(newdifference / 1000);
  };
  //get detailed view of contest
  const getDetailedView = async (currentContestId) => {
    setDetailsLoading(true);

    const DETAIL_VIEW_URL = CONSTANTS.DETAILVIEWCONTEST;
    //console.log(DETAIL_VIEW_URL);
    //  const token = sessionStorage.getItem("token");
    let dataToPost = { contestid: currentContestId };
    //  const HEADER = { "authorization": token, }
    try {
      const res = await api.post(DETAIL_VIEW_URL, dataToPost);
      if (res && res?.data) {
        console.log(res.data);
        let response = res?.data;
        setEntryFee(response?.entryamount);
        setActivePlayerCount(response?.liveplayers);
        setAppliedPlayerCount(response?.appliedusers);
        setTotalPrize(response?.totalwinningamount);
        setIsApplied(response?.isApplied);
        setCurrentContestName(response?.contestName);
        setStartsin(calculateDifference(response?.contestTime));
        setContestEndTime(response?.contestEndTime);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
        if (
          error.response.data.message == "Contest is over" ||
          error.message == "Contest is over"
        ) {
          navigate("/appuserhome");
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setDetailsLoading(false);
    }
  };
  // Apply to a contest
  const applyContest = async (item) => {
    setBtnLoading(true);
    const APPLY_URL = `${CONSTANTS.APPLYCONTEST}`;
    //  const token = sessionStorage.getItem("token");
    let sendToData = { contestid: item };
    // const HEADER = { "authorization": token, }

    try {
      const post = await api.post(APPLY_URL, sendToData);
      if (post && post?.data) {
        getDetailedView(locationState.contestid);
        console.log(post.data);
        toast.success(post.data.message);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
        if (
          error.response.data.message == "Contest is over" ||
          error.message == "Contest is over"
        ) {
          navigate("/appuserhome");
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setBtnLoading(false);
    }
  };
  //Play Now- First check if user already sted to play. If status 1 then he will enter to the contest, if status is 2 he have to pay some amount
  const PlayNow = async (result, serverTime) => {
    setBtnLoading(true);
    sessionStorage.removeItem("questionIndex");
    const playcheck_URL = CONSTANTS.CONTESTPLAYCHECK;

    //   const token = sessionStorage.getItem("token");
    let dataToSend = { contestid: result };
    //  const HEADER = { "authorization": token, }
    try {
      const post = await api.post(playcheck_URL, dataToSend);
      if (post && post?.data) {
        if (post.data.status == 1) {
          console.log(post.data.question1, "questions");
          navigate("/livecontestnew", {
            state: {
              question1: post.data.question1,
              totalquestions: post.data.totalquestions,
              ContestTime: post.data.contestTime,
              InititalUsers: post.data.totalIntitalUsers,
              entryamount: entryFee,
              contestid: result,
              questionIndex: post.data.questionIndex,
              serverTime: serverTime
            },
          });
        }
        if (post.data.status == 2) {
          // localStorage.clear('success')
          setAllData([
            post.data.question1,
            post.data.totalquestions,
            post.data.contestTime,
            entryFee,
            result,
          ]);
          setNewPoll([post.data.entryamount, post.data.particularPoll]);
          setLgShowsss(true);
        }
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
        if (
          error.response.data.message == "Contest is over" ||
          error.message == "Contest is over"
        ) {
          navigate("/appuserhome");
        }
      } else {
        toast.error(error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //     toast.error(error.response.data.message);
      // }
      //toast.error(e.response.data.message)
    } finally {
      setBtnLoading(false);
    }
  };

  const entered = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const payNewPoll_URL = CONSTANTS.PAYNEWPOLLAMOUNT;

    //   const token = sessionStorage.getItem("token");
    let dataToSend = { contestid: alldata[4] };
    //  const HEADERS = { "authorization": token, }
    try {
      const post = await api.post(payNewPoll_URL, dataToSend);
      if (post && post?.data) {
        if (post.data.status == 1) {
          console.log("aaaaaa", alldata[0], alldata[1], alldata[2]);
          navigate("/livecontestnew", {
            state: {
              question1: alldata[0],
              totalquestions: alldata[1],
              ContestTime: alldata[2],
              InititalUsers: post.data.totalIntitalUsers,
              entryamount: alldata[3],
              contestid: alldata[4],
              questionIndex: post.data.questionIndex,
            },
          });
        }
      }
      //TO DO : need to remove after testing
      // let post = await axios.post(payNewPoll_URL, dataToSend, {
      //     headers: HEADERS,
      // });

      // if (post.data.status == 1) {
      //     console.log("aaaaaa", alldata[0], alldata[1], alldata[2])
      //     navigate("/livecontestnew", { state: { question1: alldata[0], totalquestions: alldata[1], ContestTime: alldata[2], InititalUsers: post.data.totalIntitalUsers, entryamount: alldata[3], contestid: alldata[4], questionIndex: post.data.questionIndex } })
      // }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //     toast.error(error.response.data.message);
      // }
      // toast.error(e.response.data.message)
    } finally {
      setBtnLoading(false);
    }
  };
  useEffect(() => {
    if (!location?.state) {
      navigate("/appuserhome");
    }

    if (location.state) {
      let _state = location.state;
      setLocationState(_state);
      // console.log('current contest id is ', location);
      setCurrentContestId(_state.id);
      getDetailedView(_state.contestid);
    }
  }, [location]);
  const renderTime = ({ remainingTime }, endTime) => {
    if (remainingTime === 0) {
      return (
        <div className="timer text-dark-orange">
          {!moment(endTime).isBefore(new Date()) ? "Live" : "Expired"}
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="value fs-6 text-blue">{remainingTime}</div>
      </div>
    );
  };
  return (
    <>
      {" "}
      {detailsLoading ? (
        <SplashLoader />
      ) : (
        <div>
          <AppHeader />
          <div className="inner-page-container">
            <div className="container-fluid" style={{paddingBottom:'70px'}} >
              {/* inner-page-top starts */}
              {/* <section>HELLO BHAYO ::::: {broadcastMessage.length && broadcastMessage[broadcastMessage.length-1].eventType} :::: {isApplied}</section> */}
              <section className="row inner-page-top bg-white border-bottom-left-round">
                <div className="d-flex align-items-center justify-content-between p-2 pt-3">
                  <p className="inner-page-heading">{currentContestName}</p>
                  <CountdownCircleTimer
                    isPlaying
                    duration={startsin}
                    initialRemainingTime={startsin}
                    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                    colorsTime={[15, 10, 5, 0]}
                    size={70}
                    strokeWidth={5}
                    onComplete={() => ({ shouldRepeat: false, delay: 0 })}
                    onUpdate={(remainingTime) => {
                      if (remainingTime == 5) {
                      }
                    }}
                  >
                    {(e) => renderTime(e, contestEndTime)}
                  </CountdownCircleTimer>
                </div>
              </section>
              {/* inner-page-top Ends */}
              {/* single Entry fee card starts */}
              <section className="single-quiz-entry-fee">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card-parent-gray bg-light-gray">
                      <div className="single-q-fee-box  width-80 bg-dark-orange  py-5 d-flex justify-content-center">
                        <div className="card entry-fee-card small my-2">
                          <div className="card-header bg-yellow-orange text-black">
                            ENTRY FEE
                          </div>
                          <div className="card-body bg-dark-gray">
                            <div className="card-text">
                              <div className="text-white text-center fs-1">
                                <i className="big coin pe-5"></i> {entryFee}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>{" "}
              {/* single Entry fee card Ends */}
              {/*Active player ribbon starts */}
              <section className="active-player">
                <div className="row">
                  <div className="col-xs-12 col-md-12">
                    <div className="info-oval-ribbon d-flex justify-content-between bg-dark-orange ps-5 py-3">
                      <div className="user-icon bg-black">
                        <span className="">
                          <i className="fas fa-users fa-2x text-white"></i>
                        </span>
                      </div>
                      <div className="user-no">
                        <p className="mb-0 text-info-gray">Active Players</p>
                        <p className="mb-0 fw-500  fs-1 text-center text-white">
                          {activePlayerCount
                            ? activePlayerCount
                            : appliedPlayerCount}
                        </p>
                      </div>
                      <div className="user-grp-icons">
                        <img src="./assets/images/profile.png" alt="" />
                        <img src="./assets/images/profile.png" alt="" />
                        <img src="./assets/images/profile.png" alt="" />
                      </div>
                    </div>
                    <div className="info-oval-ribbon d-flex justify-content-center bg-dark-orange py-3">
                      <div className="user-no">
                        <p className="mb-0 text-info-gray">
                          Total winning prize
                        </p>
                        <p className="mb-0 fs-1 text-white">
                          <i className="big coin pe-5"></i>
                          {totalPrize}{" "}
                        </p>
                      </div>
                    </div>
                    {isApplied ? (
                      // <Link to={"/livecontestnew"}  state= {{question1: alldata[0], totalquestions: alldata[1], ContestTime: alldata[2], InititalUsers: post.data.totalIntitalUsers, entryamount: alldata[3], contestid: alldata[4], questionIndex: post.data.questionIndex }}>
                      <div className="info-oval-ribbon bg-dark-orange py-3 text-center">
                        <p className="text-info-gray text-center fs-1">
                          Good Luck !
                        </p>
                        {/* <p className='text-white text-center fs-3'>Starting in 2:34 seconds</p> */}
                        {!showPlaybtn ? (
                          <div className="start-in-time position-relative goodlucktimer">
                            <span className="countdown-wrapper text-white position-absolute">
                              <CountdownCircleTimer
                                isPlaying
                                duration={startsin}
                                initialRemainingTime={startsin}
                                colors={[
                                  "#004777",
                                  "#F7B801",
                                  "#A30000",
                                  "#A30000",
                                ]}
                                colorsTime={[15, 10, 5, 0]}
                                size={70}
                                strokeWidth={5}
                                onComplete={() => ({
                                  shouldRepeat: false,
                                  delay: 0,
                                })}
                                onUpdate={(remainingTime) => {
                                  if (remainingTime == 0) {
                                    setShowPlaybtn(true);
                                  }
                                }}
                              >
                                {(e) => renderTime(e, contestEndTime)}
                              </CountdownCircleTimer>
                            </span>
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-sm btn-success"
                            disabled= 'true'
                            // disabled={
                            //   btnLoading ||
                            //   moment(contestEndTime).isBefore(new Date())
                            // }
                            // onClick={() => {
                            //   PlayNow(locationState.contestid);
                            // }}
                          >
                            Loading...
                            {/* {btnLoading ? "Loading..." : "Play"} */}
                          </button>
                        )}
                      </div>
                    ) : (
                      // </Link>

                      <div className="info-oval-ribbon bg-dark-orange">
                        <p className="mb-0 text-info-gray text-center">
                          Enter Competition
                        </p>
                        <div className="d-flex justify-content-between py-3">
                          <button
                            onClick={() => navigate(-1)}
                            className="user-icon ms-3 bg-light-gray ps-2"
                          >
                            <span className="">
                              <i className="fas fa-times fa-2x text-white px-1"></i>
                            </span>
                          </button>
                          <div className="user-no">
                            <div className="text-white text-center fs-1">
                              <i className="big coin pe-5"></i> {entryFee}
                            </div>
                          </div>
                          <button
                            className="user-icon me-3 bg-dark-gray"
                            disabled={btnLoading}
                            onClick={handleTermsandCondsShow}
                          >
                            <span className="">
                              <i className="fas fa-check fa-2x text-white"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              {/* Active player ribbon ends */}
            </div>
          </div>
          <BottomNav />
          <Modal
            size="md"
            show={lgShowsss}
            onHide={() => setLgShowsss(false)}
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            {/* <Modal.Title id="example-modal-sizes-title-lg">
         Do You Want To Enter in Contest ?
          </Modal.Title> */}

            <Modal.Body>
              {/* <form>
        <div className="form-group">
       <label for="exampleInputEmail1">Entry Amount : {newpoll && newpoll[0]}</label>
       <label for="exampleInputEmail1">Current Entry Amount : {newpoll && newpoll[1]}</label>
       <label for="exampleInputEmail1">You Have To Pay : {newpoll && (parseInt(newpoll[1])-(parseInt(newpoll[0])))}</label>
       
       </div>
       <button type="submit" className="btn btn-primary" onClick={entered}>Yes</button>&nbsp;
       <button type="submit" className="btn btn-danger" onClick={(e)=>{ e.preventDefault(); setLgShowsss(false)}}>Close</button>
       </form> */}

              <div className="modal-content p-1">
                <div className="text-center">
                  <i className="fa fa-bell re-enter-pay-bell"></i>
                </div>
                <h3>Do You Want To Enter in Contest ?</h3>
                <p className="re-enter-pay-text px-md-5 px-sm-1">
                  Entry Amount : {newpoll && newpoll[0]}
                </p>
                <p className="re-enter-pay-text px-md-5 px-sm-1">
                  Current Entry Amount : {newpoll && newpoll[1]}
                </p>
                <p className="re-enter-pay-text px-md-5 px-sm-1">
                  You Have To Pay :{" "}
                  {newpoll && parseInt(newpoll[1]) - parseInt(newpoll[0])}
                </p>
                <div className="text-center mb-3">
                  {" "}
                  <button
                    className="btn btn-gray"
                    onClick={(e) => {
                      e.preventDefault();
                      setLgShowsss(false);
                    }}
                  >
                    Not now
                  </button>{" "}
                  <button
                    className="btn btn-orange px-4 ms-2"
                    disabled={btnLoading}
                    onClick={(e) => entered(e)}
                  >
                    {btnLoading ? "Loading..." : "Yes"}
                  </button>{" "}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={showTermsAndConds} onHide={handleTermsandCondsClose}>
            <Modal.Header closeButton style={{ position: 'sticky', top: 0, zIndex: 999 }}>
              <Modal.Title>Terms & Conditions</Modal.Title>
            </Modal.Header >
               <Modal.Body style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 150px)', padding: '15px' }}>
                <TermsAndCondition></TermsAndCondition>
               </Modal.Body>
            <Modal.Footer style={{ position: 'sticky', bottom: 0, zIndex: 999 ,padding: '5px'}}>
              <Button variant="secondary" onClick={handleTermsandCondsClose}>
                Close
              </Button>
              <Button variant="primary" onClick={() => {
                              applyContest(locationState.contestid);
                              handleTermsandCondsClose()
                            }}>
                Agree
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}{" "}
    </>
  );
}
