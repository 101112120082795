import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
// icons
import { AiOutlineBank } from "react-icons/ai";
import { BsPatchCheckFill, BsFillExclamationCircleFill } from "react-icons/bs";
import { ImWarning } from "react-icons/im";
// components
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../../Components/BottomNav/BottomNav";
import Loader from "../../Components/Loader";
// utils and helpers
import { ACTIONS, useAppContext } from "../../context/AppContext";
import CONSTANTS from "../../Constants/global";
import api from "../../Service/apiService";
import ReactPaginate from "react-paginate";
const PAGE_LIMIT = 10;

const Payment = () => {
  const { appState, updateAppState } = useAppContext();
  const navigate = useNavigate();

  const [previousFundAccounts, setPreviousFundAccounts] = useState([]);
  const [bankVerificationFees, setBankVerificationFees] = useState(0);
  const [loading, setLoading] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalOrderCount, setTotalOrderCount] = useState(0)

  const getTransactionHistory = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `${CONSTANTS.PAYMENT_HISTORY}?page=${pageNumber}&column=date&OrderBy=-1`
      );
      setTransactionHistory(response.data?.order);
      setTotalOrderCount(response.data?.totalOrderCount);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (item) => {
    let pageclicked = item.selected + 1;
    setPageNumber(pageclicked);
  };

  const getWalletDetails = async () => {
    try {
      const res = await api.get(CONSTANTS.GETWALLETAMOUNT);
      updateAppState(ACTIONS?.WALLET, res?.data?.userwallet);
      updateAppState(ACTIONS?.IS_VERIFIED, res?.data?.isVerified);
      updateAppState(ACTIONS.PROFILE_IMAGE, res.data?.profileImage);
      updateAppState(ACTIONS?.KYC_EXPIRED, res?.data?.isKycExpired);
      updateAppState(ACTIONS?.PHONE_VERIFIED, res?.data?.isPhoneVerified);
      updateAppState(ACTIONS?.PHONE, res?.data?.phone);

      localStorage.setItem("name", res?.data?.name);
    } catch (error) {
      console.error(error);
    }
  };

  const getPreviousFundAccounts = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${CONSTANTS.PAYMENT_FUND_LIST}`);
      setPreviousFundAccounts(response.data?.fund_account_list);
      setBankVerificationFees(response.data?.bank_verification_fees);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWalletDetails();
    getPreviousFundAccounts();
  }, []);

  useEffect(() => {
    getTransactionHistory();
  }, [pageNumber]);

  const getStatusColor = (status) => {
    return status === "processed"
      ? "text-success"
      : status?.toLowerCase() === "processing"
        ? "text-warning"
        : "text-danger";
  };

  return (
    <div>
      <AppHeader />
      <div className="page-container h-91 bg-white px-2 overflow-y-scroll px-4">
        {appState?.isVerified && !appState?.isKycExpired ? (
          <>
            <h1 className="mt-3">Deposit</h1> <hr className="m-0 mb-3" />
            <button
              onClick={() => navigate("/payment/deposit")}
              className="my-4 rounded-3 border-0 btn btn-primary  w-100 px-5 py-2 fs-3 d-flex align-items-center justify-content-center gap-2"
            >
              Add money to wallet
            </button>
            <br />
            <h1 className="mt-3">Withdraw</h1> <hr className="m-0 mb-3" />
            <div>
              <p className="fw-bold m-0">Add payment method:</p>
              <div>
                <div
                  className="btn-deposit rounded-3 text-white w-100 px-5 py-2 fs-3 d-flex align-items-center justify-content-center gap-2"
                  onClick={() =>
                    navigate("/payment/verify", {
                      state: {
                        type: "bank_account",
                      },
                    })
                  }
                >
                  Bank Transfer
                  <AiOutlineBank />
                </div>

                <button
                  className="my-4 rounded-3 border-0 btn btn-primary  w-100 px-5 py-2 fs-3 d-flex align-items-center justify-content-center gap-2"
                  onClick={() =>
                    navigate("/payment/verify", {
                      state: {
                        type: "vpa",
                      },
                    })
                  }
                >
                  UPI
                  <img
                    src="/vendors/images/upi.png"
                    style={{ width: "20px" }}
                  />
                </button>

                <span className="text-success">
                  Note: Adding payment method will cost Rs.
                  {bankVerificationFees}
                </span>
              </div>
            </div>
            <hr />
            <div>
              <p className="fw-bold">Withdraw:</p>
              <div>
                {loading ? (
                  <Loader />
                ) : previousFundAccounts?.length > 0 ? (
                  <>
                    <div className="d-flex flex-column gap-3">
                      {previousFundAccounts?.map((fund) => (
                        <div
                          className={`fund_account_box align-items-center justify-content-between`}
                          key={fund?.id}
                          onClick={() => {
                            if (fund.isExpired)
                              return toast.error(
                                "The payment method is expired, Add the method again"
                              );
                            navigate("/payment/withdraw", { state: fund });
                          }}
                        >
                          <div
                            className={`d-flex ${fund?.account_number
                                ? "align-items-start"
                                : "align-items-center"
                              }`}
                          >
                            <input type="radio" className="mx-2 my-4" />
                            {fund?.account_number ? (
                              <div className="mx-2">
                                <p className="m-0 fs-6 fw-bold">{fund?.name}</p>
                                <div>
                                  <span>{fund?.bank_name}</span>
                                  <span> - IFSC: {fund?.ifsc}</span> <br />
                                  <span>A/c No. {fund?.account_number}</span>
                                </div>
                              </div>
                            ) : (
                              <div className="mx-2">
                                <p className="m-0 fs-6 fw-bold">
                                  {fund?.upi_address}
                                </p>
                              </div>
                            )}
                          </div>

                          {fund.isExpired ? (
                            <div className="d-flex flex-column align-items-center me-2">
                              <ImWarning
                                color="#f7b011"
                                style={{ fontSize: "20px" }}
                              />
                              <span>Expired</span>
                            </div>
                          ) : (
                            <BsPatchCheckFill
                              color="green"
                              style={{ marginRight: "1rem", fontSize: "25px" }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <p className="m-0 text-orange">
                    *Please add payment method for withdrawal
                  </p>
                )}
              </div>
            </div>
            <div>
              <h1 className="fs-1 fw-bold mt-5">Transactions</h1>{" "}
              <hr className="m-0" />
              <div>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <table className="payment_table mt-3">
                      <thead>
                        <tr className="payment_tr">
                          <th className="payment_th">#</th>
                          <th className="payment_th">Status</th>
                          <th className="payment_th">Amount</th>
                          <th className="payment_th">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!transactionHistory?.length ? (
                          transactionHistory?.map((transaction, tranIdx) => (
                            <tr className="payment_tr" key={transaction?.id}>
                              <td className="payment_td">
                                {(pageNumber - 1) * PAGE_LIMIT + tranIdx + 1}
                              </td>
                              <td
                                className={`payment_td text-capitalize ${getStatusColor(
                                  transaction?.status
                                )}`}
                              >
                                {transaction?.status ?? "Failed"}
                              </td>
                              <td className="payment_td">{transaction?.amount}</td>
                              <td className="payment_td">
                                {new Date(transaction?.date)?.toLocaleDateString('en-GB')}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-danger">
                            <td colSpan={4}>No transactions found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    {!!transactionHistory?.length && (
                      <div className="col-sm-12 d-flex justify-content-center my-4">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          previousLabel="< previous"
                          pageCount={Math.ceil(totalOrderCount / PAGE_LIMIT)}
                          forcePage={pageNumber - 1}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination pagination-sm"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : appState?.isKycExpired ? (
          <div className="h-100 d-flex flex-column align-items-center justify-content-center">
            <BsFillExclamationCircleFill
              className="text-warning my-3"
              style={{ fontSize: "3rem" }}
            />
            <p>Your KYC is expired</p>

            <Link to="/kyc-verification" className="btn btn-success">
              Click here to Verify KYC
            </Link>
          </div>
        ) : (
          <div className="h-100 d-flex flex-column align-items-center justify-content-center">
            <BsFillExclamationCircleFill
              className="text-warning my-3"
              style={{ fontSize: "3rem" }}
            />
            <p>Please complete your KYC verification process</p>

            <Link to="/kyc-verification" className="btn btn-success">
              Click here to Verify KYC
            </Link>
          </div>
        )}
      </div>
      <BottomNav />
    </div>
  );
};

export default Payment;
