import axios from "axios";
import api from "./apiService";

export const uploadImage = async (selectedFile) => {
  const name = selectedFile.name;
  const lastDot = name.lastIndexOf(".");
  const ext = name.substring(lastDot + 1);

  try {
    const response = await api.get(
      `${process.env.REACT_APP_BASE_URL}/users/upload?fileType=${ext}`
    );

    await axios.put(response?.data?.url, selectedFile, {
      headers: {
        "Content-Type": selectedFile?.type,
      },
    });

    return response?.data?.key;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? error?.message);
  }
};
