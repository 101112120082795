import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// icons
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
// components
import AppHeader from "../../AppHeader/AppHeader";
import SplashLoader from "../../SplashLoader";
// utils and helpers
import CONSTANTS from "../../../Constants/global";
import api from "../../../Service/apiService";
import { ACTIONS, useAppContext } from "../../../context/AppContext";

export default function AdsList() {
  const [adList, setAdList] = useState([]);
  const [adsLoading, setAdsLoading] = useState(false);
  const [adIndex, setAdIndex] = useState(0);

  const { updateAppState } = useAppContext();

  useEffect(() => {
    window.onpopstate = function (e) {
      window.history.pushState({}, "", "/appuserhome");
    };
  }, []);

  //get Ads List
  const getAdList = async () => {
    setAdsLoading(true);

    const getAdList_URL = CONSTANTS.GETADLIST;
    try {
      const res = await api.get(getAdList_URL);
      if (res && res.data) {
        setAdList([...res?.data?.ads]);
        updateAppState(ACTIONS?.WALLET, res?.data?.wallet);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setAdsLoading(false);
    }
  };

  const prevAd = () => {
    setAdIndex((prev) => (prev === 0 ? adList?.length - 1 : prev - 1));
  };
  const nextAd = () => {
    setAdIndex((prev) => (prev === adList?.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    getAdList();

    if (window.NativeHook) {
      window.NativeHook.setAppStatusBarColour("#FFFFFF");
    } else if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.setAppStatusBarColour.postMessage(
        "#FFFFFF"
      );
    }
  }, []);

  return (
    <>
      {adsLoading ? (
        <SplashLoader />
      ) : (
        <>
          <AppHeader />
          <div
            className="inner-page-container overflow-y-scroll pb-0 bg-darker-gray"
            style={{ height: "99vh" }}
          >
            <div className="container-fluid">
              {/* inner-page-top starts */}
              <section className="inner-page-top ">
                <div className="row ">
                  <div className="col-xs-12 bg-white border-bottom-left-round">
                    <div className="d-flex justify-content-between py-3">
                      <h1 className="inner-page-heading ps-3">
                        Advertisements
                      </h1>
                    </div>
                  </div>
                </div>
              </section>

              <div className="row px-5 pt-4">
                {adList.length > 0 ? (
                  <AdCardComponent ads={adList} adIndex={adIndex} />
                ) : (
                  <>
                    <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                      <img src="./assets/images/no_data.svg" alt="No data" />

                      <p className="text-center text-white">
                        No Advertise Match with your profile.
                      </p>
                    </div>
                  </>
                )}
              </div>

              <div className="adlist_bottom_div">
                <p onClick={prevAd}>
                  <BsFillArrowLeftCircleFill />
                </p>

                {/* <p>{adIndex+1}/{adList?.length}</p> */}

                <p onClick={nextAd}>
                  <BsFillArrowRightCircleFill />
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const AdCardComponent = ({ ads, adIndex }) => {
  const ad = ads?.length ? ads[adIndex] : null;
  const navigate = useNavigate();

  if (!ad) return "Something went wrong";

  return (
    <>
      <div
        key={ad?.id}
        className="ad-list-card small my-2 bg-white px-4 pt-4"
        style={{ maxHeight: "60vh" }}
        onClick={() =>
          navigate("/livead", {
            state: { adId: ad.id },
          })
        }
      >
        <div className="mt-4">
          <img
            src={`${process.env.REACT_APP_AD_IMAGE_PATH_URL}${ad.adImage}`}
            alt="No image for current Advertisement"
          />
          <h1
            className="my-3 fw-bold text-center"
            style={{ wordBreak: "break-all" }}
          >
            {ad?.adName?.length > 20
              ? ad?.adName.slice(0, 20) + "..."
              : ad?.adName}
          </h1>{" "}
          <hr />
          <p className="text-center fs-7">Give the right answer and win</p>
          <p className="text-center">
            <span className="gold-coin-big me-2"></span>
            <span className="fs-3" key={ad.winningAmount}>
              {ad.winningAmount} | Q/A - {ad?.questionLength}
            </span>
          </p>
        </div>
      </div>
      {/* <div className="ad-list-card-behind-1"></div> */}
      {/* <div className="ad-list-card-behind-2"></div> */}
    </>
  );
};
