import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

//API URL
import CONSTANTS from "../../Constants/global";

//import common components
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../../Components/BottomNav/BottomNav";
import Loader from "../../Components/Loader";
import api from "../../Service/apiService";

import toast from "react-hot-toast";
import { ACTIONS, useAppContext } from "../../context/AppContext";

const PAGE_LIMIT = 10;

export default function UserWallet() {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const { appState, updateAppState } = useAppContext();

  const getTransactionHistory = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `${CONSTANTS.WALLET_HISTORY}?page=${pageNumber}&column=date&OrderBy=-1`
      );
      setTransactionHistory(response.data?.order);
      setTotalOrderCount(response.data?.totalOrderCount);
      // setPageNumber(response.data?.page);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getWalletDetails = async () => {
    const _URL = CONSTANTS.GETWALLETAMOUNT;
    try {
      const res = await api.get(_URL);
      if (res && res?.data && res?.data?.userwallet) {
        updateAppState(ACTIONS?.WALLET, res?.data?.userwallet);
        updateAppState(ACTIONS?.IS_VERIFIED, res?.data?.isVerified);
        updateAppState(ACTIONS.PROFILE_IMAGE, res.data?.profileImage);
        updateAppState(ACTIONS?.KYC_EXPIRED, res?.data?.isKycExpired);
        updateAppState(ACTIONS?.PHONE_VERIFIED, res?.data?.isPhoneVerified);
        updateAppState(ACTIONS?.PHONE, res?.data?.phone);

        localStorage.setItem("name", res?.data?.name);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    getWalletDetails();
  }, []);

  useEffect(() => {
    getTransactionHistory();
  }, [pageNumber]);

  const handlePageClick = (item) => {
    let pageclicked = item.selected + 1;
    setPageNumber(pageclicked);
  };

  const getStatusColor = (status) => {
    return status === "Credit"
      ? "text-success"
      : status?.toLowerCase() === "debit"
        ? "text-danger"
        : "text-warning";
  };

  return (
    <>
      <AppHeader />
      <div className="page-container h-91 bg-white px-2 overflow-y-scroll">
        <div>
          <section className="wallet-top">
            <div>
              <div className="col-sm-12 col-xs-12">
                <div className="text-section py-2">
                  <h1 className="fs-1 fw-bold">Wallet</h1>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="bg-white">
              <div className="text-center coin-section mx-2">
                <p className="wallet-page-coin fw-bold text-dark-orange mt-3">
                  {appState?.wallet} <span className=" ms-2 big coin"></span>
                </p>
                <p className="position-relative">Total Wallet Amount</p>
              </div>
            </div>
          </section>
        </div>
        <div>
          <h1 className="fs-1 fw-bold mt-5">Transactions</h1>{" "}
          <hr className="m-0" />
          <div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <table className="payment_table mt-3">
                  <thead>
                    <tr className="payment_tr">
                      <th className="payment_th">#</th>
                      <th className="payment_th">Status</th>
                      <th className="payment_th">Amount</th>
                      <th className="payment_th">Source</th>
                      <th className="payment_th">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!transactionHistory?.length ? (
                      transactionHistory?.map((transaction, tranIdx) => (
                        <tr className="payment_tr" key={transaction?.id}>
                          <td className="payment_td">
                            {(pageNumber - 1) * PAGE_LIMIT + tranIdx + 1}
                          </td>
                          <td
                            className={`payment_td text-capitalize ${getStatusColor(
                              transaction?.status
                            )}`}
                            style={{ fontWeight: 'bold' }}
                          >
                            {transaction?.status ?? "Failed"}
                          </td>
                          <td className="payment_td">{transaction?.amount}</td>
                          <td className="payment_td">{transaction?.source}</td>
                          <td className="payment_td">
                            {new Date(transaction?.date)?.toLocaleDateString('en-GB')}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-danger">
                        <td colSpan={4}>No transactions found</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {!!transactionHistory?.length && (
                  <div className="col-sm-12 d-flex justify-content-center my-4">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      previousLabel="< previous"
                      pageCount={Math.ceil(totalOrderCount / PAGE_LIMIT)}
                      forcePage={pageNumber - 1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination pagination-sm"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
