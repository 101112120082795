import { useNavigate  } from "react-router-dom";
import "./fifthScreen.css";

function FifthScreen() {
  const navigate = useNavigate ();
  const handleStartPlayingClick = () => {
    // Redirect to the login screen
    navigate("/");
  };

  return (
    <>
      <img className="logo" src="/assets/images/logo.png" />
      <img className="main-image" src="/assets/images/keep_playing.jpeg" />
      <div className="text-container">
        <h2 className="heading">Keep Playing or Cash Out</h2>
        <h5 className="brief">
          Continue answering to grow your net worth with each question, or cash
          out anytime to enjoy your winnings! The choice is yours
        </h5>
      </div>
      <div className="start-playing" onClick={handleStartPlayingClick}>
        <span>Start Playing</span>
      </div>
    </>
  );
}

export default FifthScreen;
