import React from "react";
import { useNavigate } from "react-router-dom";

const ContestEndModal = ({ open }) => {

  const navigate = useNavigate();  

  return open ? (
    <div className="inner-page-container">
      <div className="container-fluid">
        <div className="position-fixed contest-end-popup">
          <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="winning-inner-text bg-white p-5">
              <h3 class="fw-bold text-dark-orange mb-0">Contest is over</h3>
              <p>Thank you for participating.</p>
              <div className="winning-navigation text-end pt-3">
                <button
                  type="button"
                  className="btn btn-sm btn-orange"
                  onClick={() => navigate("/appuserhome")}
                >
                  Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ContestEndModal;
