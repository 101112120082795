import { useEffect, useRef, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import Login from "./Pages/Login/Login";
import Signup from "./Pages/Signup/Signup";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./Pages/ForgetPassword/ResetPassword";
import VerifyEmail from "./Pages/VerifyEmail/VerifyEmail";
// import UserHome from "./Pages/UserHome/UserHome";
// import Livecontest from "./Pages/Livecontest/Livecontest" // to avoid un-necessary import resulting in huge bundle file
import Livecontestnew from "./Pages/Livecontest2/Livecontestnew";
import AppUserHome from "./Pages/AppUserHome/AppUserHome";
import SingleQuizDetail from "./Components/SingleQuizDetail/SingleQuizDetail";
import AppliedContestList from "./Components/AppliedContestList/AppliedContestList";
import SingleQuestion from "./Components/SingleQuestion/SingleQuestion";
import AdsList from "./Components/SponsorAds/AdsList/AdsList";
// import LiveAd from "./Components/SponsorAds/LiveAd/LiveAd";
import AdScreen from "./Pages/AdScreen";
import UserWallet from "./Pages/Wallet/UserWallet";
import UserProfile from "./Pages/UserProfile/UserProfile";
import UpdateProfile from "./Pages/UserProfile/UpdateProfile";
import Payment from "./Pages/Payment/Payment";
import { AppProvider } from "./context/AppContext";
import HomePage from "./Pages/HomePage";
import Verify from "./Pages/Payment/Verify";
import Withdraw from "./Pages/Payment/Withdraw";
import Deposit from "./Pages/Payment/Deposit";
import DepositStatus from "./Pages/Payment/DepositStatus";
import KYCVerification from "./Pages/KYC";

import { toastConfig } from "./config/config";
import ProtectedRoute from "./Components/ProtectedRoute";
import AdCarousel from "./Pages/Carousel/AdCarousel";

import { socket } from './socket';

function App() {
  const [isOnline, setIsOnline] = useState(true);
  const toastRef = useRef();

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [broadcastMessage, setBroadcastMessage] = useState([]);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onBroadcastEvent(value) {
      console.log("@@@@@", value)
      // setBroadcastMessage(previous => [...previous, value]);
      setBroadcastMessage(previous => [...previous, value]);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('MessagePassing', onBroadcastEvent);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('MessagePassing', onBroadcastEvent);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      toastRef.current = toast.error(
        "Internet connection lost. If you are watching ad or stuck. Please close the app and start again.",
        {
          duration: Infinity,
        }
      );
    } else if (toastRef.current) {
      toast.remove(toastRef.current);
      toastRef.current = null;
    }
  }, [isOnline]);

  // if (
  //   /webview|wv|ip((?!.*Safari)|(?=.*like Safari))/i.test(
  //     window?.navigator?.userAgent
  //   ) === false
  // ) {
  //   return (
  //     <div className="h-screen d-flex align-items-center justify-content-center">
  //       <p className="fs-4">Sorry browser not supported</p>
  //     </div>
  //   );
  // }

  return (
    <>
      <AppProvider>
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/register" element={<Signup />} />
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
            <Route exact path="/resetPassword" element={<ResetPassword />} />
            <Route exact path="/verify-email" element={<VerifyEmail />} />
            <Route exact path="/adCarousel" element={<AdCarousel/>}/>
            <Route exact path="/adhome" element={<HomePage/>} />
            {/* <Route exact path="/userhome" element={<UserHome />} /> */}
            {/* <Route exact path="/livecontest" element={<Livecontest />} /> */}

            <Route element={<ProtectedRoute />}>
              <Route
                exact
                path="/livecontestnew"
                element={<Livecontestnew />}
              />
              <Route exact path="/appuserhome" element={<AppUserHome />} />
              <Route
                exact
                path="/singlequizdetail"
                element={<SingleQuizDetail broadcastMessage={broadcastMessage}/>}
              />
              <Route
                exact
                path="/appliedcontest"
                element={<AppliedContestList />}
              />
              <Route
                exact
                path="/singlequestion"
                element={<SingleQuestion />}
              />
              <Route exact path="/adslist" element={<AdsList />} />
              <Route exact path="/livead" element={<AdScreen />} />
              <Route exact path="/userwallet" element={<UserWallet />} />
              <Route exact path="/userprofile" element={<UserProfile />} />
              <Route exact path="/updateprofile" element={<UpdateProfile />} />
              <Route exact path="/payment" element={<Payment />} />
              <Route exact path="/payment/verify" element={<Verify />} />
              <Route exact path="/payment/withdraw" element={<Withdraw />} />
              <Route exact path="/payment/deposit" element={<Deposit />} />
              <Route exact path="/payment/deposit/status/:orderId" element={<DepositStatus />} />
              <Route
                exact
                path="/kyc-verification"
                element={<KYCVerification />}
              />
            </Route>
          </Routes>
        </Router>
      </AppProvider>
      <Toaster toastOptions={toastConfig} />
    </>
  );
}

export default App;
