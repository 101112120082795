import "./secondScreen.css";
function SecondScreen() {
  return (
    <div class="second-screen">
      <img className="logo" src="/assets/images/logo.png" />
      <div className="image-container">
        <div className="child-container">
          <div className="child">
            <img src="/assets/images/at_cost.jpg" />
          </div>
        </div>
        <div className="video-cont">
          <p>Watch the video</p>
          <p>Give the right answer and win</p>
        </div>
      </div>
      <div class="content">
        <p className="heading">Your Money, Your Game</p>
        <p className="brief">
          Play with your money or earn entry fees by watching ads! Earn your way
          in! Deposit your money or watch ads, answer campaign questions to
          pocket real cash for your quiz entries—exclusively with Izido.
        </p>
      </div>
    </div>
  );
}

export default SecondScreen;
