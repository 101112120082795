import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
// icons
import { AiFillDollarCircle } from "react-icons/ai";
// components
import BottomNav from "../Components/BottomNav/BottomNav";
import AppHeader from "../Components/AppHeader/AppHeader";
import Loader from "../Components/Loader";
import Banner from "../Components/Banner";
// utils and helpers
import { ACTIONS, useAppContext } from "../context/AppContext";
import CONSTANTS from "../Constants/global";
import api from "../Service/apiService";

const CAROUSEL_IMAGES = [
  "./assets/images/ban1.png",
  "./assets/images/ban2.png",
];

const HomePage = () => {
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(false);

  const { updateAppState } = useAppContext();

  const getWalletDetails = async () => {
    try {
      const res = await api.get(CONSTANTS.GETWALLETAMOUNT);
      updateAppState(ACTIONS?.WALLET, res?.data?.userwallet);
      updateAppState(ACTIONS?.IS_VERIFIED, res?.data?.isVerified);
      updateAppState(ACTIONS?.KYC_EXPIRED, res?.data?.isKycExpired);
      updateAppState(ACTIONS.PROFILE_IMAGE, res.data?.profileImage);
      updateAppState(ACTIONS?.PHONE_VERIFIED, res?.data?.isPhoneVerified);
      updateAppState(ACTIONS?.PHONE, res?.data?.phone);

      localStorage.setItem("name", res?.data?.name);
    } catch (error) {
      console.error(error);
    }
  };

  const getAdList = async () => {
    try {
      setLoading(true);
      const res = await api.get(`${CONSTANTS.GETADLIST}?limit=3`);
      if (res && res.data) {
        setAds(res?.data?.ads);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        console.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWalletDetails();
    getAdList();
    if (window.NativeHook) {
      window.NativeHook.setAppStatusBarColour("#FFFFFF");
    } else if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.setAppStatusBarColour.postMessage(
        "#FFFFFF"
      );
    }
  }, []);

  return (
    <div>
      <AppHeader />

      {loading ? (
        <div className="page-container h-91 bg-white overflow-y-scroll">
          <div className="h-100 d-flex align-items-center justify-content-center">
            <Loader />
          </div>
        </div>
      ) : (
        <div className="page-container h-91 bg-white overflow-y-scroll">
          <div>
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators text-black pb-2">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                {ads?.length !== 0 && (
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                )}
              </div>
              <div className="carousel-inner">
                {[...new Array(ads?.length === 0 ? 2 : 3)]?.map(
                  (_, imageIdx) => {
                    let imageSrc =
                      ads && ads[imageIdx]
                        ? `${process.env.REACT_APP_AD_IMAGE_PATH_URL}${ads[imageIdx].adImage}`
                        : `${CAROUSEL_IMAGES[imageIdx % 2]}`;

                    let adName =
                      ads && ads[imageIdx] ? ads[imageIdx].adName : "";
                    return (
                      <div
                        className={`carousel-item ${
                          imageIdx === 0 ? "active" : ""
                        }`}
                        key={imageIdx}
                      >
                        <Banner adName={adName} src={imageSrc} />
                      </div>
                    );
                  }
                )}
              </div>
            </div>

            <div className="px-4 pt-3">
              <div className="d-flex homepage_header">
                <div>
                  <h1 className="mb-0 text-danger fw-bold">
                    You heard it right!
                  </h1>
                  <p className="m-0 text-secondary">
                    Just watch an ad and start earning money.
                  </p>
                </div>

                <img src="/assets/images/head.svg" />
              </div>

              <Link to={"/adslist"}>
                <div className="homepage_bottom_box">
                  <div className="homepage_ad_box">
                    <AiFillDollarCircle
                      style={{
                        color: "green",
                        height: "5rem",
                        fontSize: "2rem",
                        opacity: "0.6",
                      }}
                    />
                    <img
                      src="./assets/images/controller.png"
                      className="homapage_adbox_image"
                    />
                    <h1 className="text-white m-0">Interact with brands</h1>
                    <p className="text-white m-0">& win daily rewards</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="text-center d-flex flex-column mb-3">
            <img
              src="/assets/images/homepage_bottom.svg"
              className="homepage_bottom_image"
            />
            <span>Crafted with &hearts; in India</span>
          </div>
        </div>
      )}
      <BottomNav />
    </div>
  );
};

export default HomePage;
