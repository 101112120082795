import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const status = localStorage.getItem("loginStatus");
  const emailVerified = localStorage.getItem("emailVerified");

  if (!refreshToken || !status || emailVerified !== "true") {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
