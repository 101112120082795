import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
import { FaEdit, FaCamera } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { Form } from "react-bootstrap";
import moment from "moment";
// import { useLocation, useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
// import { alternateEmailValidation, charactersValidation, emailValidation, isNumberKey, numberValidation, } from "../../Utils/Utils"

//import common components
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../../Components/BottomNav/BottomNav";
import DeleteAccountModal from "../../Components/Modal/DeleteAccountModal";
import Spinner from "../../Components/Spinner";
import api from "../../Service/apiService";
//API URL
import CONSTANTS from "../../Constants/global";
import { ACTIONS, useAppContext } from "../../context/AppContext";
import { uploadImage } from "../../Service/uploadImage";

export default function UpdateProfile() {
  const navigate = useNavigate();
  // const location = useLocation()
  const [username, setUsername] = useState("");
  const [error, setError] = useState({});
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [addressDataLoading, setAddressDataLoading] = useState(false);
  const [profileInfo, setProfileInfo, getProfileInfo] = useState({
    // ageGroup: "",
    age: "",
    dob: "",
    gender: "",
    incomegroup: "",
    name: "",
  });
  const [userAddress, setUserAddress, getUserAddress] = useState({
    pin: "",
    address1: "",
    address2: "",
    district: "",
    state: "",
    country: "",
    location: "",
  });
  const [genderList, setGenderList] = useState({});
  // const [ageGroupList, setAgeGroupList] = useState({})
  const [incomeGroupList, setIncomeGroupList] = useState({});
  const [loading, setLoading] = useState(false);

  const [profileImage, setProfileImage] = useState({});

  const [showUserInput, setShowUserInput] = useState(false);
  const [newUserName, setNewUserName] = useState(localStorage.getItem("name"));

  // delete account
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { appState, updateAppState } = useAppContext();

  const updateUserName = async () => {
    if (!newUserName || newUserName === localStorage.getItem("name"))
      return toast.error("Name cannot be empty or same as previous");
    try {
      const res = await api.post(CONSTANTS.UPDATE_USERNAME, {
        name: newUserName,
      });
      toast.success("Name updated successfully");
      setUsername(res.data?.name);
      localStorage.setItem("name", res?.data?.name);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, try again"
      );
    }
  };

  console.log(profileInfo.dob, "ddddd");
  //Sync the form data with the user changes
  const handlePinfoChange = (e) => {
    profileInfo[e.target.name] = e.target.value;
    setProfileInfo({
      ...profileInfo,
    });
    console.log(e.target.name, "ddddd");
  };
  const handleAddressChange = (e) => {
    userAddress[e.target.name] = e.target.value;
    setUserAddress({
      ...userAddress,
    });
    console.log(e.target.name);
    if (e.target.name == "pin" && e.target.value.length == 6) {
      console.log(e.target.value);
      // getPinData(parseInt(e.target.value));
    } else if (e.target.name == "pin" && e.target.value.length != 6) {
      setDistrictList([]);
      setStateList([]);
      setLocationList([]);
      userAddress.district = "";
      userAddress.state = "";
      userAddress.location = "";
      setUserAddress({
        ...userAddress,
      });
      console.log("useraddress afer update", userAddress);
    }
  };

  //Handle Update profile Submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      // age: profileInfo.age,
      dob: profileInfo.dob,
      gender: profileInfo.gender,
      incomegroup: profileInfo.incomegroup,
      name: profileInfo.name,
      // pin: userAddress.pin,
      // address1: userAddress.address1,
      // address2: userAddress.address2,
      // district: userAddress.district,
      // state: userAddress.state,
      // country: userAddress.country,
      // location: userAddress.location,
      // ageGroup: profileInfo.ageGroup,

      // altContact: "",
      // aadhar: "",
      // pan: "",
    };

    const getAge = (birthDate) =>
      Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);
    let age = getAge(formData.dob);
    if (!formData.dob || age < 18)
      return toast.error("Age should be atleast 18");

    console.log(formData);
    let _URL = CONSTANTS.UPDATEPROFILE;
    try {
      setLoading(true);
      const res = await api.post(_URL, formData);
      if (res && res?.data && res?.data?.profile && res?.data?.address) {
        console.log(res.data);
        toast.success(res?.data?.message);
        setFormData(res?.data?.profile, res?.data?.address);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const setFormData = (userProfileData, userAddressData) => {
    setProfileInfo({ ...userProfileData });
    setUserAddress({ ...userAddressData });
  };

  const getUserDetails = async () => {
    const _URL = CONSTANTS.GETUSERPROFILE;
    try {
      const res = await api.get(_URL);
      if (res && res.data) {
        console.log(res);
        setUsername(res?.data?.name);
        setFormData(res?.data?.userProfile, res?.data?.address);
        setProfileImage(res.data?.profileImage);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  const getEnumValues = async () => {
    const _URL = CONSTANTS.GETENUMVALUES;
    try {
      const res = await api.get(_URL);
      if (res && res.data) {
        setGenderList({ ...res?.data?.Gender });
        setIncomeGroupList({ ...res?.data?.IncomeGroup });
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  useEffect(() => {
    getEnumValues();
    getUserDetails();
  }, []);

  const askPermission = () => {
    if (window.NativeHook) {
      window.NativeHook.getPermission("location");
      getAddressDetails();
    } else if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.getPermission.postMessage({
        message: "location",
      });
      getAddressDetails();
    }
  };

  const getLatLong = () => {
    return new Promise((resolve, reject) => {
      // If web doesn't get any response from native then we will throw an error after 10-seconds
      const timeoutId = setTimeout(() => {
        reject({ message: "Please allow location access and Try again" });
      }, 30000);

      window.sendLatLongDetails = (e) => {
        clearTimeout(timeoutId);
        try {
          let a = JSON.parse(e);
          if (a.success) {
            resolve(a);
          } else {
            reject({ message: a.error });
          }
        } catch (error) {
          reject({ message: error.message });
        }
      };
    });
  };

  const getAddressDetails = async () => {
    try {
      setAddressDataLoading(true);
      const latLong = await getLatLong();
      const res = await api.post(
        CONSTANTS.GET_ADDRESS_FROM_LATLONG,
        latLong.data
      );

      if (res?.data?.pincode?.length === 0)
        return toast.error(
          "Sorry we couldn't find your address. Please try again later."
        );

      let pinRes = res?.data?.pincode ?? [];
      setDistrictList([...new Set(pinRes.map((item) => item.District))]);
      setStateList([...new Set(pinRes.map((item) => item.StateName))]);
      setLocationList([...new Set(pinRes.map((item) => item.LocationName))]);
      setUserAddress({
        ...userAddress,
        pin: pinRes[0]?.Pincode,
        district: pinRes[0]?.District,
        state: pinRes[0]?.StateName,
        country: "India",
        location: "",
      });
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setAddressDataLoading(false);
    }
  };

  const handleProfileImageChange = async (e) => {
    let file = e.target.files[0];
    if (!file || e.target.files?.length === 0) return;

    if (!file.type.startsWith("image/")) {
      return toast.error("Selected file is not an image.");
    } else if (file.size > 2 * 1024 * 1024) {
      return toast.error("Selected image cannot be larger than 2 MB.");
    }

    try {
      toast("File upload in progress", {
        icon: "⌛",
      });
      let url = await uploadImage(file);
      let res = await api.post(CONSTANTS.UPDATE_PROFILE_IMG, {
        profileImage: url,
      });

      setProfileImage(url);
      updateAppState(ACTIONS.PROFILE_IMAGE, url);
      toast.success("Profile photo updated successfully");
    } catch (error) {
      toast.error("Please try again");
    }
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();

    if (!profileInfo.dob)
      return toast.error("Please update profile info first");

    if (!userAddress.pin)
      return toast.error("Allow location access to update address");

    const formData = {
      pin: userAddress.pin,
      address1: userAddress.address1,
      address2: userAddress.address2,
      district: userAddress.district,
      state: userAddress.state,
      country: "India",
      location: userAddress.location,
      dob: profileInfo.dob,
    };

    if (!formData?.location || !formData?.address1)
      return toast.error("Please fill all mandatory fields");

    console.log("formdata is", formData);

    let _URL = CONSTANTS.UPDATEPROFILE;
    try {
      setLoading(true);
      const res = await api.post(_URL, formData);
      if (res?.data?.address) {
        toast.success("Address updates successfully");
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  const handleDeleteAccount = async (confirmDelete) => {
    setShowDeleteModal(false);
    if (!confirmDelete) {
      return;
    }

    try {
      setDeleteLoading(true);
      await api.post(CONSTANTS.DELETE_USER_ACCOUNT);
      logout();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <AppHeader />
      <div className="page-container h-91 overflow-y-scroll">
        <div className="container-fluid">
          <div className="d-flex flex-column align-items-center">
            <label htmlFor="file-input" className="position-relative">
              <img
                src={
                  profileImage
                    ? `${process.env.REACT_APP_IMAGE_PATH_URL}${profileImage}`
                    : "/assets/images/profile_pic.svg"
                }
                className="update_profile_img"
              />
              <span className="update_profile_img_edit_icon">
                <FaCamera />
              </span>
            </label>
            <input
              type="file"
              accept="image/*"
              id="file-input"
              className="invisible"
              onChange={handleProfileImageChange}
            />
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h4 className="card-title d-flex justify-content-between">
                <div>
                  Hello <span className="text-dark-orange">{username} !</span>
                </div>

                {!appState?.isVerified && (
                  <FaEdit
                    className="cursor-pointer"
                    onClick={() => setShowUserInput(!showUserInput)}
                  />
                )}
              </h4>

              {showUserInput && (
                <div className="d-flex gap-3 mt-3">
                  <input
                    placeholder="Change name here..."
                    className="form-control w-75"
                    value={newUserName}
                    onChange={(e) => setNewUserName(e.target.value)}
                  />{" "}
                  <button
                    className="btn btn-sm btn-orange"
                    onClick={updateUserName}
                  >
                    Update
                  </button>
                </div>
              )}

              {!appState?.isVerified && (
                <p className="m-0 p-0">
                  Please verify your KYC. Click here to{" "}
                  <Link
                    to="/kyc-verification"
                    className="text-info text-decoration-underline"
                  >
                    verify
                  </Link>
                </p>
              )}
            </div>
          </div>

          <p className="card-subtitle my-3 text-muted mx-2">
            Fill the information to complete your profile.
          </p>

          <div className="accordion" id="profileaccordion">
            <div className="accordion-item">
              <h2 className="accordion-header border-orange" id="headingOne">
                <button
                  className="accordion-button py-2 text-dark-orange fs-5 fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Profile Info
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#profileaccordion"
              >
                <div className="accordion-body">
                  <form
                    // className="row g-3"
                    onSubmit={(e) => {
                      handleFormSubmit(e);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6 pb-3">
                        <label htmlFor="dob" className="form-label fw-bold">
                          DOB *
                        </label>

                        <input
                          className="form-control"
                          id="dob"
                          name="dob"
                          type={"date"}
                          required
                          value={moment(profileInfo.dob).format("YYYY-MM-DD")}
                          onChange={handlePinfoChange}
                        />
                      </div>

                      <div className="col-sm-12 col-md-6 pb-3">
                        <Form.Label className=" fw-bold">Gender</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="gender"
                          value={profileInfo.gender}
                          onChange={handlePinfoChange}
                        >
                          <option>Select Gender</option>
                          {Object.keys(genderList).map((key, index) => {
                            return (
                              <option key={index} value={genderList[key]}>
                                {key}
                              </option>
                            );
                          })}
                          {/* <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="others">Others</option> */}
                        </Form.Select>
                      </div>

                      <div className="col-md-6 pb-3">
                        {/* <label htmlFor="incomegroup" className="form-label fw-bold">Income Group</label>
                                                <input className="form-control" id="incomegroup" name="incomegroup" type="number" value={profileInfo.incomegroup} onChange={handlePinfoChange} /> */}

                        <Form.Label htmlFor="incomegroup" className="fw-bold">
                          Income Group
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={handlePinfoChange}
                          id="incomegroup"
                          name="incomegroup"
                          value={
                            profileInfo.incomegroup
                              ? profileInfo.incomegroup
                              : "Income Group"
                          }
                        >
                          <option value="">Income Group</option>
                          {Object.keys(incomeGroupList).map((key, index) => {
                            return (
                              <option key={index} value={incomeGroupList[key]}>
                                {key}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>

                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-orange"
                          disabled={loading}
                          title={
                            !(Object.keys(error).length == 0)
                              ? "form fields validation"
                              : "Submit to Update profile"
                          }
                        >
                          {loading ? "Updating..." : "Update Profile"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed py-2 text-dark-orange fs-5 fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Address Info
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#profileaccordion"
              >
                <div className="accordion-body">
                  {addressDataLoading ? (
                    <>
                      <Spinner />
                      <p>Fetching your current address please wait...</p>
                    </>
                  ) : (
                    <form onSubmit={handleAddressSubmit}>
                      <div className="row">
                        <p className="text-primary" onClick={askPermission}>
                          <ImLocation2 /> Click here and allow access to your
                          location
                        </p>
                        <div className="col-12 pb-3">
                          <label
                            htmlFor="address1"
                            className="form-label fw-bold"
                          >
                            Address <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address1"
                            name="address1"
                            placeholder="Address Line 1"
                            value={userAddress.address1}
                            onChange={handleAddressChange}
                          />
                        </div>
                        <div className="col-12 pb-3">
                          <label
                            htmlFor="address2"
                            className="form-label fw-bold"
                          >
                            Address 2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address2"
                            name="address2"
                            placeholder="Apartment, studio, or floor"
                            value={userAddress.address2}
                            onChange={handleAddressChange}
                          />
                        </div>
                        <div className="col-md-2 pb-3">
                          <label htmlFor="pin" className="form-label  fw-bold">
                            PIN <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control pe-none bg-light-grey"
                            id="pin"
                            name="pin"
                            value={userAddress.pin}
                            readOnly
                            // onChange={handleAddressChange}
                          />
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 pb-3">
                          <Form.Label htmlFor="state" className="fw-bold">
                            State
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            // onChange={handleAddressChange}
                            id="state"
                            name="state"
                            value={
                              userAddress.state
                                ? userAddress.state
                                : stateList
                                  ? stateList[0]
                                  : "Select State"
                            }
                            readOnly
                            className="pe-none bg-light-grey"
                          >
                            <option value={userAddress.state}>
                              {userAddress.state}
                            </option>
                          </Form.Select>
                        </div>
                        <div className="col-md-6  col-xs-12 pb-3">
                          <Form.Label htmlFor="district" className="fw-bold">
                            District
                          </Form.Label>
                          <Form.Select
                            aria-label="Select district"
                            value={
                              userAddress.district
                                ? userAddress.district
                                : districtList
                                  ? districtList[0]
                                  : "Select District"
                            }
                            id="district"
                            name="district"
                            readOnly
                            className="pe-none bg-light-grey"
                          >
                            <option value={userAddress.district}>
                              {userAddress.district}
                            </option>
                          </Form.Select>
                        </div>
                        <div className="col-md-6 col-xs-12 pb-3">
                          <Form.Label htmlFor="location" className="fw-bold">
                            Area <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            aria-label="Select Location"
                            value={
                              userAddress.location
                                ? userAddress.location
                                : "Select Area"
                            }
                            onChange={handleAddressChange}
                            id="location"
                            name="location"
                          >
                            {!locationList?.length && userAddress.location ? (
                              <option value={userAddress.location}>
                                {userAddress.location}
                              </option>
                            ) : (
                              <>
                                <option value="">Select Area</option>
                                {locationList.map((areaitem, areaIdx) => {
                                  return (
                                    <option key={areaIdx} value={areaitem}>
                                      {areaitem}
                                    </option>
                                  );
                                })}
                              </>
                            )}
                          </Form.Select>
                        </div>
                        <div className="col-md-6 col-xs-12 pb-3">
                          <Form.Label htmlFor="country" className="fw-bold">
                            Country
                          </Form.Label>
                          <Form.Select
                            aria-label="Select Country"
                            value="India"
                            // onChange={handleAddressChange}
                            id="country"
                            name="country"
                            readOnly
                            className="pe-none bg-light-grey"
                          >
                            <option value="India">India</option>
                          </Form.Select>
                        </div>
                        <div className="col-12 text-center">
                          <button
                            type="submit"
                            className="btn btn-orange"
                            disabled={loading}
                          >
                            {loading ? "Updating..." : "Update Address"}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <button
              className="btn btn-danger col-8 offset-2 text-white px-3 py-2 border-0 border-radius-20"
              disabled={deleteLoading}
              onClick={() => setShowDeleteModal(true)}
            >
              { deleteLoading ? "Loading..." : "Delete Account" }
            </button>
          </div>
        </div>
      </div>
      <DeleteAccountModal
        show={showDeleteModal}
        handleClick={handleDeleteAccount}
        message="Warning: Proceeding with this action will delete your account."
      />
      <BottomNav />
    </>
  );
}
