import axios from "axios";
import CONSTANTS from "./../Constants/global";

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (
      originalConfig?.url !== CONSTANTS.USERSIGNUP &&
      originalConfig?.url !== CONSTANTS.USERLOGIN &&
      err.response
    ) {
      // Access Token was expired
      // console.log('login status ', err.response.status)
      // console.log('originalConfig._retry is ', originalConfig._retry)
      if (
        (err.response.status === 401 && !originalConfig._retry) ||
        (err.response.status === 403 && !originalConfig._retry)
      ) {
        originalConfig._retry = true;
        try {
          const rs = await axios.post(CONSTANTS.REFRESH_TOKEN, {
            refreshToken: localStorage.getItem("refreshToken"),
          });
          const { token } = rs.data;
          console.log("refresh token data is ", rs.data);
          sessionStorage.setItem("token", token);
          return instance(originalConfig);
        } catch (_error) {
          // handle logout, clear storage
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
          return Promise.reject(_error);
        }
      } else if (
        err.response.status === 403 &&
        err?.response?.data?.message === "Forbidden error"
      ) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
        return Promise.reject(err);
      } else {
        return Promise.reject(err);
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
