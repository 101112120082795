export const passwordValidation = (password) => {
  if (
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,10}$/.test(
      password
    )
  ) {
    return "";
  }
  if (password.trim() === "") {
    return "Password is required";
  }

  return "Password should contain minimum five and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character";
};

export const emailValidation = (email) => {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  ) {
    return "";
  }
  if (email.trim() === "") {
    return "Email is required";
  }
  return "Email Address must be in valid formate with @ symbol";
};

export const nameValidation = (name) => {
  if (name.trim() === "") {
    return `Name is required`;
  }
  if (/[^a-zA-Z -]/.test(name)) {
    return "Invalid Characters";
  }
  if (name.trim().length < 3) {
    return `Name needs to be at least three characters`;
  }
  return "";
};

export const confPasswordValidation = (password, confPassword) => {
  if (confPassword.trim() === "") {
    return "Confirm Password is required";
  }
  if (password !== confPassword) {
    return "Password doesn't match";
  }
  return "";
};

export const charactersValidation = (string) => {
  if (/[^a-zA-Z -]/.test(string)) {
    return true;
  }
  return false;
};

export const numberValidation = (number) => {
  if (/^[0-9]*$/.test(number)) {
    return false;
  }
  return true;
};

export const alternateEmailValidation = (email) => {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  ) {
    return "";
  }
  if (email.trim() === "") {
    return "";
  }
  return "Email Address must be in valid formate with @ symbol";
};

export const isNumberKey = (evt) => {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
};

export const ifscValidation = (ifsc) => {
  if (/^[A-Z]{4}[0][A-Z0-9]{6}$/.test(ifsc) === false) {
    return "Enter valid ifsc code";
  }
};

export const accountNoValidation = (acc) => {
  if (/[0-9]{9,18}/.test(acc) === false) {
    return "Enter valid account number";
  }
};

export const contactNoValidation = (contact) => {
  if (/^[6-9]\d{9}$/.test(contact) === false) {
    return "Enter valid contact number";
  }
};

export const amountValidation = (amount) => {
  console.log("@@@@@", process.env.REACT_APP_MIN_AMOUNT)
  if (isNaN(amount) || /^\d+$/.test(amount) === false)
    return "Invalid amount, must be digits only";

  if (+amount < process.env.REACT_APP_MIN_AMOUNT) return `Minimum of ${process.env.REACT_APP_MIN_AMOUNT} needs to be withdrawn`;

  if (+amount > process.env.REACT_APP_MAX_AMOUNT) return `Maximum of ${process.env.REACT_APP_MAX_AMOUNT} is allowed for withdrawal at once`;

  return null;
};

export const formatNumber = (num) => {
  if (typeof num !== "number" && typeof num !== "string") return "0";

  const number = Number(num);

  if (isNaN(number)) {
    return "0";
  }

  const absNumber = Math.abs(number);

  const abbreviations = {
    K: 3,
    M: 6,
    B: 9,
    T: 12,
    Q: 15,
    Quint: 18,
    Sext: 21,
    Sept: 24,
  };

  for (const key in abbreviations) {
    if (
      absNumber >= 10 ** abbreviations[key] &&
      absNumber < 10 ** (abbreviations[key] + 3)
    ) {
      return (number / 10 ** abbreviations[key]).toFixed(2) + key;
    }
  }

  return num.toString();
};
