import React, { useEffect, useState } from "react";
//API URL
import CONSTANTS from "../../Constants/global";
import { useNavigate, Link } from "react-router-dom";

//import common components
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../../Components/BottomNav/BottomNav";
import Loader from "../../Components/Loader";
import api from "../../Service/apiService";

import toast from "react-hot-toast";
import { ACTIONS, useAppContext } from "../../context/AppContext";

export default function UserProfile() {
  const [userProfileInfo, setUserProfileInfo] = useState();
  const [userAddressInfo, setUserAddressInfo] = useState();
  const [adList, setAdList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { updateAppState } = useAppContext();
  const navigate = useNavigate();

  const getAdList = async () => {
    const getAdList_URL = CONSTANTS.GETADLIST;
    try {
      setLoading(true);
      const res = await api.get(getAdList_URL);
      if (res && res.data) {
        setAdList([...res?.data?.ads]);
        updateAppState(ACTIONS?.WALLET, res?.data?.wallet);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        console.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const getUserDetails = async () => {
    const _URL = CONSTANTS.GETUSERPROFILE;
    try {
      const res = await api.get(_URL);
      if (res && res.data) {
        console.log(res);
        setUserProfileInfo(res?.data?.userProfile);
        setUserAddressInfo(res?.data?.address);
        localStorage.setItem("name", res?.data?.name);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  useEffect(() => {
    getUserDetails();
    getAdList();
  }, []);

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <>
      <AppHeader />
      <div className="page-container h-91 overflow-y-scroll">
        <section className="profile-banner">
          <img
            className="img-fluid w-100"
            src="./assets/images/profile.svg"
            alt="Profile Banner"
          />
          <div className="banner-text">
            <div className="user-detail text-center">
              <h2 className="text-white mb-0 text-shadow-gray">
                {userProfileInfo?.name}
              </h2>
              <h5 className="text-white mt-0 text-shadow-gray">
                {userProfileInfo?.location}
              </h5>
            </div>
          </div>
        </section>

        <div className="container-fluid ">
          <div className="row mt-5">
            <Link
              to={"/updateprofile"}
              state={{
                userProfileData: userProfileInfo,
                userAddressData: userAddressInfo,
              }}
            >
              <div className="col-sm-12">
                <div
                  className="user-box d-flex justify-content-between"
                  style={{
                    background: "white",
                    margin: "0 5px",
                    borderRadius: "30px",
                    boxShadow: "0px 10px 15px 4px rgba(0,0,0,0.1)",
                    padding: "10px 20px",
                  }}
                >
                  <div className="d-flex gap-2">
                    <div className="pt-4 mx-3">
                      <i className="fas fa-user-alt"></i>
                    </div>
                    <div>
                      <h3 className="mb-0">My Account</h3>
                      <p>Edit your information</p>
                    </div>
                  </div>
                  <div className="pt-4 mx-3">
                    <i className="fas fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div
            className="row py-3 mt-4"
            style={{
              background: "white",
              margin: "0 5px",
              borderRadius: "30px",
              boxShadow: "0px 10px 15px 4px rgba(0,0,0,0.1)",
            }}
          >
            <h2
              className="fs-3 text-capitalize ms-2 mb-3"
              style={{ fontWeight: "700" }}
            >
              Answer Questions <br />
              To win Money!
            </h2>{" "}
            <br /> <hr />
            <div className="row gx-3" style={{ height: "75px" }}>
              {adList?.slice(0, 3).map((ad) => (
                <Link to="/adslist" className="col-3" key={ad?.id}>
                  <div className="h-100 px-2">
                    <img
                      // className="h-100"
                      style={{
                        height: "4rem",
                        width: "5rem",
                        borderRadius: "15px",
                        border: "1px solid grey",
                      }}
                      src={`${process.env.REACT_APP_AD_IMAGE_PATH_URL}${ad.adImage}`}
                    />
                  </div>
                </Link>
              ))}

              {adList?.length > 3 && (
                <div
                  className="col-3 px-2 d-flex align-items-center justify-content-center"
                  style={{ marginTop: "-8px" }}
                >
                  <Link to="/adslist">
                    <div className="rounded-circle bg-dark-orange text-white fs-4 p-3">
                      +{adList?.length - 3}
                    </div>
                  </Link>
                </div>
              )}

              {loading ? (
                <Loader />
              ) : (
                !adList?.length && (
                  <div className="px-4">No advertise match your profile</div>
                )
              )}
            </div>
          </div>

          <div className="row mt-5">
            <Link
              to={"/adCarousel"}
            >
              <div className="col-sm-12">
                <div
                  className="user-box d-flex justify-content-between"
                  style={{
                    background: "white",
                    margin: "0 5px",
                    borderRadius: "30px",
                    boxShadow: "0px 10px 15px 4px rgba(0,0,0,0.1)",
                    padding: "10px 20px",
                  }}
                >
                  <div className="d-flex gap-2">
                    <div>
                      <h3 className="mb-0">Application Tutorial</h3>
                    </div>
                  </div>
                  <div className="pt-2 mx-3">
                    <i className="fas fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="row mt-5">
            <button
              className="btn btn-danger col-4 offset-4 text-white px-2 py-1 border-0 border-radius-20"
              onClick={logout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
