import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// components
import ReactPlayer from "react-player";
import SplashLoader from "../Components/SplashLoader";
import WinningMsg from "../Components/SponsorAds/WinningMsg/WinningMsg";
// hooks
import useUnload from "../hooks/useUnload";
// utils and services
import CONSTANTS from "../Constants/global";
import ROUTER_PATHS from "../Constants/routerPath";
import api from "../Service/apiService";
import { useAppContext } from "../context/AppContext";

const AdScreen = () => {
  const [questionList, setQuestionList] = useState([]);
  const [adsLoading, setAdsLoading] = useState(false);
  const [answercheck, setAnswerCheck] = useState(false);
  const [ansList, setAnsList] = useState([]);
  const [status, setStatus] = useState({});
  const [winningAmount, setWinningAmount] = useState();

  const [currentQuestion, setCurrentQuestion] = useState({});
  const [adIndex, setAdIndex] = useState(0);
  const [viewVideo, setViewVideo] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);

  let location = useLocation();
  let navigate = useNavigate();
  const { appState } = useAppContext();

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function (e) {
      e.stopImmediatePropagation();
      window.history.pushState(null, "", window.location.href);
    };
  }, []);

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });

  useEffect(() => {
    getAdById();

    if (window.NativeHook) {
      window.NativeHook.setAppStatusBarColour("#ebf2ff");
    } else if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.setAppStatusBarColour.postMessage(
        "#ebf2ff"
      );
    }
  }, []);

  useEffect(() => {
    if (
      location?.pathname?.toLowerCase() === ROUTER_PATHS.LIVE_AD?.toLowerCase()
    ) {
      window.appInForeground = () => {
        if (viewVideo) {
          console.log("ad is resumed");
          setIsVideoPlaying(true);
        }
      };

      window.appInBackground = () => {
        if (viewVideo) {
          console.log("ad is stopped");
          setIsVideoPlaying(false);
        }
      };
    } else {
      window.appInForeground = null;
      window.appInBackground = null;
    }

    return () => {
      window.appInForeground = null;
      window.appInBackground = null;
    };
  }, []);

  const getAdById = async () => {
    setAdsLoading(true);
    const getAdById_URL = `${CONSTANTS.GETADBYID}/${location.state.adId}`;

    try {
      const result = await api.get(getAdById_URL);
      if (result && result.data) {
        let res = result?.data;
        setQuestionList([...res?.adsquestions]);
        setCurrentQuestion(res?.adsquestions[0]);
        setAdIndex(0);
        if (res?.adsquestions[0].videolink == "") {
          setViewVideo(false);
        }
      }
    } catch (error) {
      if (error.response) {
        if (
          (error.response?.data?.message ===
            "You have already played this ad" ||
            error?.response?.data?.message ===
              "Campaign is expired sorry for inconvenience") &&
          error?.response?.status === 400
        ) {
          navigate("/adslist", { replace: true });
        }

        if (error.response?.status !== 401 && error.response?.status !== 403) {
          setTimeout(() => {
            toast.error(error.response?.data?.message || error?.message);
          }, 400);
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setAdsLoading(false);
    }
  };

  const getnewQuestion = () => {
    if (questionList?.length == adIndex + 1) {
      checkAnswers(true);
    } else {
      checkAnswers();
      setCurrentQuestion(questionList[adIndex + 1]);
      if (questionList[adIndex + 1].videolink !== "") {
        setViewVideo(true);
      }
      setAdIndex((prev) => prev + 1);
      nextQuestion();
    }
  };

  const checkAnswers = async (isFinalQuestion) => {
    const _URL = CONSTANTS.ADSANSWERCHECK;
    let selectedOption = ansList.find((aL) => aL.quesId == currentQuestion.id);
    selectedOption = selectedOption?.ans ?? null;

    let sendData = {
      adId: Number(location.state.adId),
      ansList: ansList,
      adIndex: adIndex + 1,
      questionId: currentQuestion.id,
      selectedOption: selectedOption,
    };
    try {
      setAdsLoading(true);
      const post = await api.post(_URL, sendData);
      if (post && post?.data && isFinalQuestion) {
        setWinningAmount(post.data.winningAmount);
        setStatus({
          totalcorrectans: post.data.totalcorrectans,
          totalquestions: post.data.totalquestions,
        });
        setAnswerCheck(true);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          setTimeout(() => {
            toast.error(error.response?.data?.message || error?.message);
          }, 400);

          navigate("/adslist", { replace: true });
        }
      } else {
        setTimeout(() => {
          toast.error(error?.message || "Something went wrong");
        }, 400);

        navigate("/adslist", { replace: true });
      }
    } finally {
      setAdsLoading(false);
    }
  };

  const handleOptionSelect = (value) => {
    let tempObj = { quesId: currentQuestion?.id, ans: value };
    setAnsList((ansList) => [
      ...ansList?.filter((item) => item.quesId !== currentQuestion?.id),
      tempObj,
    ]);
  };

  const submitAns = () => {
    var childNodes = document.getElementById("containersoption");
    let submitBtn = document.getElementById("submitAnsBtn");
    childNodes.style.pointerEvents = "none";
    submitBtn.setAttribute("style", "background-color: #26a21b !important;");
    console.log("submit clicked");
    getnewQuestion();
  };

  const nextQuestion = () => {
    var childNodes = document.getElementById("containersoption");
    if (childNodes) {
      childNodes.style.pointerEvents = "auto";
    }
  };

  const currentAns = ansList?.find((el) => el?.quesId === currentQuestion?.id);

  return (
    <>
      {adsLoading ? (
        <SplashLoader />
      ) : (
        <>
          <div className="position-relative d-flex flex-column overflow-hidden">
            <div
              className="position-absolute start-0 top-0 top_layer"
              style={{ width: "100vw", height: "35%" }}
            ></div>
            <div className="d-flex justify-content-center pt-2 pb-2 px-4">
              <div className="adscreen_header_logo"></div>
            </div>

            <div className="d-flex align-items-center justify-content-between position-relative px-4">
              <div>
                <p className="m-0 fs-6 fw-bold">
                  Question {adIndex + 1}/{questionList?.length}
                </p>
              </div>

              <div>
                <div
                  className="wallet-coin"
                  style={{ border: "1px solid #000" }}
                >
                  {appState?.wallet ?? "0"} <i className="coin"></i>
                </div>
              </div>
            </div>

            {viewVideo ? (
              <div className="d-flex flex-column align-items-center position-relative">
                <div className="mt-3" style={{ pointerEvents: "none" }}>
                  <ReactPlayer
                    className="react-player mt-5"
                    id="newad_player"
                    url={`https://www.youtube.com/embed/${currentQuestion.videolink}?autoplay=1&rel=0&start=0&modestbranding=1&showinfo=0&fs=0`}
                    frameBorder="0"
                    config={{
                      file: { attributes: { playsInline: true } },
                    }}
                    allowFullScreen
                    volume={1}
                    playing={isVideoPlaying}
                    allow="autoplay"
                    onEnded={() => setViewVideo(false)}
                    width="85vw"
                    height="30vh"
                  />
                </div>
                <img
                  src="/images/vdo.png"
                  className="mt-3"
                  style={{ width: "90vw", height: "30vh" }}
                />
              </div>
            ) : (
              <div
                className="w-100 hide-scrollbar px-4"
                style={{
                  marginTop: "20px",
                  paddingBottom: "50px",
                  overflowY: "auto",
                }}
              >
                <div className="question_box position-relative">
                  <p className="fw-bold fs-3 overflow-hidden overflow-wrap-break display-inline-block">
                    {currentQuestion?.question}
                  </p>
                </div>

                {currentQuestion?.imagepath && (
                  <div className="text-center">
                    <img
                      src={`${process.env.REACT_APP_AD_IMAGE_PATH_URL}${currentQuestion?.imagepath}`}
                      style={{
                        maxHeight: "30vh",
                        maxWidth: "70vw",
                        objectFit: "contain",
                        borderRadius: "2rem",
                        marginBottom: "1rem",
                        position: "relative",
                      }}
                    />
                  </div>
                )}

                <div
                  className="option_box position-relative gap-3"
                  id="containersoption"
                >
                  {[...new Array(4)]?.map((_, optionIdx) => {
                    let option =
                      currentQuestion[`option${optionIdx + 1}`] ?? "";
                    return (
                      <div
                        className={`option_item_box gap-4 ${
                          currentAns?.ans === option ? "selected" : ""
                        }`}
                        onClick={() => handleOptionSelect(option)}
                        key={option}
                      >
                        <div className="option_item_box_1">
                          <p className="m-0 fw-bold fs-6">{optionIdx + 1} </p>
                        </div>
                        <div>
                          <p className="m-0 fw-bold fs-6 overflow-hidden word-break-all">
                            {option}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {!viewVideo && (
              <div
                className="text-center px-4"
                style={{ zIndex: 999, padding: "15px 0 20px 0" }}
              >
                <button
                  className="ad_submit_btn"
                  id="submitAnsBtn"
                  onClick={submitAns}
                >
                  {adIndex + 1 === questionList?.length ? "Submit" : "Next"}
                </button>
              </div>
            )}
          </div>

          {viewVideo && (
            <div
              className="position-absolute bottom-0 start-0"
              style={{ width: "100vw", zIndex: 1 }}
            >
              <img src="/images/wave.svg" />
            </div>
          )}
          {answercheck && (
            <WinningMsg
              status={status}
              winningcoin={winningAmount}
              nextUrl={"/adslist"}
            />
          )}
        </>
      )}
    </>
  );
};

export default AdScreen;
