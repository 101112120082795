const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
const GETPUBLISHEDCONTEST = `${BASE_URL}/publishedcontest/getPublishedContest`;
const CONTESTPLAYCHECK = `${BASE_URL}/publishedcontest/contestplaycheck`;
const APPLYCONTEST = `${BASE_URL}/publishedcontest/applycontest`;
const DETAILVIEWCONTEST = `${BASE_URL}/publishedcontest/detailviewcontest`;
const APPLIEDCONTESTLIST = `${BASE_URL}/publishedcontest/appliedcontests`;
const PAYNEWPOLLAMOUNT = `${BASE_URL}/publishedcontest/paynewpollamount`;
const SEND_OTP = `${BASE_URL}/users/send-otp`;
const OTP_VERIFICATION = `${BASE_URL}/users/otp-verification`;
const RESET_PASSWORD = `${BASE_URL}/users/reset-password`;
const USERLOGIN = `${BASE_URL}/users/login`;
const USERSIGNUP = `${BASE_URL}/users/signup`;
const GETUSER = `${BASE_URL}/users`;
const GETUSERPROFILE = `${BASE_URL}/users/profileinfo`;
const UPDATEPROFILE = `${BASE_URL}/users/update-profile`;
const UPDATE_USERNAME = `${BASE_URL}/users/update-name`;
const UPDATE_PROFILE_IMG = `${BASE_URL}/users/upload-profileimage`;
const GETADLIST = `${BASE_URL}/ads/getAds`;
const GETADBYID = `${BASE_URL}/ads`;
const ADSANSWERCHECK = `${BASE_URL}/ads/answerCheck`;
const GETPINDETAILS = `${BASE_URL}/users/getpincodedata`;
const GETENUMVALUES = `${BASE_URL}/users/getenumvalues`;
const GETWALLETAMOUNT = `${BASE_URL}/users/getuserwallet`;
const ADDWALLETAMOUNT = `${BASE_URL}/users/addmoneywallet`;
const WITHDRAWAMOUNT = `${BASE_URL}/users/withdrawmoneywallet`;
const REFRESH_TOKEN = `${BASE_URL}/users/refresh-token`;
const GET_LIVE_CONSTES = `${BASE_URL}/publishedcontest/getData`;
const NO_USER_LEFT = `${BASE_URL}/broadcast/nouserleft`;
const CREDIT_WINNING_AMOUNT = `${BASE_URL}/broadcast/creditwinningamount`;
const GET_ADDRESS_FROM_LATLONG = `${BASE_URL}/users/addressfromlatlong`;
// mobile verificaiton
const SEND_MOBILE_OTP = `${BASE_URL}/users/mobile-otp-verification`;

// delete account
const DELETE_USER_ACCOUNT = `${BASE_URL}/users/deleteuser`;

//kyc-verification
const OFFLINE_AADHAR_OTP = `${BASE_URL}/kyc/offline-aadhaar/otp`;
const OFFLINE_AADHAR_VERIFY_OTP = `${BASE_URL}/kyc/offline-aadhaar/verify`;
const PAN_VERIFICATION = `${BASE_URL}/kyc/pan/verify`;
const DOC_AADHAR_VERIFY = `${BASE_URL}/kyc/document-aadhaar/verify`;
const DOC_PAN_VERIFY = `${BASE_URL}/kyc/document-pan/verify`;
const KYC_CHECK = `${BASE_URL}/kyc/check`;
//Broadcast
const GET_POLL_VALUE = `${BASE_URL}/broadcast/getpollvalues`;
const ANSWER_CHECK = `${BASE_URL}/broadcast/answerCheck`;
const CASHOUT = `${BASE_URL}/broadcast/cashout`;
const REENTER = `${BASE_URL}/broadcast/reenter`;
const GET_INITIAL_USERS = `${BASE_URL}/broadcast/getInitialUsers`;
const GET_AGE = `${BASE_URL}/users/getAge`;
// payments
const PAYMENT_WITHDRAW = `${BASE_URL}/payment/user/withdraw`;
const PAYMENT_FUND_LIST = `${BASE_URL}/payment/user/withdraw/fund_list`;
const PAYMENT_HISTORY = `${BASE_URL}/payment/user/getorder/history`;
const PAYMENT_FEE_DEDUCTION = `${BASE_URL}/payment/user/withdraw/fee_deduction`;
const PAYMENT_ACCOUNT_VERIFICATION = `${BASE_URL}/payment/user/withdraw/account_verification`;
const PAYMENT_ACCOUNT_RE_VERIFICATION = `${BASE_URL}/payment/user/verifybank/update_bankname`;
// deposit 
const PAYMENT_DEPOSIT = `${BASE_URL}/payment/user/deposit`;
const PAYMENT_DEPOSIT_CALLBACK = `${BASE_URL}/payment/user/deposit/callback`;
const PAYMENT_DEPOSIT_STATUS = `${BASE_URL}/payment/user/deposit/status`;
const PAYMENT_DEPOSIT_FEE_DEDUCTION = `${BASE_URL}/payment/user/deposit/fee_deduction`;

//wallet
const WALLET_HISTORY = `${BASE_URL}/wallet/user/history`;


// fcm
const FCM_REGISTER_TOKEN = `${BASE_URL}/notification/loginuser/fcm/register `;

export default {
  BASE_URL,
  CONTESTPLAYCHECK,
  GETPUBLISHEDCONTEST,
  APPLYCONTEST,
  DETAILVIEWCONTEST,
  APPLIEDCONTESTLIST,
  PAYNEWPOLLAMOUNT,
  GETADLIST,
  GETADBYID,
  ADSANSWERCHECK,
  SEND_OTP,
  OTP_VERIFICATION,
  RESET_PASSWORD,
  USERLOGIN,
  USERSIGNUP,
  GETUSER,
  GETUSERPROFILE,
  UPDATEPROFILE,
  GETPINDETAILS,
  GETENUMVALUES,
  GETWALLETAMOUNT,
  ADDWALLETAMOUNT,
  WITHDRAWAMOUNT,
  REFRESH_TOKEN,
  CREDIT_WINNING_AMOUNT,
  NO_USER_LEFT,
  GET_LIVE_CONSTES,
  ANSWER_CHECK,
  CASHOUT,
  REENTER,
  GET_POLL_VALUE,
  GET_INITIAL_USERS,
  GET_AGE,
  PAYMENT_WITHDRAW,
  PAYMENT_FUND_LIST,
  PAYMENT_HISTORY,
  PAYMENT_FEE_DEDUCTION,
  PAYMENT_ACCOUNT_VERIFICATION,
  UPDATE_USERNAME,
  UPDATE_PROFILE_IMG,
  PAYMENT_ACCOUNT_RE_VERIFICATION,
  FCM_REGISTER_TOKEN,
  GET_ADDRESS_FROM_LATLONG,
  OFFLINE_AADHAR_OTP,
  OFFLINE_AADHAR_VERIFY_OTP,
  PAN_VERIFICATION,
  DOC_AADHAR_VERIFY,
  DOC_PAN_VERIFY,
  KYC_CHECK,
  PAYMENT_DEPOSIT,
  PAYMENT_DEPOSIT_CALLBACK,
  PAYMENT_DEPOSIT_STATUS,
  PAYMENT_DEPOSIT_FEE_DEDUCTION,
  SEND_MOBILE_OTP,
  WALLET_HISTORY,
  DELETE_USER_ACCOUNT
};
