const LOGIN_PAGE = "/";
const REGISTER_PAGE = "/register";
const FORGOT_PASSWORD = "/forgetpassword";
const RESET_PASSWORD = "/resetPassword";
const LIVE_CONTEST= "/livecontestnew";
const USER_HOME= "/appuserhome";
const SINGLE_QUIZ_DETAIL ="/singlequizdetail";
const APPLIED_CONTEST = "/appliedcontest";
const SINGLE_QUESTION= "/singlequestion";
const ADS_LIST= "/adslist";
const LIVE_AD = "/livead";
const USER_WALLET = "/userwallet";
const USER_PROFILE = "/userprofile";
const UPDATE_PROFILE = "/updateprofile";
const PAYMENT = "/payment";



const ROUTER_PATHS = {
    LOGIN_PAGE,
    REGISTER_PAGE,
    FORGOT_PASSWORD ,
    RESET_PASSWORD,
    LIVE_CONTEST,
    USER_HOME,
    SINGLE_QUIZ_DETAIL,
    APPLIED_CONTEST,
    SINGLE_QUESTION,
    ADS_LIST,
    LIVE_AD,
    USER_WALLET,
    USER_PROFILE,
    UPDATE_PROFILE,
    PAYMENT
};

export default ROUTER_PATHS;
