import "./fourthScreen.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false, // Hide x-axis grid
      },
    },
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: 600,
      ticks: {
        stepSize: 200,
        callback: function (value) {
          return value; // Display the tick values without modification
        },
      },
      grid: {
        display: false, // Hide x-axis grid
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const data = {
  labels,
  datasets: [
    {
      label: "",
      data: [400, 50, 50, 50, 50, 50, 50, 50, 50], // Replace with your own data
      backgroundColor: "#58A868",
      borderRadius: {
        topRight: 15, // Adjust the value to control the rounding
        topLeft: 15,
      },
      barThickness: 10,
    },
  ],
};

function FourthScreen() {
  return (
    <>
      <img className="logo" src="/assets/images/logo.png" />
      <div className="header_img_div"></div>
      <div className="first-container">
        <div className="chart-title">
          <p>Congrats</p>
          <p>You were among the people who answered correctly</p>
        </div>
        <Bar options={options} data={data} />;
      </div>
      <div className="d-flex flex-column second-container">
        <div>
          <h5 className="heading">Intial Contestant</h5>
          <h1 className="heading-value">517</h1>
        </div>
        <div>
          <h5 className="heading">Remaining Contestans</h5>
          <h1 className="heading-value">360</h1>
        </div>
        <div className="earning">
          <div className="left-container">
          <h5 className="heading">Your total earning</h5>
          <h1 className="heading-value">143.61</h1>
          </div>
          <div className="cash-out">
            Cash Out
          </div>
        </div>
      </div>
      <div className="heading-cont">
        <span>Right Answers =</span> <span>More Money</span>
      </div>
      <div className="brief">
        <span>
          Every right answer boosts your earnings! After each question, the
          prize pool is share among those who answered correctly
        </span>
      </div>
    </>
  );
}

export default FourthScreen;
