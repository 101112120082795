import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// utils and helpers
import CONSTANTS from "../../Constants/global";
import api from "../../Service/apiService";
import toast from "react-hot-toast";
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../../Components/BottomNav/BottomNav";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { ACTIONS, useAppContext } from "../../context/AppContext";

const DepositStatus = () => {
  const [orderData, setOrderData] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  let { orderId } = useParams();

  let [sourceData] = useSearchParams();
  const sessionId = sourceData.get("session_id");

  const { updateAppState } = useAppContext();



  const getWalletDetails = async () => {
    const _URL = CONSTANTS.GETWALLETAMOUNT;
    try {
      const res = await api.get(_URL);
      if (res && res?.data && res?.data?.userwallet) {
        updateAppState(ACTIONS?.WALLET, res?.data?.userwallet);
        updateAppState(ACTIONS?.IS_VERIFIED, res?.data?.isVerified);
        updateAppState(ACTIONS.PROFILE_IMAGE, res.data?.profileImage);
        updateAppState(ACTIONS?.KYC_EXPIRED, res?.data?.isKycExpired);
        updateAppState(ACTIONS?.PHONE_VERIFIED, res?.data?.isPhoneVerified);
        updateAppState(ACTIONS?.PHONE, res?.data?.phone);

        localStorage.setItem("name", res?.data?.name);
      }
    } catch (error) {
      console.log("error is ", error);
    }
  };

  const getOrderStatus = async (orderId) => {
    try {
      setLoading(true);
      const response = await api.post(CONSTANTS.PAYMENT_DEPOSIT_STATUS, {
        ids: orderId,
      });
      setOrderData(response?.data?.order);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          "Something went wrong. Please try again"
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const depositCallback = async (orderId) => {
    try {
      setLoading(true);
      await api.post(`${CONSTANTS.PAYMENT_DEPOSIT_CALLBACK}`, {
        orderId,
      });
    } catch (error) {
      console.error(error);
    } finally {
      getOrderStatus(orderId);
      getWalletDetails();
    }
  };

  useEffect(() => {
    if (!orderId) {
      navigate("/payment", { replace: true });
      toast.error("Something went wrong. Please try again");
    }

    if (!sessionId){
      getOrderStatus(orderId);
      getWalletDetails();
    } else depositCallback(orderId);
  }, [orderId]);

  const getStatusColor = (status) => {
    return status === "SUCCESS" || status === "processed"
      ? "text-success"
      : status === "PENDING" || status?.toLowerCase() === "processing"
        ? "text-warning"
        : "text-danger";
  };

  const statusColor = getStatusColor(orderData?.status);

  return (
    <div>
      <AppHeader />
      <div className="page-container h-91 bg-white overflow-y-scroll">
        <div className="w-100 d-flex flex-column mt-4 px-2">
          <div className="rounded-lg px-3">
            <div className="text-center pb-4">
              <div className={`${statusColor}`}>
                {!loading && (
                  <p className="fw-bold m-0" style={{ fontSize: "3rem" }}>
                    {statusColor === "text-danger" ? (
                      <ImCross />
                    ) : statusColor === "text-success" ? (
                      <BsFillCheckCircleFill />
                    ) : (
                      <AiOutlineInfoCircle />
                    )}
                  </p>
                )}
                <h1 className="fw-bold fs-1">
                  {loading ? (
                    <span className="text-info">Loading...</span>
                  ) : (
                    <span className="text-uppercase">
                      Payment{" "}
                      {orderData?.status?.replace("TXN_", "") || "FAILED"}
                    </span>
                  )}
                </h1>
              </div>
              <hr />
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <strong className="text-black">Transaction Type</strong>
              </div>
              <div className="col-6 text-end">DEPOSIT</div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <strong className="text-black">Reference Number</strong>
              </div>
              <div className="col-6 text-end">{orderData?.id}</div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <strong className="text-black">Amount Requested</strong>
              </div>
              <div className="col-6 text-end">{orderData?.amount}</div>
            </div>
            {orderData && orderData?.service && (
              <>
                <div className="row mb-3">
                  <div className="col-6">
                    <strong className="text-black">Gateway Fees</strong>
                  </div>
                  <div className="col-6 text-end">
                    {orderData?.service?.gateway_charge}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6">
                    <strong className="text-black">GST charges</strong>
                  </div>
                  <div className="col-6 text-end">
                    {orderData?.service?.gst_fee}
                  </div>
                </div>
              </>
            )}
            {orderData?.service?.service_charge && (
              <div className="row mb-3">
                <div className="col-6">
                  <strong className="text-black">Service Charge</strong>
                </div>
                <div className="col-6 text-end">
                  {orderData?.service?.service_charge || "NA"}
                </div>
              </div>
            )}
            {orderData?.service?.service_tax && (
              <div className="row mb-3">
                <div className="col-6">
                  <strong className="text-black">Service Tax</strong>
                </div>
                <div className="col-6 text-end">
                  {orderData?.service?.service_tax || "NA"}
                </div>
              </div>
            )}
            <div className="row mb-3">
              <div className="col-6">
                <strong className="text-black">
                  Amount credited in wallet
                </strong>
              </div>
              <div className="col-6 text-end text-success fw-bold">
                {orderData?.finalamount || "NA"}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <strong className="text-black">Date</strong>
              </div>
              <div className="col-6 text-end">
                {new Date(orderData?.date).toDateString()}{" "}
                {new Date(orderData?.date).toLocaleTimeString()}
              </div>
            </div>
          </div>

          <button
            className="btn bg-primary text-white px-4 py-2 mt-4"
            onClick={() => navigate("/payment", { replace: "true" })}
          >
            Go to payments
          </button>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default DepositStatus;
