import { React, useEffect, useState } from "react";
// import logo from './logonews.png'
import ReactPlayer from "react-player/youtube";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
// import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa'
// import axios from "axios";
// import { Modal, Button, Form, Row, ModalFooter } from "react-bootstrap";
// import { FaCrown, FaExclamation, FaSmile } from "react-icons/fa";
// import StatsModal from './StatsModal/StatsModal';
// import StatsModalWrong from './StatsModal/StatsModalWrong';
import WrongAnswer from "./contestStats/WrongAnswer";
import CorrectAnswer from "./contestStats/CorrectAnswer";
import BackBtnModalAlert from "./BackBtnModalAlert";
import ContestEndModal from "./ContestEndModal";
import {
  CONTEST_FETCH_POLL_DELAY,
  STATS_DELAY_IN_MILLISEC,
  STATS_DELAY_IN_SEC,
} from "../../config/config";
import AppHeader from "../../Components/AppHeader/AppHeader";
import SplashLoader from "../../Components/SplashLoader";
// import BottomNav from "../../Components/BottomNav/BottomNav";
import WinningMsg from "../../Components/SponsorAds/WinningMsg/WinningMsg";
import api from "../../Service/apiService";
import CONSTANTS from "../../Constants/global";
import useUnload from "../../hooks/useUnload";

import "./StatsModal/StatsModal.css";

const Livecontestnew = () => {
  const [value, setValue] = useState("");
  const [viewVideo, setViewVideo] = useState(true);
  const [questions, setQuestions] = useState({});
  const [entryamount, setEntryAmount] = useState();
  const [contestid, setContestId] = useState();
  const [contesttime, setContestTime] = useState();
  const [serverTime, setServerTime] = useState();
  // const [present, setPresent] = useState([])
  const [totalquestions, setTotalQuestions] = useState();
  const [newvideoTime, setVideoTime] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [timertime, setTimerTime] = useState(0);
  // const [runtimer, setRunTimer] = useState(true);
  // const [fullscreen, setFullscreen] = useState(true);
  const [lgShows, setLgShows] = useState(false);
  const [lgShowss, setLgShowss] = useState(false);
  // const [totalQuestionTime, setQuestionTotalTime] = useState();
  const [key, setKey] = useState(0);
  const [reentersuccess, setReentersuccess] = useState(false);
  const [polldata, setPollData] = useState([]);
  const [creditlastamount, setCreditLastAmount] = useState(0);
  const [initialusers, setInitialUsers] = useState(0);
  const [showWinning, setShowWinning] = useState(false);
  const [cashOutCoin, setcashOutCoin] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [initialUsersCalled, setInitialUserCalled] = useState([]); // just to track api is only called once for every question
  const [answerCheckCalled, setAnswerCheckCalled] = useState([]);
  // const [removeuser, setremoveuser] = useState(0);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [pollValueLoading, setPollValueLoading] = useState(false);
  const [checkAnswersLoading, setCheckAnsLoading] = useState(false);
  const [cashoutRenenterLoading, setCashoutReenterLoading] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false); //this toggles the alert modal when back btn is pressed
  const [correctAnswer, setCorrectAnswer] =  useState('');
  const [correctOption, setCorrectOption] = useState('');
  const [wrongAnsMessage, setWrongAnsMessage] = useState('');
  const [contestEndModal,setContestEndModal] = useState(false);
  // For adding values from userhome
  // console.log(present.length,present)
  const navigate = useNavigate();
  let location = useLocation();
  // avoid user to refresh/navigate to other pages
  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function (e) {
      e.stopImmediatePropagation();
      setShowAlertModal(true);
      window.history.pushState(null, "", window.location.href);
    };
  }, []);

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });

  // console.log(questions.totalQuestionTime)
  console.log(questionIndex, totalquestions, "vvvvvvvv", value);
  console.log("zzzzzzzz", reentersuccess);
  useEffect(() => {
    if (!contestid) {
      addData();
    }
  }, [location?.state]);

  function addData() {
    let clientTime = new Date();
    let serverTime = moment(location?.state?.serverTime.serverTime);
    console.log("@@in add data", clientTime, serverTime)

    let diff = serverTime - clientTime;
    console.log("@@ add data diff", diff)
    setServerTime(diff);
    setQuestions(location?.state?.question1);
    // if(location.state.question1.videolink == ''){
    //    setViewVideo(false)
    // }
    setContestTime(location?.state?.ContestTime);
    setEntryAmount(location?.state?.entryamount);
    setContestId(location?.state?.contestid);
    // setPresent(location.state.present == null ? [] : location.state.present)
    setTotalQuestions(location?.state?.totalquestions);
    setQuestionIndex(location?.state?.questionIndex + 1);
    sessionStorage.setItem("questionIndex", location?.state?.questionIndex + 1);
    sessionStorage.setItem("reenter", 1);
    console.log(questions);
  }

  //broadcast Alogorithm

  useEffect(() => {
    console.log("@@ in useeffect questions change");
    let contestStartTime = moment(contesttime);
    // let userEntersTime = new Date();
    let userEntersTime = new Date(Date.now() + serverTime);
    let userDelayTime = userEntersTime - contestStartTime;
    let questionTime = questions?.totalQuestionTime * 1000;
    console.log("Question Time", questionTime, userDelayTime);
    let afterDelayQuestionTime = questionTime - userDelayTime;
    setTimerTime(afterDelayQuestionTime / 1000);
    console.log(
      "afterDelayQuestionTime, contestStartTime, questionIndex",
      afterDelayQuestionTime,
      contestStartTime,
      questionIndex
    );

    if (contestStartTime <= userEntersTime) {
      if (userDelayTime / 1000 >= questions?.totalVideoTime) {
        setViewVideo(false);
      } else {
        let tempVideoTime =
          questions?.totalVideoTime - Math.round(userDelayTime / 1000);

        if (!isNaN(tempVideoTime)) {
          setVideoTime(Math.round(userDelayTime / 1000));
          console.log("userdelay", userDelayTime / 1000);
          console.log("tempVideoTime", tempVideoTime);

          setTimeout(() => {
            setViewVideo(false);
          }, tempVideoTime * 1000);
        }
      }

      //when contest is running
      if (afterDelayQuestionTime > 0) {
        const setTime = setTimeout(function () {
          // checkAnswers();
          if (sessionStorage.getItem("reenter") != 0) {
            getQuestions();
          }

          setLgShowss(false);
          setLgShows(false);
          setKey(key + 1);
          setReentersuccess(false);
          console.log("aaaaaafffdss");
          // clearTimeout(setTime)
        }, afterDelayQuestionTime + STATS_DELAY_IN_MILLISEC);

        const setNewTime = setTimeout(function () {
          checkAnswers();

          console.log("aaaaaafffdss");
          // clearTimeout(setNewTime)
        }, afterDelayQuestionTime);

        return () => {
          clearTimeout(setTime);
          clearTimeout(setNewTime);
        };
      }
    }
  }, [questions]);

  // Cashout

  const cashout = async (cashoutQuesId) => {
    console.log("in cashout");
    // const BASE_URL = `${process.env.REACT_APP_BASE_URL}/broadcast/cashout`;
    const _URL = CONSTANTS.CASHOUT;
    const token = sessionStorage.getItem("token");
    let sendData = {
      contestId: contestid,
      questionId: cashoutQuesId,
    };
    try {
      setCashoutReenterLoading(true);
      const post = await api.post(_URL, sendData);
      if (post && post?.data) {
        if (post.data.status == 1) {
          console.log("cashout success");
          setLgShowss(false);
          setShowWinning(true);
          setcashOutCoin(post.data.ParticularPoll);

          // toast.success(post.data.message)
          // navigate('/appuserhome')
        }
      }
      //TO DO : need to remove after testing
      // let post = await axios.post(BASE_URL, sendData, {
      //    headers: {
      //       "authorization": token,
      //    },
      // });

      // if (post.data.status == 1) {
      //    console.log('cashout success')
      //    setLgShowss(false);
      //    setShowWinning(true);
      //    setcashOutCoin(post.data.ParticularPoll);

      //    // toast.success(post.data.message)
      //    // navigate('/appuserhome')
      // }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //    toast.error(error.response.data.message);
      // }
      //TO DO : need to remove after testing
      //console.log("Oops! Something Went Wrong")
    } finally {
      setCashoutReenterLoading(false);
    }
  };

  // Reenter

  const reenter = async (e) => {
    //const BASE_URL = `${process.env.REACT_APP_BASE_URL}/broadcast/reenter`;
    const _URL = CONSTANTS.REENTER;
    //   const token = sessionStorage.getItem("token");
    let sendData = {
      contestId: contestid,
      questionId: questions.id,
    };
    try {
      setCashoutReenterLoading(true);
      const post = await api.post(_URL, sendData);
      if (post && post?.data) {
        if (post.data.status == 1) {
          setReentersuccess(true);
          toast.success(post.data.message);
          setShowConfirmation(false);
        }
      }
      //TO DO : need to remove after testing
      // let post = await axios.post(BASE_URL, sendData, {
      //    headers: {
      //       "authorization": token,
      //    },
      // });
      // if (post.data.status == 1) {
      //    setReentersuccess(true)
      //    toast.success(post.data.message)
      //    setShowConfirmation(false);
      // }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          setShowConfirmation(false);
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //    setShowConfirmation(false);
      //    toast.error(error.response.data.message)
      // }
      //TO DO : need to remove after testing

      // setShowConfirmation(false);
      // toast.success(e.response.data.message)
      // toast.error(e.response.data.message)
    } finally {
      setCashoutReenterLoading(false);
    }
  };

  // It get calls every question end (question+stats page time )

  const getQuestions = async (e) => {
    setQuestionLoading(true);
        //  when contest gets over
    // if (totalquestions == sessionStorage.getItem('questionIndex')) {
    //    navigate('/appuserhome')
    // }
    const selectedans = localStorage.getItem("selectedans");
    // const BASE_URL = `${process.env.REACT_APP_BASE_URL}/publishedcontest/getData`;
    const _URL = CONSTANTS.GET_LIVE_CONSTES;
    //  const token = sessionStorage.getItem("token");
    let sendData = {
      contestId: contestid,
      segmentId: questions.segmentId,
      questionIndex: sessionStorage.getItem("questionIndex"),
      LivecontestTime: moment(contesttime).add(
        questions?.totalQuestionTime + STATS_DELAY_IN_SEC,
        "seconds"
      ),
      reenter: sessionStorage.getItem("reenter"),
      questionId: questions.id,
      // selectedOption: selectedans
    };
    try {
      const post = await api.post(_URL, sendData);
      if (post && post?.data) {
        //  when contest gets over
        if (totalquestions == questionIndex) {
          setContestEndModal(true);
          return;
          // navigate("/appuserhome");
        }

        console.log(post.data.question);

        setContestTime(
          moment(contesttime).add(
            questions?.totalQuestionTime + STATS_DELAY_IN_SEC,
            "seconds"
          )
        );
        if (post.data.question !== null) {
          // setLgShows(true)
          setQuestions(post.data.question);
          setTimerTime(
            post.data.question?.totalQuestionTime + STATS_DELAY_IN_SEC
          );
          setQuestionIndex(parseInt(post.data.liveindex) + 1);
          console.log("qqqqqqq", parseInt(post.data.liveindex) + 1);
          sessionStorage.setItem(
            "questionIndex",
            parseInt(post.data.liveindex) + 1
          );
          if (post.data.question.videolink !== "") {
            setViewVideo(true);
          }
        }

        nextQuestion();
      }
      //TO DO : need to remove after testing

      // let post = await axios.post(BASE_URL, sendData,{
      //    headers: {
      //       "authorization": token,
      //      },
      //  });
      //  //  when contest gets over
      //  if (totalquestions == questionIndex) {

      //    navigate('/appuserhome')
      // }

      // console.log(post.data.question)

      // setContestTime(moment(contesttime).add(questions?.totalQuestionTime+STATS_DELAY_IN_SEC, 'seconds'))
      // if (post.data.question !== null) {
      //    // setLgShows(true)
      //    setQuestions(post.data.question)
      //    setTimerTime(post.data.question?.totalQuestionTime+STATS_DELAY_IN_SEC)
      //    setQuestionIndex(parseInt(post.data.liveindex) + 1)
      //    console.log("qqqqqqq",parseInt(post.data.liveindex)+1)
      //    sessionStorage.setItem("questionIndex",parseInt(post.data.liveindex)+1)
      //    if (post.data.question.videolink !== '') {
      //       setViewVideo(true)
      //    }
      // }

      // nextQuestion()
      // // if(post.data.status == 0){
      // //    setLgShows(true)
      // // }
      // // if(post.data.status == 1){
      // //    setLgShowss(true)
      // // }

      // console.log("aaddddd", contesttime)
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          if (error.response.status == 400) {
            // navigate("/appuserhome");
            setContestEndModal(true)
            toast.error(error.response.data.message || error.message);
          } else {
            toast.error(error.response.data.message || error.message);
          }
        }
      } else if (!error?.response?.status) {
        toast.error(error.message);
        navigate("/appuserhome");
        return;
      } else {
        // toast.error(error.message);
        console.log("Code error: ", error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //    navigate('/appuserhome');
      // }
      //TO DO : need evaluate for error handling base on API error code
      // console.log(error.response.status, 'qqqqq')
      // if (error.response.status == 400) {
      //    navigate('/appuserhome')
      //    //   toast.success(e.response.data.message)
      // }
    } finally {
      setQuestionLoading(false);
    }
  };

  // It get calls after Question before stats screen

  const checkAnswers = async (e) => {
    console.log("answerCheckCalled", answerCheckCalled, questions?.id);
    if (answerCheckCalled?.includes(questions?.id)) return;

    setCheckAnsLoading(true);
    const selectedans = localStorage.getItem("selectedans");
    console.log(value, questions?.totalQuestionTime);
    // const BASE_URL = `${process.env.REACT_APP_BASE_URL}/broadcast/answerCheck`;
    const _URL = CONSTANTS.ANSWER_CHECK;
    //    const token = sessionStorage.getItem("token");
    let sendData = {
      contestId: contestid,
      questionId: questions.id,
      selectedOption: selectedans,
    };

    if (!sendData?.contestId || !sendData?.questionId)
      toast.error("Invalid question or contestId");

    try {
      setAnswerCheckCalled((prev) => [...prev, questions?.id]);
      const post = await api.post(_URL, sendData);

      if (!isNaN(post.data.InitialUsers)) {
        setInitialUsers(post.data.InitialUsers);
      }
      //TO DO : need to remove after testing
      // let post = await axios.post(BASE_URL, sendData, {
      //    headers: {
      //       "authorization": token,
      //    },
      // });
      console.log(post.data.status);
      if (post.data.status == 0) {
        setLgShows(true);
        setCorrectAnswer(post.data.correctAnswer);
        setCorrectOption(post.data.correctOption);
        if(post.data?.message === "We received the answer late and will be marked wrong"){
          setWrongAnsMessage(post.data?.message);
        }
      }
      if (post.data.status == 1) {
        setLgShowss(true);
        setCorrectAnswer(post.data.correctAnswer);
        setCorrectOption(post.data.correctOption);
      }
      setReentersuccess(false);
      setPollValueLoading(true);
      setTimeout(() => {
        getpollvalues();
      }, CONTEST_FETCH_POLL_DELAY);

      localStorage.removeItem("selectedans");
    } catch (error) {
      setLgShows(true);
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else if (!error?.response?.status) {
        toast.error(error.message);
        navigate("/appuserhome");
        return;
      } else {
        toast.error(error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //    toast.error(error.response.data.message);
      // }
      //TO DO : need to remove after testing
      // console.log("Oops! Something Went Wrong")
    } finally {
      setCheckAnsLoading(false);
    }
  };

  const getpollvalues = async (e) => {
    // const BASE_URL = `${process.env.REACT_APP_BASE_URL}/broadcast/getpollvalues`;
    const _URL = CONSTANTS.GET_POLL_VALUE;
    const token = sessionStorage.getItem("token");
    let sendData = {
      contestId: contestid,
      questionId: questions.id,
    };
    try {
      const post = await api.post(_URL, sendData);
      //TO DO : need to remove after testing
      // let post = await axios.post(BASE_URL, sendData, {
      //    headers: {
      //       "authorization": token,
      //    },
      // });
      if (post && post?.data) {
        setPollData([post.data.ParticularPoll, post.data.LiveUsers]);
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else if (!error?.response?.status) {
        toast.error(error.message);
        navigate("/appuserhome");
        return;
      } else {
        toast.error(error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //    toast.error(error.response.data.message);
      // }
      //TO DO : need to remove after testing
      // console.log("Oops! Something Went Wrong")
    } finally {
      setPollValueLoading(false);
    }
  };

  // getting initial user data

  const getInitialUsers = async (e) => {
    console.log("initialusersArray", initialUsersCalled, questions?.id);
    if (initialUsersCalled?.includes(questions?.id)) return;

    //const BASE_URL = `${process.env.REACT_APP_BASE_URL}/broadcast/getInitialUsers`;
    const _URL = CONSTANTS.GET_INITIAL_USERS;
    let sendData = {
      contestId: contestid,
      questionId: questions.id,
    };

    if (!sendData?.contestId || !sendData?.questionId)
      toast.error("Invalid question or contestId");

    try {
      setInitialUserCalled((prev) => [...prev, questions?.id]);
      const post = await api.post(_URL, sendData);
      if (post && post?.data) {
        setInitialUsers(post.data.InitialUsers);
      }
      //TO DO : need to remove after testing
      // let post = await axios.post(BASE_URL, sendData);
      // setInitialUsers(post.data.InitialUsers)
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else if (!error?.response?.status) {
        toast.error(error.message);
        navigate("/appuserhome");
        return;
      } else {
        toast.error(error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //    toast.error(error.response.data.message);
      // }
      //TO DO : need to remove after testing
      //console.log("Oops! Something Went Wrong")
    }
  };

  //for  getting selected option value .

  function add(e) {
    // const allActiveClasses = document.querySelectorAll('.step_1')
    // for (let i = 0; i < allActiveClasses.length; i++) {
    //    allActiveClasses[i].classList.remove('active');
    // }

    // e.currentTarget.classList.add('active');
    localStorage.setItem("selectedans", e.target.value);
    if (e.target.value === undefined) {
      setValue("");
    } else setValue(e.target.value);
  }

  //submit of answer

  function submitAns() {
    localStorage.setItem("selectedans", value);
    var childNodes = document.getElementById("containersoption");
    let submitBtn = document.getElementById("submitAnsBtn");
    console.log(childNodes);
    childNodes.style.pointerEvents = "none";
    submitBtn.setAttribute("style", "background-color: #26a21b !important;");
  }

  // for next question

  function nextQuestion() {
    var childNodes = document.getElementById("containersoption");
    // let submitBtn = document.getElementById("submitAnsBtn");
    // submitBtn.setAttribute('style', 'background-color: #ff914d !important;');
    if (childNodes) {
      childNodes.style.pointerEvents = "auto";
    }
    var radioNodes = document.getElementsByName("radio");
    console.log(radioNodes, "qqqqqq");
    for (var i = 0; i < radioNodes.length; i++) {
      radioNodes[i].checked = false;
    }
  }

  return (
    <>
      {/* <div className="wrapper position-relative overflow-hidden">
            <div className="container-fluid p-0">
               <div className="row">
                  <div className="col-md-6">
                     <div className="logo_area mt-5 ms-5 ">
                        <a href="#">
                           <img src={logo} alt="image_not_found" className='roll-in-left' />
                        </a>
                     </div>
                  </div>
                  <div className="col-md-6  d-md-block" >
                     {questions && <> <div className="clock_area countdown_timer position-relative mt-5 me-5 float-end" data-countdown="2022/10/24">
                        <img src="./assets/images/clock/clock.png" alt="image_not_found" />
                        <span className="text-white position-absolute"> <CountdownCircleTimer
                           key={key}
                           isPlaying
                           duration={timertime}
                           colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                           colorsTime={[7, 5, 2, 0]}
                           initialRemainingTime
                           onComplete={() => {
                              // do your stuff here
                            
                                 return { shouldRepeat: true , isPlaying: true , newInitialRemainingTime:timertime-STATS_DELAY_IN_SEC}
                        
                               // repeat animation in 1.5 seconds
                            }}
                            onUpdate={(remainingTime)=>{
                                   if(remainingTime== 5 ){
                                      getInitialUsers();
                                   }
                            }} 
                        >
                           {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer></span></div> </>}
                  </div>
               </div>
            </div>
            <div className="container p-0">
               <form className="multisteps_form" id="wizard" >

                  <div className="multisteps_form_panel" style={{ display: 'block' }}>
                     <div className="row">
                        <div className="col-md-7 m-auto">
                           <div className="content_box position-relative">

                              {viewVideo && questions && !questions?.imagepath ?

                                 <div className="player-wrapper">
                                    <ReactPlayer className="react-player" id='player' url={`https://www.youtube.com/embed/${questions?.videolink}?autoplay=1&rel=0&mute=1&start=${newvideoTime}&end=${questions?.totalVideoTime}&modestbranding=1&showinfo=0&fs=0`} frameBorder="0" allowFullScreen playing={true} allow="autoplay" onEnded={() => setViewVideo(false)} width='100%'
                                       height='100%' muted></ReactPlayer></div>
                                 :
                                 <>
                                   {questions?.imagepath && <img src={questions?.imagepath} alt="image" width="500" height="600"></img>}
                                  
                                    <div className="question_number text-uppercase">
                                       <span>question {questionIndex} / {totalquestions}</span>
                                    </div>
                                    <div className="question_title py-3 text-uppercase">
                                       <h1>{questions.question}</h1>
                                    </div>
                                    <div className="form_items  " id='containers'   >
                                       <label className="step_1 rounded-pill position-relative bg-white" onClick={(e) => add(e)}>
                                          {questions.options[0]}
                                          <input type="radio" name="stp_1_select_option" value={questions.options[0]} />
                                          <span className="position-absolute">A</span>
                                       </label>
                                       <label className="step_1 rounded-pill position-relative bg-white " onClick={(e) => add(e)}>
                                          {questions.options[1]}
                                          <input type="radio" name="stp_1_select_option" value={questions.options[1]} />
                                          <span className="position-absolute">B</span>
                                       </label>
                                       <label className="step_1 rounded-pill position-relative bg-white " onClick={(e) => add(e)}>
                                          {questions.options[2]}
                                          <input type="radio" name="stp_1_select_option" value={questions.options[2]} />
                                          <span className="position-absolute">C</span>
                                       </label>
                                       <label className="step_1 rounded-pill position-relative bg-white" onClick={(e) => add(e)}>
                                          {questions.options[3]}
                                          <input type="radio" name="stp_1_select_option" value={questions.options[3]} />
                                          <span className="position-absolute">D</span>
                                       </label>
                                    </div></>
                              }
                            
                           </div>
                        </div>
                     </div>
                  </div>



                  <div className="left-side-img">
                     <img src="./assets/images/background/bg_1.png" alt="image_not_found" />
                  </div>


                  <div className="step_progress position-absolute">
                     <div className="step rounded-pill position-relative text-center active">1</div>
                     <div className="step rounded-pill position-relative text-center mt-4">2</div>
                     <div className="step rounded-pill position-relative text-center mt-4">3</div>
                     <div className="step rounded-pill position-relative text-center mt-4">4</div>
                  </div>


               </form>

               <div className="form_btn">
                  <button type="button" className="f_btn prev_btn text-uppercase rounded-pill position-absolute" id="prevBtn" onClick={(e) => nextQuestion(1)} > Next
                     Question <span><i className="fas fa-arrow-right"></i></span></button>
                  <button type="button" className="f_btn next_btn text-uppercase rounded-pill position-absolute" id="nextBtn" onClick={submitAns}
                  >Submit </button>
               </div>
            </div>
         </div> */}

      {questionLoading || checkAnswersLoading ? (
        <SplashLoader />
      ) : (
        <>
          <AppHeader />
          {showWinning && (
            <ContestEndModal open={showWinning}/>
            // <WinningMsg
            //   status={"success"}
            //   winningcoin={cashOutCoin}
            //   nextUrl={"/adslist"}
            //   isCashout={true}
            // ></WinningMsg>
          )}
          <div className="inner-page-container">
            <div className="container-fluid">
              <section>
                <div className="row">
                  <div className="col-sm-12 pt-3 bg-white live-top-info py-4 mt-3">
                    <div className="d-flex justify-content-around">
                      <div className="live-ques-detail">
                        <div className="d-flex flex-column text-center">
                          <p className="fs-2 mb-0 fw-bold text-dark-orange">
                            Question {questionIndex}
                          </p>
                          <p className="mb-0 fs-6 font-italic">
                            {questionIndex}/{totalquestions}
                          </p>
                        </div>
                      </div>
                      {/* <div className="live-user-info"> // initially no data is available, so it shows 0, correct data is only available 5 seconds before question ends, we might use this in future
                              <div className="">
                                 <span className='user-info-item d-inline-block'><i className="fas fa-user text-dark-orange"></i></span>
                                 <span className='user-info-item  d-inline-block'>{initialusers}</span>
                                 <span className='user-info-item  d-inline-block'><i className="fas fa-star"></i></span>
                              </div>
                           </div> */}
                    </div>
                  </div>
                </div>
              </section>
              {/*Active player ribbon starts */}
              <section className="single-question">
                <div className="row">
                  <div className="col-xs-12 col-md-12 mt-2">
                    <div className="page-timer d-flex justify-content-center">
                      <span className="text-white bg-dark-orange mt-2 d-inline-block small-ribbon">
                        <CountdownCircleTimer
                          key={key}
                          isPlaying
                          duration={timertime}
                          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                          colorsTime={[7, 5, 2, 0]}
                          initialRemainingTime
                          size={70}
                          strokeWidth={5}
                          onComplete={() => {
                            // do your stuff here

                            return {
                              shouldRepeat: true,
                              isPlaying: true,
                              newInitialRemainingTime:
                                timertime - STATS_DELAY_IN_SEC,
                            };

                            // repeat animation in 1.5 seconds
                          }}
                          onUpdate={(remainingTime) => {
                            if (remainingTime == 5 && !lgShows && !lgShowss) {
                              getInitialUsers();
                            }
                          }}
                        >
                          {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                      </span>
                    </div>

                    {!viewVideo && questions ? (
                      <div className="info-oval-ribbon d-flex justify-content-between bg-dark-orange ps-5 py-3">
                        <div className="question-text text-white">
                          {questionIndex} / {totalquestions}{" "}
                          {questions.question}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {viewVideo && questions && !questions?.imagepath ? (
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-3 d-flex justify-content-center">
                      <div className="video-player-wraper d-flex flex-column justify-content-center align-items-center gap-2">
                        <ReactPlayer
                          className=" react-player"
                          id="player"
                          url={`https://www.youtube.com/embed/${questions?.videolink}?autoplay=1&rel=0&mute=1&start=${newvideoTime}&end=${questions?.totalVideoTime}&modestbranding=1&showinfo=0&fs=0`}
                          frameBorder="0"
                          allowFullScreen
                          playing={true}
                          allow="autoplay"
                          onEnded={() => setViewVideo(false)}
                          width="100%"
                          height="100%"
                          volume={1}
                          // muted={muted}
                        ></ReactPlayer>
                        {/* <span onClick={() => setMuted(!muted)} className="d-flex justify-content-center align-items-center fs-2 speaker-icon p-3">
                                 {  muted ? <FaVolumeMute /> : <FaVolumeUp /> } 
                                 </span> */}
                      </div>
                    </div>
                  ) : (
                    <>
                      {questions?.imagepath && (
                        <div className="col-xs-12 col-sm-12 col-md-12 pt-3 d-flex justify-content-center">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PATH_URL}${questions?.imagepath}`}
                            className="ques-img"
                            alt=""
                          />
                        </div>
                      )}

                      <div className="col-xs-12 col-sm-12 col-md-12 pt-3">
                        <div className="question-options" id="containersoption">
                          <div className="radio-tile-group">
                            <div className="input-container">
                              <input
                                id="walk"
                                className="radio-button"
                                type="radio"
                                name="radio"
                                value={questions?.options[0]}
                                onClick={(e) => add(e)}
                              />
                              <div className="radio-tile">
                                <label
                                  htmlFor="walk"
                                  className="radio-tile-label"
                                >
                                  A) {questions?.options[0]}
                                </label>
                              </div>
                            </div>

                            <div className="input-container">
                              <input
                                id="bike"
                                className="radio-button"
                                type="radio"
                                name="radio"
                                value={questions?.options[1]}
                                onClick={(e) => add(e)}
                              />
                              <div className="radio-tile">
                                <label
                                  htmlFor="bike"
                                  className="radio-tile-label"
                                >
                                  B) {questions?.options[1]}
                                </label>
                              </div>
                            </div>

                            <div className="input-container">
                              <input
                                id="drive"
                                className="radio-button"
                                type="radio"
                                name="radio"
                                value={questions?.options[2]}
                                onClick={(e) => add(e)}
                              />
                              <div className="radio-tile">
                                <label
                                  htmlFor="drive"
                                  className="radio-tile-label"
                                >
                                  C) {questions?.options[2]}
                                </label>
                              </div>
                            </div>

                            <div className="input-container">
                              <input
                                id="fly"
                                className="radio-button"
                                type="radio"
                                name="radio"
                                value={questions?.options[3]}
                                onClick={(e) => add(e)}
                              />
                              <div className="radio-tile">
                                <label
                                  htmlFor="fly"
                                  className="radio-tile-label"
                                >
                                  D) {questions?.options[3]}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center pt-2 mt-4">
                              <button type="button" className="btn btn-orange text-white" id="submitAnsBtn" onClick={submitAns}>Submit</button>
                           </div> */}
                    </>
                  )}
                </div>
              </section>
              {/* Active player ribbon ends */}
            </div>
          </div>
        </>
      )}
      <BackBtnModalAlert
        showModal={showAlertModal}
        setShowModal={setShowAlertModal}
      />
      {/* <StatsModalWrong lgShow={lgShows} setLgShow={setLgShows} reenter={reenter} reentersuccess={reentersuccess} setReentersuccess={setReentersuccess} polldata={polldata} totalquestions={totalquestions} initalusers={initialusers} /> 
                  <StatsModal  lgShow={lgShowss} setLgShow={setLgShowss} cashout={cashout} polldata={polldata} creditlastamount={creditlastamount} contestid={contestid} totalquestions={totalquestions} initalusers={initialusers} /> */}
      <WrongAnswer
        lgShow={lgShows}
        setLgShow={setLgShows}
        correctAnswer = {correctAnswer}
        correctOption =  {correctOption}
        loading={cashoutRenenterLoading}
        reenter={reenter}
        reentersuccess={reentersuccess}
        setReentersuccess={setReentersuccess}
        polldata={polldata}
        totalquestions={totalquestions}
        initalusers={initialusers}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        currentQuesId={questions?.id}
        contestid={contestid}
        pollValueLoading={pollValueLoading}
        message={wrongAnsMessage}
        setContestEndModal={setContestEndModal}
      />
      <CorrectAnswer
        lgShow={lgShowss}
        setLgShow={setLgShowss}
        correctAnswer = {correctAnswer}
        correctOption =  {correctOption}
        loading={cashoutRenenterLoading}
        cashout={cashout}
        polldata={polldata}
        creditlastamount={creditlastamount}
        contestid={contestid}
        totalquestions={totalquestions}
        initalusers={initialusers}
        currentQuesId={questions?.id}
        pollValueLoading={pollValueLoading}
      />
      <ContestEndModal open={contestEndModal} />
    </>
  );
};

export default Livecontestnew;
