import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const KycCheckModal = ({
  show,
  handleClose,
  kycData,
  loading,
  handleClick,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h1>Confirmation</h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="m-0">{kycData?.message}</p>
        <p>Do you want to continue?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => handleClick({ reKyc: true, ...kycData })} disabled={loading}
        >
          {loading ? "Processing..." : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default KycCheckModal;
