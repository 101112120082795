// import React from 'react';
// import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import PullToRefresh from "react-simple-pull-to-refresh";

import moment from "moment";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ReactPaginate from "react-paginate";
// import { STATS_DELAY_IN_SEC } from '../../config/config';

//API URL
import CONSTANTS from "../../Constants/global";

//import common components
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../../Components/BottomNav/BottomNav";

import Spinner from "../../Components/Spinner";
import ContestCard from "./ContestCard";

import toast from "react-hot-toast";
//import noQuizz from "../../../public/assets/images/no-quiz.webp"
import api from "../../Service/apiService";
import { ACTIONS, useAppContext } from "../../context/AppContext";

export default function AppUserHome() {
  //  console.log('this is base url from global ', CONSTANTS.BASE_URL)
  const [contests, setContest] = useState([]);
  const [appliedcontests, setAppliedContests] = useState([]);
  // const [user, setUser] = useState("");
  // const [wallet, setUserWallet] = useState();
  const [pagenumber, setPagenumber] = useState(1); //need to remove after getting data from API
  const [pagelimit, setPagelimit] = useState(3); //need to remove after getting data from API
  const [totalrecCount, setTotalrecCount] = useState(); //need to remove after getting data from API
  // const [startsin, setStartsin] = useState();
  const [pageCount, setPageCount] = useState();
  const [nextQuizCounter, setNextQuizCounter] = useState(0);

  const [loading, setLoading] = useState(false);

  const { appState, updateAppState } = useAppContext();

  const navigate = useNavigate();
  const calculateDifference = (contestTime) => {
    let contestDateTime = moment(contestTime);
    // console.log(contestDateTime)
    let currentdate = new Date();
    let currentTime = moment(currentdate).format("h:mm:ss");
    // console.log(currentdate)
    let newdifference = contestDateTime - currentdate;
    //console.log(newdifference)
    return Math.floor(newdifference / 1000);
  };
  // Handle Pagination click
  const handlePageClick = (item) => {
    let pageclicked = item.selected + 1;
    //console.log('page clicked ', pageclicked)
    setPagenumber(pageclicked);
    getContest(pageclicked, pagelimit);
  };

  const getWalletDetails = async() => {
    try {
      const res = await api.get(CONSTANTS.GETWALLETAMOUNT);
      updateAppState(ACTIONS?.WALLET, res?.data?.userwallet);
    } catch (error) {
      console.error(error)  
    }
  }

  // get all contest data
  const getSingleContest = async (loader = true, isRefreshed = false) => {
    loader && setLoading(true);

    // const allContestUrl = CONSTANTS.APPLIEDCONTESTLIST;
    // const token = sessionStorage.getItem("token");
    // const HEADERS = { "authorization": token, }
    try {
      const res = await api.get(CONSTANTS.APPLIEDCONTESTLIST);
      if (res.data && res.data?.contest) {
        let response = res?.data?.contest;
        console.log("response is ", response);
        setAppliedContests([...response]); // applied contest list should update from this response
        const sortedres = response
          ?.filter((contest) => !moment().isAfter(contest.contestEndTime))
          .sort(function (a, b) {
            return (
              new Date(a.contestTime).getTime() -
              new Date(b.contestTime).getTime()
            );
          });
        console.log("nextCounter", sortedres, nextQuizCounter);
        if ((nextQuizCounter == 0 || isRefreshed) && sortedres[0]) {
          // let contesttime = sortedres[0]?.contestTime;
          // let contestendtime = sortedres[0]?.contestEndTime;
          //console.log('next record 0th', sortedres[0])
          // setStartsin(calculateDifference(contesttime));
          updateAppState(ACTIONS.NEXT_CONTEST, sortedres[0]);
        } else {
          if (sortedres[nextQuizCounter]) {
            // let contesttime = sortedres[nextQuizCounter]?.contestTime;
            // let contestendtime = sortedres[nextQuizCounter]?.contestEndTime;
            // setStartsin(calculateDifference(contesttime));
            updateAppState(ACTIONS.NEXT_CONTEST, sortedres[nextQuizCounter]);
          } else {
            // setStartsin(0);
            updateAppState(ACTIONS.NEXT_CONTEST, null);
            setAppliedContests([]);
          }
        }
        setNextQuizCounter((prevCounter) =>
          isRefreshed ? 0 : prevCounter + 1
        );
      }
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //     toast.error(error.response.data.message);
      // }
      //TO DO : need to remove after testing
      // if (error && error.response) {
      //     toast.error(error.response.data.message);
      //     if (error.response.status === 401) {
      //         localStorage.clear();
      //         sessionStorage.clear();
      //         navigate("/");
      //     }
      // } else {
      //     toast.error('Something went wrong');
      // }
    } finally {
      setLoading(false);
    }
  };
  // const getSingleContest = async () => {
  //     const allContestUrl = CONSTANTS.APPLIEDCONTESTLIST;
  //     const token = sessionStorage.getItem("token");
  //     const HEADERS = { "authorization": token, }
  //     try {
  //         await axios.get(allContestUrl, {
  //             headers: HEADERS,
  //         }).then((res) => {
  //             // console.log('from single for timer', res.data.contest);
  //             let response = res?.data?.contest;
  //             console.log('response is ', response);
  //             const sortedres = response.sort(function (a, b) {
  //                 return moment.utc(a.contestTime).diff(moment.utc(b.contestTime))
  //             });
  //             if (nextQuizCounter == 0) {
  //                 let contesttime = sortedres[0]?.contestTime;
  //                 let contestendtime = sortedres[0]?.contestEndTime;
  //                 console.log('next record 0th', sortedres[0])
  //                 setStartsin(calculateDifference(contesttime));
  //             } else {
  //                 if (sortedres[nextQuizCounter]) {
  //                     let contesttime = sortedres[nextQuizCounter]?.contestTime;
  //                     let contestendtime = sortedres[nextQuizCounter]?.contestEndTime;
  //                     setStartsin(calculateDifference(contesttime));
  //                 } else {
  //                     setStartsin(0);
  //                     setAppliedContests([]);
  //                 }

  //             }
  //             setNextQuizCounter(nextQuizCounter + 1);

  //         });
  //     } catch (error) {
  //         console.log('error is ', error.response)
  //         if (error && error.response) {
  //             toast.error(error.response.data.message);
  //             if (error.response.status === 401) {
  //                 localStorage.clear();
  // 	            sessionStorage.clear();
  //                 navigate("/");
  //             }
  //         } else {
  //             toast.error('Something went wrong');
  //         }
  //     }
  // }

  // get all contest data
  const getContest = async (number, limit) => {
    //console.log('inside get contest1')
    const allContestUrl = `${CONSTANTS.GETPUBLISHEDCONTEST}?page=${number}&limit=${limit}`;
    //  const token = sessionStorage.getItem("token");
    // const HEADERS = { "authorization": token, }
    try {
      const res = await api.get(allContestUrl);
      if (res.data && res.data?.contests) {
        console.log(res);
        let responseAll = res?.data?.contests;

        setContest([...responseAll]);
        //setAppliedContests([...res?.data?.appliedcontests]); // applied contest list should update from getSingleContest
        // setUser(res?.data?.user)
        // setUserWallet(res?.data?.wallet);
        updateAppState(ACTIONS?.WALLET, res?.data?.wallet);
        setTotalrecCount(res?.data?.count);
        setPagelimit(res?.data?.page_size);
        let totalcount = Math.ceil(totalrecCount / pagelimit);
        //setPageCount(totalcount);
      }
      //TODO : need to remove after testing
      // axios.get(allContestUrl, {
      //     headers: HEADERS,
      // }).then((res) => {
      //     console.log(res);
      //     let responseAll = res?.data?.contests;
      //     const sortedAll = responseAll.sort(function (a, b) {
      //         return moment.utc(b.contestTime).diff(moment.utc(a.contestTime))
      //     });
      //     setContest([...sortedAll]);
      //     setAppliedContests([...res?.data?.appliedcontests]);
      //     setUser(res?.data?.user)
      //     setUserWallet(res?.data?.wallet);
      //     setTotalrecCount(res?.data?.count);
      //     setPagelimit(res?.data?.page_size);
      //     let totalcount = Math.ceil(totalrecCount / pagelimit);
      //     //setPageCount(totalcount);
      // });
    } catch (error) {
      console.log("error is ", error);
      if (error.response) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          toast.error(error.response.data.message || error.message);
        }
      } else {
        toast.error(error.message);
      }
      // if (error?.response?.status !== 403 && error?.response?.status !== 401) {
      //     toast.error(error.response.data.message);
      // }
      //TODO : need to remove after testing
      // if (error && error.response) {
      //     console.log('error is ', error)
      //     toast.error(error.response.data.message);
      //     if (error.response.status === 401) {
      //         localStorage.clear();
      //         sessionStorage.clear();
      //         navigate("/");
      //     }
      // } else {
      //     toast.error('Something went wrong');
      // }
    }
  };

  //Render will return template to display on clock timer
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer text-white fs-6">Live</div>;
    }

    return (
      <div className="timer">
        <div className="value fs-5">{remainingTime}</div>
      </div>
    );
  };
  // Get All COntest Data
  useEffect(() => {
    getContest(pagenumber, pagelimit);
    getWalletDetails()
  }, []);
  // get single record for timier
  useEffect(() => {
    getSingleContest();
  }, []);
  // update page count
  useEffect(() => {
    let totalcount = Math.ceil(totalrecCount / pagelimit);
    setPageCount(totalcount);
    // console.log('totalrecCount use effr=ect called', totalrecCount)
  }, [totalrecCount]);
  // useEffect(() => {

  //     console.log('totalcount  is here', pageCount)
  // }, [pageCount]);

  //Navigate if user is not logged-in
  useEffect(() => {
    let status = localStorage.getItem("loginStatus");
    if (!status) {
      navigate("/");
    }
  }, []);

  const handleRefresh = () => {
    getContest(pagenumber, pagelimit);
    getSingleContest(true, true);
  };

  console.log("current contest", appState?.nextContest);

  return (
    <div>
      <AppHeader />
      <PullToRefresh
        onRefresh={handleRefresh}
        pullDownThreshold={10}
        refreshingContent={""}
      >
        <div className="page-container ">
          {loading ? (
            <div className="h-70vh">
              {" "}
              <Spinner />{" "}
            </div>
          ) : (
            <div className="container-fluid">
              <Link to={"/adhome"}>
                <section className="advertisement ">
                  <div className="row bg-light-orange">
                    <div className="col-sm-12 col-xs-12">
                      <div className="text-section py-3 text-white text-center d-flex flex-column align-items-center">
                        <h1>Watch Daily Ads to Win Money</h1>
                        <span className="go-advertisement">
                          <i
                            className="fas fa-plus fa-2x"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </Link>
              {/* <section className="remaining-quiz">
                        <div className="row bg-lighter-gray py-5 b-radius-25">
                            <div className="col-3 col-xs-3">
                                <i className="fa fa-trophy fa-5x trophy" aria-hidden="true"></i>
                            </div>
                            <div className="col-9 col-xs-9">
                                <h3 className='fs-5 fw-normal text-center text-orange'> Remaining Quiz</h3>
                                <p className='fs-2 text-center'>{contests.length} quizzes <i className="fas fa-arrow-right " aria-hidden="true"></i></p>
                            </div>
                        </div>
                    </section> */}
              {/* next-quiz section starts */}
              <section className="next-quiz">
                <div className="row py-3">
                  <div className="col-sm-12 col-xs-12 ">
                    <div className="text-center py-1 section-head">
                      Next quiz starts in{" "}
                    </div>
                    {/* {JSON.stringify({endTime:appState?.nextContest?.contestEndTime, diff: calculateDifference(appState?.nextContest?.contestTime)})} */}
                    {!!appliedcontests?.length &&
                    appState?.nextContest &&
                    !moment(appState?.nextContest?.contestEndTime).isBefore(
                      new Date()
                    ) ? (
                      <div className="clock_area countdown_timer d-flex justify-content-center text-center">
                        <Link to={"/appliedcontest"}>
                          <div className="position-relative" role="button">
                            <img
                              src="./assets/images/clock/clock.png"
                              alt="timer_image"
                            />
                            <span className="countdown-wrapper text-white position-absolute">
                              <CountdownCircleTimer
                                isPlaying
                                duration={calculateDifference(
                                  appState?.nextContest?.contestTime
                                )}
                                // initialRemainingTime={calculateDifference(appState?.nextContest?.contestTime)}
                                colors={[
                                  "#004777",
                                  "#F7B801",
                                  "#A30000",
                                  "#A30000",
                                ]}
                                colorsTime={[15, 10, 5, 0]}
                                onComplete={() => ({
                                  shouldRepeat: false,
                                  delay: 0,
                                })}
                                onUpdate={(remainingTime) => {
                                  if (remainingTime == 2) {
                                    // getSingleContest(false);
                                  }
                                }}
                              >
                                {renderTime}
                              </CountdownCircleTimer>
                            </span>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <div style={{ flex: 4 }}>
                          <img src="./assets/images/no-quiz.webp" alt="" />
                        </div>
                        <div
                          style={{ flex: 6 }}
                          className="text-secondary fs-5 fw-400"
                        >
                          <p className="">No Quiz registered. </p>
                          <p className="mb-0 ">Please register to play.</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              {/* next-quiz section Ends */}
              {/* quiz today section starts */}
              <section className="quiz-today">
                <div className="row py-3">
                  <div className="col-sm-12 col-xs-12">
                    <p className="py-3 section-head">Quizzes </p>
                  </div>
                </div>
                {contests.length > 0 ? (
                  <div className="row">
                    {/* {contests.map((res, index) => {
                                        //Key added to remove error "Each child in a list should have a unique 'key' prop".
                                        return (<div key={index}>
                                            {/* card item starts */}
                    {/* <div className="col-xl-3  col-sm-12 col-xs-12 mb-30" key={index}>
                                                <Link to="/singleQuizdetail" state={{ contestName: res.contestName, EntryAmount: res.EntryAmount, contestTime: res.contestTime, contestid: res.id }} >
                                                    <div className="card-item-box height-100-p card-item" >
                                                        <div className="d-flex align-items-center">
                                                            <div className="quiz-image"><div>
                                                                <img src="./assets/images/quiz-icon.jpg" />
                                                            </div>
                                                            </div>
                                                            <div className="quiz-text">
                                                                <div className="h6 mb-0 pb-1">{res?.contestName}</div>
                                                                <div className="fs-6 mb-0 pb-1">Time:{moment(res.contestTime).format("DD/MM HH:mm")}</div>
                                                                <div className="">
                                                                    <span className='text-gray d-inline-block pe-3 fs-5'>Entry Fee</span>
                                                                    <span className='text-yellow fs-5'>{`₹ ${res.EntryAmount}`} <i className='coin'></i></span>
                                                                </div>
                                                            </div>
                                                            <div className="quiz-arrow text-end">
                                                                {
                                                                    (moment().isBetween(moment(res.contestTime), moment(res.contestEndTime)))
                                                                        ? <button className='btn btn-sm btn-success btn-live'>Live</button>
                                                                        : (moment().isAfter(moment(res.contestTime)))
                                                                            ? <button className='btn btn-sm btn-warning btn-live'>Expired</button>
                                                                            : (moment().isBefore(moment(res.contestTime)))
                                                                                ? ""
                                                                                : ""
                                                                }

                                                                <i className="fas fa-arrow-right"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>

                                            </div>{/* card item Ends */}
                    {/* </div>
                                        ) */}
                    {/* }})}} */}

                    {contests.map((res, index) => (
                      <ContestCard key={index} res={res} />
                    ))}
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-sm-12 text-center no-quiz">
                      <h1>No Quiz !!</h1>
                      <img src="./assets/images/no-quiz.png" alt="" />
                    </div>
                  </div>
                )}
              </section>{" "}
              {/* quiz today section Ends */}
              {/* pagination starts */}
              <section>
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-center">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      previousLabel="< previous"
                      pageCount={pageCount}
                      forcePage={pagenumber - 1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination pagination-sm"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      renderOnZeroPageCount={null}
                    />
                    {/* <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                  </div>
                </div>
              </section>
              {/* pagination ends */}
            </div>
          )}
        </div>
      </PullToRefresh>
      <BottomNav />
    </div>
  );
}
