import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CONSTANTS from "../../Constants/global";

import { BsArrowLeft } from "react-icons/bs";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ errorName: "", errorMsg: "" });
  // const [showmessage, setshowMesaage] = useState(false);
  // const [toastMessage, setToastMessage] = useState("");
  // const [toastColor, setToastColor] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function handleForm(event) {
    if (!email) {
      setError({ errorName: "email", errorMsg: "please enter your email" });
      return;
    }
    if (!isEmail(email)) {
      setError({
        errorName: "email",
        errorMsg: "Invalid email!",
      });
      return;
    }
    return true;
  }
  function isEmail(val) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (handleForm()) {
      const sendData = {
        email,
      };
      const SEND_OTP = CONSTANTS.SEND_OTP;
      try {
        setLoading(true);
        const data = await axios.post(SEND_OTP, sendData);
        console.log(data);
        // setToastMessage(data.data.message);
        // setshowMesaage(true);
        if (data.data.status === 1) {
          toast.success(data.data.message);
          setTimeout(() => {
            navigate("/resetPassword", { state: { email: email } });
          }, [2000]);
        } else {
          toast.error(data.data.message);
        }
      } catch (e) {
        toast.error("You have entered an invalid email");
        // setshowMesaage(true);
        // setToastMessage("You have entered an invalid email");
      } finally {
        setLoading(false);
      }
    }
  }
  return (
    <>
      {/* <div className="login-header box-shadow">
		<div className="container-fluid d-flex justify-content-between align-items-center">
			<div className="brand-logo">
				<Link to='/'>
					{/* <img src="vendors/images/money3.png" alt="" /> 
          <img src="assets/images/log01.png" alt="" />
				</Link>
			</div>
			<div className="login-menu">
				<ul>
					<li><Link to="/">Sign In</Link></li>
				</ul>
			</div>
		</div>
	</div> */}
      <div className="login-wrap min-h-screen bg-white d-flex flex-wrap">
        <div>
          <div className="px-5 display-6" onClick={() => navigate("/")}>
            <BsArrowLeft />
          </div>

          <div className="text-center">
            <img
              src="./assets/images/reset.svg"
              alt="reset-pass"
              style={{
                height: "200px",
                width: "200px",
              }}
            />
          </div>

          <div className="align-items-center">
            <div className="col-sm-6">
              <div className="mt-4 px-5">
                <div className="login-title">
                  <h1 className="font-bold">Password Reset</h1>
                </div>
                <p className="mb-20">
                  Enter your email address to reset your password
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="input-group custom">
                    <div className="input-group-prepend custom">
                      <span className="input-group-text h-100">
                        <i className="fas fa-envelope" aria-hidden="true"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setError({
                          errorName: "",
                          errorMsg: "",
                        });
                      }}
                    />
                  </div>
                  {error && error.errorName === "email" && (
                    <div style={{ color: "red" }}>{error.errorMsg}</div>
                  )}
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="input-group mb-0">
                        <button
                          disabled={loading}
                          className="btn  btn-sm btn-block bg-dark-orange text-white d-flex justify-content-between align-items-center w-100 px-4 py-2"
                          type="submit"
                        >
                          {loading ? (
                            <div className="d-flex mx-auto px-4 py-2">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </div>
                          ) : (
                            <>
                              <span>Submit</span>{" "}
                              <i className="fas fa-sign-in-alt"></i>
                            </>
                          )}
                        </button>
                        {/* <input
                          className="btn btn-primary btn-lg btn-block"
                          type="submit"
                          value="Submit A"
                        /> */}
                        {/* <a className="btn btn-primary btn-lg btn-block" href="index.html">Submit</a> */}
                      </div>
                    </div>
                    {/* <div className="col-2">
                      <div
                        className="font-16 weight-600 text-center"
                        data-color="#707373"
                      >
                        OR
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="input-group mb-0">
                        <Link
                          to="/"
                          className="btn btn-outline-primary btn-lg btn-block"
                        >
                          Login
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <ToastContainer position="top-end" style={{paddingTop:"3rem",width:'166px',right:'7px',top:'25px'}} >
          <Toast
            show={showmessage}
            bg={toastColor}
            onClose={() => setshowMesaage(false)}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto"></strong>
              <small className="text-muted"></small>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </ToastContainer> */}
      </div>
    </>
  );
};

export default ForgetPassword;
