export let STATS_DELAY_IN_SEC = 30;
export let STATS_DELAY_IN_MILLISEC = 30000;

export const CONTEST_FETCH_POLL_DELAY = process.env.REACT_APP_CONTEST_FETCH_POLL_DELAY || 10000;

export const toastConfig = {
  duration: 2000,
  style: {
    borderRadius: "10px",
    background: "#333",
    color: "#fff",
  },
};
