import { useEffect } from "react";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// components
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../../Components/BottomNav/BottomNav";
import Loader from "../../Components/Loader";
// utils and helpers
import CONSTANTS from "../../Constants/global";
import api from "../../Service/apiService";
import { amountValidation } from "../../Utils/Utils";
import { useAppContext } from "../../context/AppContext";

const PAYMENT_MODES_BANK = ["NEFT", "IMPS"]; //RTGS

const Withdraw = () => {
  const [amount, setAmount] = useState(0);
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [feesData, setFeesData] = useState(null);
  const [feesDataLoading, setFeesDataLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { appState } = useAppContext();

  const withdrawal = async () => {
    let amtValidation = amountValidation(amount);
    if (amtValidation) return toast.error(amtValidation);
    if (
      location?.state?.account_number &&
      (!mode || !PAYMENT_MODES_BANK?.includes(mode))
    ) {
      toast.error("Please select payment mode");
      return;
    }

    if (Number(appState?.wallet) < Number(amount))
      return toast.error("You do not have sufficient balance for withdrawal");

    try {
      setLoading(true);
      const data = {
        ...location?.state,
        fund_id: location?.state?.id,
        address: location?.state?.upi_address || null,
        account_type: location?.state?.upi_address ? "vpa" : "bank_account",
        mode: location?.state?.upi_address ? "UPI" : mode,
        email: localStorage.getItem("email"),
        amount,
      };
      const res = await api.post(CONSTANTS.PAYMENT_WITHDRAW, data);
      setTimeout(() => {
        toast.success(res.data?.message);
      }, 300);
      navigate("/payment", { replace: true });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, Try again"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isNaN(amount) || Number(amount) < Number(process.env.REACT_APP_MIN_AMOUNT)) {
      setFeesData(null);
      return;
    }

    if (
      location?.state?.account_number &&
      (!mode || !PAYMENT_MODES_BANK?.includes(mode))
    )
      return;

    const getData = setTimeout(() => {
      setFeesDataLoading(true);
      setFeesData(null);
      api
        .post(`${CONSTANTS.PAYMENT_FEE_DEDUCTION}`, {
          mode: location?.state?.upi_address ? "UPI" : mode,
          account_type: location?.state?.account_number
            ? "bank_account"
            : "vpa",
          amount: amount,
        })
        .then((response) => {
          setFeesData(response?.data?.fee_structure);
        })
        .catch((err) =>
          toast.error(
            err.message ||
              "We could not process your transaction, Please try after some time."
          )
        )
        .finally(() => {
          setFeesDataLoading(false);
        });
    }, 500);

    return () => clearTimeout(getData);
  }, [amount, mode]);

  return (
    <div>
      <AppHeader />
      <div className="page-container px-4 h-91 bg-white px-2 overflow-y-scroll">
        <h1 className="mt-3">Withdraw</h1> <hr className="m-0 mb-3" />
        <div
          className="border rounded-4 py-2 px-3"
          style={{
            boxShadow:
              "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
          }}
        >
          <table className="table">
            <tbody>
              <tr>
                <td scope="row">Name</td>
                <td className="text-end">{location?.state?.name}</td>
              </tr>
              <tr>
                <td scope="row">Contact</td>
                <td className="text-end">{location?.state?.contact}</td>
              </tr>
              {location?.state?.account_number && (
                <Fragment>
                  <tr>
                    <td scope="row">Acc No.</td>
                    <td className="text-end">
                      {location?.state?.account_number}
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">IFSC</td>
                    <td className="text-end">{location?.state?.ifsc}</td>
                  </tr>
                </Fragment>
              )}
              {location?.state?.upi_address && (
                <tr>
                  <td scope="row">UPI</td>
                  <td className="text-end">{location?.state?.upi_address}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div>
          <div className="form-group mt-4">
            <label>Amount:</label>
            <input
              type="number"
              className="form-control w-100"
              placeholder="Enter amount:"
              value={amount}
              name="amount"
              onChange={(e) => setAmount(e.target.value)}
            />
            <p className="text-danger p-1 mb-0">
              Note: Minimum of {process.env.REACT_APP_MIN_AMOUNT} needs to be withdrawn
            </p>
          </div>
          {location?.state?.account_number && (
            <div className="form-group mt-4">
              <label>Transfer Mode:</label>
              <select
                value={mode}
                onChange={(e) => setMode(e.target.value)}
                className="form-select w-100"
              >
                <option hidden value="">
                  Select
                </option>
                {PAYMENT_MODES_BANK?.map((mode) => (
                  <option key={mode} value={mode}>
                    {mode?.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          )}

          {feesDataLoading && <Loader />}

          {feesData && (
            <div
              className="border rounded-4 py-2 px-3"
              style={{
                boxShadow:
                  "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" className="text-end">
                      Amount (in Rs)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">Amount requested</td>
                    <td className="text-end">{parseInt(feesData?.amount)}</td>
                  </tr>

                  <tr>
                    <td scope="row">Gateway fees</td>
                    <td className="text-end">{feesData?.gatway_fee}</td>
                  </tr>

                  <tr>
                    <td scope="row" className="fw-bold fs-6">
                      Final Amt.
                    </td>
                    <td className="text-end fw-bold fs-6">
                      {feesData?.finalamount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          <div className={`d-flex gap-4 ${feesData ? "mt-5" : "mt-3"}`}>
            <button
              className="btn btn-danger w-100 py-2"
              disabled={loading}
              onClick={() => navigate("/payment", { replace: true })}
            >
              Cancel
            </button>
            <button
              className="btn btn-success w-100 py-2"
              disabled={loading || feesData === null}
              onClick={withdrawal}
            >
              {loading ? "Processing..." : "Withdraw"}
            </button>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default Withdraw;
