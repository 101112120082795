function TermsAndCondition() {
  return (
    <>
      <section>
        <h4>1. Usage of Izido Quiz</h4>
        <p>
          Any person ("User") accessing the Izido Quiz Application (referred to
          as "the App") for participating in quizzes and contests ("Contests")
          shall be bound by these Terms and Conditions, and all other rules,
          regulations, and terms of use referred to herein or provided by Izido
          Quiz in relation to any Izido Quiz Services. Izido Quiz shall be
          entitled to modify these Terms and Conditions, rules, regulations, and
          terms of use referred to herein or provided by Izido Quiz in relation
          to any Izido Quiz Services, at any time, by posting the same on the
          Izido Quiz Application. Use of the Izido Quiz Application constitutes
          the User's acceptance of such Terms and Conditions, rules,
          regulations, and terms of use referred to herein or provided by Izido
          Quiz in relation to any Izido Quiz Services, as may be amended from
          time to time. Izido Quiz may, at its sole discretion, also notify the
          User of any change or modification in these Terms and Conditions,
          rules, regulations, and terms of use referred to herein or provided by
          Izido Quiz, by way of sending an email to the User's registered email
          address or posting notifications in the User accounts. The User may
          then exercise the options provided in such an email or notification to
          indicate non-acceptance of the modified Terms and Conditions, rules,
          regulations, and terms of use referred to herein or provided by Izido
          Quiz. If such options are not exercised by the User within the time
          frame prescribed in the email or notification, the User will be deemed
          to have accepted the modified Terms and Conditions, rules,
          regulations, and terms of use referred to herein or provided by Izido
          Quiz. Certain Izido Quiz Services being provided on the Izido Quiz
          Application may be subject to additional rules and regulations set
          down in that respect. To the extent that these Terms and Conditions
          are inconsistent with the additional rules and regulations set down,
          the additional rules and regulations shall prevail. Izido Quiz may, at
          its sole and absolute discretion: Restrict, suspend, or terminate any
          User's access to all or any part of the Izido Quiz Services; Change,
          suspend, or discontinue all or any part of the Izido Quiz Services;
          Reject, move, or remove any material that may be submitted by a User;
          Move or remove any content that is available on the Izido Quiz
          Application; Deactivate or delete a User's account and all related
          information and files on the account; Establish general practices and
          limits concerning the use of the Izido Quiz Application; Offer
          discounts to its users in a form it deems fit ("Prize Bonuses"). All
          such discounts shall be credited in a separate account called the
          Prize Bonus Account. In the event any User breaches, or Izido Quiz
          reasonably believes that such User has breached these Terms and
          Conditions, or has illegally or improperly used the Izido Quiz
          Application or the Izido Quiz Services, Izido Quiz may, at its sole
          and absolute discretion, and without any notice to the User, restrict,
          suspend or terminate such User's access to all or any part of Izido
          Quiz Contests, the Izido Quiz Application, or any other Izido Quiz
          offerings, deactivate or delete the User's account and all related
          information on the account, delete any content posted by the User on
          an Izido Quiz platform, and further, take technical and legal steps as
          it deems necessary. If Izido Quiz charges its Users a platform fee in
          respect of any Izido Quiz Services, Izido Quiz shall, without delay,
          repay such platform fee in the event of suspension or removal of the
          User's account or Izido Quiz Services on account of any negligence or
          deficiency on the part of Izido Quiz, but not if such suspension or
          removal is effected due to: Any breach or inadequate performance by
          the User of any of these Terms and Conditions; or Any circumstances
          beyond the reasonable control of Izido Quiz. Users consent to
          receiving communications such as announcements, administrative
          messages, and advertisements from Izido Quiz or any of its partners,
          licensors, or associates. In the event of any conflict between these
          Terms and Conditions and the terms and conditions governing any other
          mobile application or website operated by Izido Quiz, these Terms and
          Conditions shall prevail in respect of the Izido Quiz Application.{" "}
        </p>
      </section>
      <section>
        <h4>2. Intellectual Property </h4>
        <p>
          The Izido Quiz Application includes a combination of content created
          by Izido, its partners, affiliates, licensors, and associates. The
          intellectual property rights ("Intellectual Property Rights") in all
          software underlying the Izido Quiz Application and material published
          on the Izido Quiz Application, including (but not limited to) quizzes,
          contests, software, advertisements, written content, photographs,
          graphics, images, illustrations, marks, logos, audio or video
          clippings, and animations, are owned by Izido, its partners,
          licensors, and/or associates. Users may not modify, publish, transmit,
          participate in the transfer or sale of, reproduce, create derivative
          works of, distribute, publicly perform, publicly display, or in any
          way exploit any of the materials or content on the Izido Quiz
          Application either in whole or in part without express written license
          from Izido. Users may request permission to use any Izido content by
          writing to the Izido Helpdesk. All names, logos, marks, labels,
          trademarks, copyrights, or intellectual and proprietary rights on the
          Izido Quiz Application belonging to any person, entity, or third party
          are recognized as proprietary to the respective owners and any claims,
          controversy, or issues against these names, logos, marks, labels,
          trademarks, copyrights, or intellectual and proprietary rights must be
          directly addressed to the respective parties under notice to Izido.
        </p>
      </section>
      <section>
        <h4>3. Privacy Policy</h4>
      </section>
      <section>
        <h4>4. User Conduct</h4>
        <p>
          Users of Izido Quiz agree to abide by these Terms and Conditions and
          all other rules, regulations, and terms of use of the Izido Quiz
          Application. In the event a User does not abide by these Terms and
          Conditions and all other rules, regulations, and terms of use, Izido
          Quiz may, at its sole and absolute discretion, take necessary remedial
          action, including but not limited to: Restricting, suspending, or
          terminating any User's access to all or any part of Izido Quiz
          Services. Deactivating or deleting a User's account and all related
          information and files on the account. Any amount remaining unused in
          the User's Unutilised Account or Winnings Account (subject to
          deduction of applicable TDS) on the date of deactivation or deletion
          shall be transferred to the User's bank account on record with Izido
          Quiz, subject to a processing fee (if any) applicable on such
          transfers as set out herein. Where a User possesses a common User
          account across multiple websites and mobile applications operated by
          Izido Quiz, the deletion or deactivation of such User account will
          invite the consequences set out in the privacy policy and terms and
          conditions of use of the respective website and mobile application.
          Refraining from awarding any prize(s) to such User. Users agree to
          provide true, accurate, current, and complete information at the time
          of registration and at all other times (as required by Izido Quiz).
          Users further agree to update and keep updated their registration
          information. Users agree and acknowledge that the address-related
          details provided, if any, by the User shall be treated as the "Address
          on record" of the User for the purposes of Goods and Service Tax Act.
          In the event of a change in such details, the User shall promptly
          update his/her profile on the Izido Quiz Application or write to Izido
          Quiz at helpdesk. A User shall not register or operate more than one
          User account with Izido Quiz. Users agree to ensure that they can
          receive all communication from Izido Quiz by marking e-mails or
          sending SMSs from Izido Quiz as part of their "safe senders" list.
          Izido Quiz shall not be held liable if any e-mail/SMS remains unread
          by a User as a result of such e-mail getting delivered to the User's
          junk or spam folder. Users are responsible for maintaining the
          confidentiality of their accounts and passwords. Users agree to
          immediately notify Izido Quiz of any unauthorized use of their
          accounts or any other breach of security. Users agree to exit/log-out
          of their accounts at the end of each session. Izido Quiz shall not be
          responsible for any loss or damage that may result if the User fails
          to comply with these requirements. Users agree not to use cheats,
          exploits, automation, software, bots, hacks, or any unauthorized
          third-party software designed to modify or interfere with Izido Quiz
          Services and/or Izido Quiz experience or assist in such activity.
          Users agree not to copy, modify, rent, lease, loan, sell, assign,
          distribute, reverse engineer, grant a security interest in, or
          otherwise transfer any right to the technology or software underlying
          the Izido Quiz Application or Izido Quiz Services. Users agree that
          without Izido Quiz's express written consent, they shall not modify or
          cause to be modified any files or software that are part of Izido Quiz
          Services. Users agree not to disrupt, overburden, or aid or assist in
          the disruption or overburdening of (a) any computer or server used to
          offer or support Izido Quiz or the Izido Quiz Services (each a
          "Server"); or (2) the enjoyment of Izido Quiz Services by any other
          User or person. Users agree not to institute, assist, or become
          involved in any type of attack, including without limitation to
          distribution of a virus, denial of service, or other attempts to
          disrupt Izido Quiz Services or any other person's use or enjoyment of
          Izido Quiz Services. Users shall not attempt to gain unauthorized
          access to the User accounts, servers, or networks connected to Izido
          Quiz Services by any means other than the User interface provided by
          Izido Quiz, including but not limited to, by circumventing or
          modifying, attempting to circumvent or modify, or encouraging or
          assisting any other person to circumvent or modify, any security,
          technology, device, or software that underlies or is part of Izido
          Quiz Services. Without limiting the foregoing, Users agree not to use
          the Izido Quiz Application for any of the following: To engage in any
          obscene, offensive, indecent, racial, communal, anti-national,
          objectionable, defamatory, or abusive action or communication. To
          harass, stalk, threaten, or otherwise violate any legal rights of
          other individuals. To disseminate any inappropriate, profane,
          defamatory, infringing, obscene, indecent, or unlawful content. To
          disseminate files that contain viruses, corrupted files, or any other
          similar software or programs that may damage or adversely affect the
          operation of another person's computer, Izido Quiz Application, any
          software, hardware, or telecommunications equipment. To download any
          file, recompile, or disassemble or otherwise affect our products that
          you know or reasonably should know cannot be legally obtained in such
          a manner. To falsify or delete any author attributions, legal or other
          proper notices or proprietary designations or labels of the origin or
          the source of software or other material. To restrict or inhibit any
          other User from using and enjoying any public area within our sites.
          To collect or store personal information about other Users. To
          interfere with or disrupt Izido Quiz Application, servers, or
          networks. To impersonate any person or entity, including, but not
          limited to, a representative of Izido Quiz, or falsely state or
          otherwise misrepresent User's affiliation with a person or entity. To
          forge headers or manipulate identifiers or other data in order to
          disguise the origin of any content transmitted through the Izido Quiz
          Application or to manipulate User's presence on the Izido Quiz
          Application. To take any action that imposes an unreasonably or
          disproportionately large load on our infrastructure. To engage in any
          illegal activities. To engage in any action that threatens the unity,
          integrity, defense, security, or sovereignty of India, friendly
          relations with foreign States, or public order, or causes incitement
          to the commission of any cognizable offense or prevents investigation
          of any offense or is insulting to other nations. If a User chooses a
          username that, in Izido Quiz's considered opinion, is obscene,
          indecent, abusive, or that might subject Izido Quiz to public
          disparagement or scorn, or a name which is an official
          team/league/franchise name and/or the name of any sporting
          personality, as the case may be, Izido Quiz reserves the right,
          without prior notice to the User, to restrict usage of such names,
          which in Izido Quiz’s opinion fall within any of the said categories
          and/or change such username and intimate the User or delete such
          username from the Izido Quiz Application, deny such User access to the
          Izido Quiz Application, or any combination of these options.
          Unauthorized access to the Izido Quiz Application is a breach of these
          Terms and Conditions and a violation of the law. Users agree not to
          access the Izido Quiz Application by any means other than through the
          interface that is provided by Izido Quiz for use in accessing the
          Izido Quiz Application. Users agree not to use any automated means,
          including, without limitation, agents, robots, scripts, or spiders, to
          access, monitor, or copy any part of our sites, except those automated
          means that we have approved in advance and in writing. Use of the
          Izido Quiz Application is subject to existing laws and legal
          processes. Nothing contained in these Terms and Conditions shall limit
          Izido Quiz's right to comply with governmental, court, and
          law-enforcement requests or requirements relating to Users' use of the
          Izido Quiz Application. Users may reach out to Izido Quiz through the
          Helpdesk if the User has any concerns with regard to a match and/or
          Contest within Forty Eight (48) hours of winner declaration for the
          concerned Contest. Persons below the age of eighteen (18) years are
          not allowed to participate in any of the paid contests, games (by
          whatever name called) on the Izido Quiz Application. The Users will
          have to disclose their real age at the time of getting access into the
          Izido Quiz Application. User hereby confirms that he/she is
          participating in a Contest in a personal capacity and not in the
          course of business and/or profession.
        </p>
      </section>

      <section>
        <h4>5. Conditions of Participation</h4>
        <p>
          By entering a Quiz, User agrees to be bound by these Terms and
          Conditions and the decisions of Izido Quiz. Subject to the terms and
          conditions stipulated herein below, Izido Quiz, at its sole
          discretion, may disqualify any User from a Quiz, refuse to award
          benefits or prizes, and require the return of any prizes if the User
          engages in unfair conduct, which Izido Quiz deems to be improper,
          unfair, or otherwise adverse to the operation of the Quiz or is in any
          way detrimental to other Users, which includes, but is not limited to:
          Falsifying one's own personal information (including, but not limited
          to, name, email address, bank account details, and/or any other
          information or documentation as may be requested by Izido Quiz to
          enter a Quiz and/or claim a prize/winning). Engaging in any type of
          financial fraud or misrepresentation, including unauthorized use of
          credit/debit instruments, payment wallet accounts, etc., to enter a
          Quiz or claim a prize. It is expressly clarified that the onus to
          prove otherwise shall solely lie on the User. Colluding with any other
          User(s) or engaging in any type of syndicate play. Any violation of
          Quiz rules, these Terms and Conditions, or the Terms of Use, as
          specified. Accumulating points or prizes through unauthorized methods
          such as automated bots or other automated means. Using automated means
          (including but not limited to harvesting bots, robots, parser,
          spiders, or screen scrapers) to obtain, collect, or access any
          information on the Izido Quiz Application or of any User for any
          purpose. Any type of Cash Bonus misuse; tampering with the
          administration of a Quiz or trying to in any way tamper with the
          computer programs or any security measure associated with a Quiz.
          Obtaining other Users’ information without their express consent
          and/or knowledge and/or spamming other Users (Spamming may include but
          shall not be limited to sending unsolicited emails to Users, sending
          bulk emails to Users, sending unwarranted email content either to
          selected Users or in bulk). Abusing the Izido Quiz Application in any
          way ('unparliamentary language, slangs, or disrespectful words' are
          some of the examples of Abuse). It is clarified that in case a User is
          found to be in violation of this policy, Izido Quiz reserves its right
          to initiate appropriate civil/criminal remedies as it may be advised
          other than forfeiture and/or recovery of prize money if any.
        </p>
      </section>

      <section>
        <h4>6. Registration for a Contest</h4>
        <p>
          In order to register for the Quiz, Participants are required to
          accurately provide the following information: Full Name Team Name(s)
          E-mail address Password State of Residence Gender Date of birth
          Participants are also required to confirm that they have read and
          shall abide by these Terms and Conditions. In the event a Participant
          indicates, while entering an address, that he/she is a resident of
          either Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim, or Telangana,
          such Participant will not be permitted to proceed to sign up for any
          match in the paid version of the Quiz as described below. Once the
          Participants have entered the above information and clicked on the
          "register" tab, and such Participants are above the age of 18 years,
          they are sent an email confirming their registration and containing
          their login information.
        </p>
      </section>
      <section>
        <h4>7. Contest(s), Participation and Prizes</h4>
        <p>
          As part of its services, Izido Quiz offers various quiz contests on
          its platform. Participants can join these contests by paying an entry
          fee. By participating in a contest, participants agree to comply with
          the rules and laws set forth by Izido Quiz. To participate in a quiz
          contest, participants must accurately provide the required
          information, including their full name, email address, and date of
          birth. Participants must also confirm their agreement to abide by the
          contest rules and laws. Participants can create their entries for the
          quiz contest, but they must adhere to the specified rules regarding
          the number of entries allowed per participant. Izido Quiz reserves the
          right to disqualify participants who violate these rules or engage in
          unfair conduct. The contest rules, along with any other terms and
          conditions specified by Izido Quiz, govern the quiz contests on the
          platform. Participants are expected to read and understand these rules
          before participating in any contest. Participants earn points or money
          based on their performance in the quiz contest. The participant(s)
          with the highest score or earnings at the end of the contest will be
          declared the winner(s). In case of a tie, the prize money will be
          divided equally among the tied participants. Prizes for the quiz
          contests are pre-designated and are disbursed from the prize pool.
          Participants must pay the entry fee to participate in the paid
          versions of the contests. Izido Quiz reserves the right to adjust the
          prize distribution or abandon a contest in certain circumstances
          beyond its control. Participants from certain regions, such as Andhra
          Pradesh, Assam, Nagaland, Odisha, Sikkim, or Telangana, may not be
          permitted to participate in the paid versions of the contests, as per
          the laws of those regions. Participants are responsible for
          maintaining the confidentiality of their accounts and passwords. Izido
          Quiz is not liable for any unauthorized use of participant accounts.
          Participants agree to comply with all applicable laws and regulations
          while participating in the quiz contests on Izido Quiz. Izido Quiz
          reserves the right to take appropriate action against participants who
          violate these rules or engage in unfair conduct.
        </p>
      </section>
      <section>
        <h4>8. Contest Formats</h4>
        <p>
          Entry Fee: Participants are required to pay an entry fee to join the
          quiz contest. The entry fee amount is fixed and is specified for each
          contest. Question Format: The quiz contest consists of multiple-choice
          questions. Participants are presented with a series of questions, each
          with multiple answer options. Time Limit: Participants have a limited
          amount of time to answer each question. The time limit is specified
          for each question and varies depending on the complexity of the
          question. Scoring: Participants earn points for each correct answer.
          The number of points awarded for each question is specified in the
          contest rules. Incorrect answers do not result in any points being
          deducted. Elimination: Participants who answer a question incorrectly
          are eliminated from the contest. The contest continues until only one
          participant remains. Cashout System: The prize pool is distributed
          among the remaining participants after each question. Participants can
          choose to cash out their winnings at any point during the contest, or
          they can continue playing to try and win a larger share of the prize
          pool. Final Prize: The participant who remains in the contest until
          the end and answers all questions correctly wins the final prize,
          which is the remaining amount in the prize pool after all previous
          cashouts. Adherence to Rules: Participants must adhere to the rules
          and regulations of the contest. Failure to do so may result in
          disqualification from the contest and forfeiture of any winnings. Fair
          Play: Izido Quiz reserves the right to monitor the contest for fair
          play and to take action against participants who engage in unfair
          conduct or violate the contest rules. Dispute Resolution: In case of
          any disputes regarding the contest, Izido Quiz's decision shall be
          final and binding. Changes to Contest Format: Izido Quiz reserves the
          right to change the format of the contest, including the entry fee,
          prize distribution, and rules, at any time without prior notice.
        </p>
      </section>
      <section>
        <h4>9. Legality of Game of Skill for Izido Quiz:</h4>
        <p>
          Games of skill are legal, as they are excluded from the ambit of
          Indian gambling legislations including, the Public Gambling Act of
          1867. The Indian Supreme Court has held that a game in which success
          depends predominantly upon the superior knowledge, training,
          attention, experience, and adroitness of the player shall be
          classified as a game of skill. The Izido Quiz is designed as a game of
          skill, where success depends primarily on the participant's superior
          knowledge of the quiz topics, attention, and dedication towards the
          quiz, and adroitness in answering the questions. Participants are
          required to demonstrate their understanding of the quiz topics and
          make strategic decisions to maximize their points, making it a game of
          skill. By participating in Izido Quiz, each participant acknowledges
          and agrees that they are participating in a game of skill.
        </p>
      </section>
      <section>
        <h4>10. Eligibilty</h4>
        <p>
          The quiz is open only to individuals above the age of 18 years. The
          quiz is open only to individuals currently residing in India. Izido
          Quiz may, in accordance with the laws prevailing in certain Indian
          states, bar individuals residing in those states from participating.
          Currently, individuals residing in the Indian states of Andhra
          Pradesh, Assam, Nagaland, Odisha, Sikkim, or Telangana may not
          participate in the paid version of the quiz as the laws of these
          states are unclear/bar persons from participating in games of skill
          where participants are required to pay to enter. Participants must
          have a valid email address to participate. Izido Quiz may bar a person
          from participation and/or withdrawing winning amounts if such person
          is found to have insider knowledge of participating teams, questions,
          or any other relevant information related to the quiz. Only those
          participants who have successfully registered on the Izido Quiz
          platform and registered prior to each quiz in accordance with the
          procedure outlined shall be eligible to participate and win prizes.
        </p>
      </section>
      <section>
        <h4>11. Payment Terms</h4>
        <p>
          Participants authorize Izido Quiz to appoint a third-party
          Trustee/Escrow Agent to manage participants' funds on their behalf.
          The payment made by participants to enter the quiz contest includes
          the platform fee for accessing Izido Quiz and the participant's
          contribution towards the prize money pool. All amounts collected from
          participants are held in a separate non-interest-earning bank account
          operated by a third party appointed by Izido Quiz. These funds are
          used for payouts to participants, Izido Quiz (for platform fees), and
          the government (for TDS on winnings). Izido Quiz reserves the right to
          charge a platform fee, which will be debited from the participant's
          account balance. GST paid on each paid contest joined will be
          reflected in the user's invoice. Participants may contribute a
          pre-specified amount towards the prize money pool of a contest, which
          will be passed on to the winners after the completion of the contest.
          Participants have two categories of accounts: unutilized account and
          winnings account. Winnings from a contest are credited to the winnings
          account, while amounts remitted through the payment gateway are
          credited to the unutilized account. Each time a participant joins a
          quiz, the pre-designated amount is debited from the unutilized account
          first, and any remaining amount is then debited from the winnings
          account. Any unutilized amount in the unutilized account must be used
          within 335 days of credit. After this period, Izido Quiz reserves the
          right to forfeit the unutilized amount. Withdrawal of winnings can be
          made by requesting Izido Quiz to transfer the amount to the
          participant's bank account. No processing fee is charged for this
          transfer. Izido Quiz may issue Cash Bonus to participants for
          promotional activities, which can be used for setting off against the
          contribution to the prize pool. Cash Bonus is non-transferable and
          cannot be withdrawn. All Cash Bonus credited to a participant's
          account is valid for 14 days from the date of credit. Transactions on
          Izido Quiz may take up to 24 hours to be processed, and participants
          agree not to raise any complaints against Izido Quiz for any delays.
          Once a transaction is confirmed, no cancellation is permissible. In
          exceptional circumstances, Izido Quiz may refund the amount after
          deducting applicable charges and taxes.
        </p>
      </section>
      <section>
        <h4>
          12. Selection and Verification of Winners and Conditions relating to
          the Prizes for Izido Quiz:
        </h4>
        <p>
          Selection of Winners: Winners in Izido Quiz will be decided based on
          their performance in answering quiz questions correctly and
          accumulating the highest score. Participants who violate rules or
          engage in unfair conduct will be disqualified. In case of a tie, the
          prize will be equally divided among the winning participants. Izido
          Quiz reserves the right to disqualify winners who do not abide by the
          Terms and Conditions. Contacting Winners: Winners will be contacted
          via email for further verification and prize collection. Winners must
          provide photocopies of their PAN card, government-issued residence
          proof, and bank account details for verification. Failure to provide
          the required documents within the specified time period will result in
          disqualification. Verification Process: Only winners who complete the
          verification process and provide the required documents within the
          specified time limit will be eligible to receive their prizes. Izido
          Quiz may disqualify winners if any submitted document or information
          is found to be incorrect, misleading, false, or incomplete. Taxes
          Payable: All prizes will be subject to deduction of tax (TDS) as per
          the Income Tax Act, 1961. The TDS rate for winnings over Rs. 10,000 is
          30%. TDS will be deducted on each withdrawal request placed by the
          user. Winners are responsible for any other applicable taxes,
          including income tax and gift tax. Miscellaneous: Izido Quiz's
          decision regarding prize awards is final and binding. Participants
          must confirm that they are not residents of Andhra Pradesh, Assam,
          Nagaland, Odisha, Sikkim, or Telangana to play the paid formats of the
          quiz. Residents of these states are disqualified. Participants under
          the age of eighteen (18) are not eligible to play the paid formats of
          the quiz. Prizes are non-transferable, non-refundable, and cannot be
          exchanged for cash or kind. Winners are responsible for any shipping,
          courier, or delivery costs associated with receiving their prizes.
          Please note that these terms and conditions are subject to change, and
          participants should review them regularly.
        </p>
      </section>
      <section>
        <h4>Publicity for Izido Quiz:</h4>
        <p>
          Acceptance of a prize by the Winner constitutes permission for Izido
          Quiz, and its affiliates to use the Winner's name, likeness, voice,
          and comments for advertising and promotional purposes in any media
          worldwide for purposes of advertising and trade without any further
          permissions or consents and/or additional compensation whatsoever. The
          Winners further undertake that they will be available for promotional
          purposes as planned and desired by Izido Quiz without any charge. The
          exact dates remain at the sole discretion of Izido Quiz. Promotional
          activities may include but not be limited to press events, internal
          meetings, and ceremonies/functions.{" "}
        </p>
      </section>
      <section>
        <h4>14. General Conditions</h4>
        <p>
          If it comes to the notice of Izido that any governmental, statutory,
          or regulatory compliances or approvals are required for conducting any
          Contest(s), or if it comes to the notice of Izido that the conduct of
          any such Contest(s) is prohibited, then Izido shall withdraw and/or
          cancel such Contest(s) without prior notice to any Participants or
          winners of any Contest(s). Users agree not to make any claim in
          respect of such cancellation or withdrawal of the Contest or Contest
          it in any manner. Employees of Izido shall not be eligible to
          participate in any Public Contest(s).
        </p>
      </section>
      <section>
        <h4>15. Dispute and Dispute Resolution</h4>
        <p>
          The courts of competent jurisdiction at Mumbai shall have exclusive
          jurisdiction to determine any and all disputes arising out of, or in
          connection with, the Izido Services provided by Izido (including the
          Contest(s)), the construction, validity, interpretation, and
          enforceability of these Terms and Conditions, or the rights and
          obligations of the User(s) (including Participants) or Izido, as well
          as the exclusive jurisdiction to grant interim or preliminary relief
          in case of any dispute referred to arbitration as given below. All
          such issues and questions shall be governed and construed in
          accordance with the laws of the Republic of India. In the event of any
          legal dispute (which may be a legal issue or question) that may arise,
          the party raising the dispute shall provide a written notification
          ("Notification") to the other party. On receipt of Notification, the
          parties shall first try to resolve the dispute through discussions. In
          the event that the parties are unable to resolve the dispute within
          fifteen (15) days of receipt of Notification, the dispute shall be
          settled by arbitration. The place of arbitration shall be Mumbai,
          India. All arbitration proceedings shall be conducted in English and
          in accordance with the provisions of the Arbitration and Conciliation
          Act, 1996, as amended from time to time. The arbitration award will be
          final and binding on the Parties, and each Party will bear its own
          costs of arbitration and equally share the fees of the arbitrator
          unless the arbitral tribunal decides otherwise. The arbitrator shall
          be entitled to pass interim orders and awards, including the orders
          for specific performance, and such orders would be enforceable in
          competent courts. The arbitrator shall give a reasoned award. Nothing
          contained in these Terms and Conditions shall prevent Izido from
          seeking and obtaining interim or permanent equitable or injunctive
          relief, or any other relief available to safeguard Izido's interest
          prior to, during, or following the filing of arbitration proceedings
          or pending the execution of a decision or award in connection with any
          arbitration proceedings from any court having jurisdiction to grant
          the same. The pursuit of equitable or injunctive relief shall not
          constitute a waiver on the part of Izido to pursue any remedy for
          monetary damages through the arbitration described herein.{" "}
        </p>
      </section>
      <section>
        <h4>16. Release and Limitations of Liability</h4>
        <p>
          Users shall access the Izido Services provided on the Izido Quiz
          platform voluntarily and at their own risk. Izido shall, under no
          circumstances, be held responsible or liable for any loss or damage
          sustained (including but not limited to any accident, injury, death,
          loss of property) by Users or any other person or entity during the
          course of access to the Izido Services (including participation in the
          Contests) or as a result of acceptance of any prize. By entering the
          Contests and accessing the Izido Services provided therein, Users
          hereby release and agree to indemnify Izido, and/or any of its
          directors, employees, partners, associates, and licensors, from and
          against all liability, cost, loss, or expense arising out of their
          access to the Izido Services, including (but not limited to) personal
          injury and damage to property, whether direct, indirect,
          consequential, foreseeable, due to some negligent act or omission on
          their part, or otherwise. Izido accepts no liability, whether jointly
          or severally, for any errors or omissions, whether on behalf of itself
          or third parties, in relation to the prizes. Users shall be solely
          responsible for any consequences that may arise due to their access to
          Izido Services by conducting an illegal act or due to non-conformity
          with these Terms and Conditions and other rules and regulations in
          relation to Izido Services, including the provision of incorrect
          addresses or other personal details. Users also undertake to indemnify
          Izido and their respective officers, directors, employees, and agents
          in the event of such an occurrence (including, without limitation, the
          cost of attorney fees, legal charges, etc.) on a full indemnity basis
          for any loss/damage suffered by Izido due to such acts on the part of
          the Users. Users shall indemnify, defend, and hold Izido harmless from
          any third-party/entity/organization claims arising from or related to
          such User's engagement with Izido or participation in any Contest. In
          no event shall Izido be liable to any User for acts or omissions
          arising out of or related to User's engagement with Izido or his/her
          participation in any Contest(s). In consideration of Izido allowing
          Users to access the Izido Services, to the maximum extent permitted by
          law, the Users waive and release each and every right or claim, all
          actions, causes of actions (present or future) each of them has or may
          have against Izido, its respective agents, directors, officers,
          business associates, group companies, sponsors, employees, or
          representatives for all and any injuries, accidents, or mishaps
          (whether known or unknown) or (whether anticipated or unanticipated)
          arising out of the provision of Izido Services or related to the
          Contests or the prizes of the Contests.
        </p>
      </section>
      <section>
        <h4>17. Disclaimers</h4>
        <p>
          To the extent permitted under law, neither Izido nor its
          parent/holding company, subsidiaries, affiliates, directors, officers,
          professional advisors, employees shall be responsible for the
          deletion, the failure to store, the mis-delivery, or the untimely
          delivery of any information or material. To the extent permitted under
          law, Izido shall not be responsible for any harm resulting from
          downloading or accessing any information or material, the quality of
          servers, games, products, Izido services or platforms, cancellation of
          competition and prizes. Izido disclaims any responsibility for, and if
          a User pays for access to one of Izido Services the User will not be
          entitled to a refund as a result of, any inaccessibility that is
          caused by Izido's maintenance on the servers or the technology that
          underlies our sites, failures of Izido's service providers (including
          telecommunications, hosting, and power providers), computer viruses,
          natural disasters, or other destruction or damage of our facilities,
          acts of nature, war, civil disturbance, or any other cause beyond our
          reasonable control. In addition, Izido does not provide any warranty
          as to the content on the Izido Quiz platform. Izido Quiz platform
          content is distributed on an "as is, as available" basis. Any material
          accessed, downloaded, or otherwise obtained through the Izido Quiz
          platform is done at the User's discretion, competence, acceptance, and
          risk, and the User will be solely responsible for any potential damage
          to the User's computer system or loss of data that results from the
          User's download of any such material. Izido shall make best endeavors
          to ensure that the Izido Quiz platform is error-free and secure,
          however, neither Izido nor any of its partners, licensors, or
          associates makes any warranty that: the Izido Quiz platform will meet
          Users' requirements, Izido Quiz platform will be uninterrupted,
          timely, secure, or error-free the results that may be obtained from
          the use of Izido Quiz platform will be accurate or reliable; and the
          quality of any products, Izido Services, information, or other
          material that Users purchase or obtain through Izido Quiz platform
          will meet Users' expectations. In case Izido discovers any error,
          including any error in the determination of Winners or in the transfer
          of amounts to a User's account, Izido reserves the right (exercisable
          at its discretion) to rectify the error in such manner as it deems
          fit, including through a set-off of the erroneous payment from amounts
          due to the User or deduction from the User's account of the amount of
          erroneous payment. In case of exercise of remedies in accordance with
          this clause, Izido agrees to notify the User of the error and of the
          exercise of the remedy(ies) to rectify the same. To the extent
          permitted under law, neither Izido nor its partners, licensors, or
          associates shall be liable for any direct, indirect, incidental,
          special, or consequential damages arising out of the use of or
          inability to use the Izido Quiz platform or other offerings, even if
          we have been advised of the possibility of such damages. Any Izido
          Services, events, or Contests being hosted or provided, or intended to
          be hosted on the Izido Quiz platform and requiring specific permission
          or authority from any statutory authority or any state or the central
          government, or the board of directors shall be deemed canceled or
          terminated if such permission or authority is either not obtained or
          denied either before or after the availability of the relevant Izido
          Services, events, or Contests are hosted or provided. To the extent
          permitted under law, in the event of suspension or closure of any
          Services, events, or Contests, Users (including Participants) shall
          not be entitled to make any demands, claims, on any nature whatsoever.
          Izido does not extend any warranty with respect to the PhonePe
          platform and does not assume any responsibility with respect to the
          operation, functioning, performance, or user experience with the
          PhonePe platform.
        </p>
      </section>
      <section>
        <h4>18. Miscellaneous</h4>
        <p>
          {" "}
          Izido may be required under certain legislations to notify User(s) of
          certain events. User(s) hereby acknowledge and consent that such
          notices will be effective upon Izido posting them on the Izido Quiz
          platform or delivering them to the User through the email address
          provided by the User at the time of registration. User(s) may update
          their email address by logging into their account on the Izido Quiz
          platform. If they do not provide Izido with accurate information,
          Izido cannot be held liable for failure to notify the User. Izido
          shall not be liable for any delay or failure to perform resulting from
          causes outside its reasonable control, including but not limited to
          any failure to perform due to unforeseen circumstances or cause beyond
          Izido's control such as acts of God, war, terrorism, riots, epidemic,
          pandemic, outage, embargoes, acts of civil or military authorities,
          fire, floods, accidents, network infrastructure failures, strikes, or
          shortages of transportation facilities, fuel, energy, labor, or
          materials or any cancellation of any event to which a Contest relates.
          In such circumstances, Izido shall also be entitled to cancel any
          related Contest(s) and to process an appropriate refund for all
          Participants. Izido's failure to exercise or enforce any right or
          provision of these Terms and Conditions shall not constitute a waiver
          of such right or provision. Users agree that regardless of any statute
          or law to the contrary, any claim or cause of action arising out of or
          related to use of the Izido Quiz platform or these Terms and
          Conditions must be filed within thirty (30) days of such claim or
          cause of action arising or be forever barred. These Terms and
          Conditions, including all terms, conditions, and policies that are
          incorporated herein by reference, constitute the entire agreement
          between the User(s) and Izido and govern your use of the Izido Quiz
          platform, superseding any prior agreements that any User may have with
          Izido. If any part of these Terms and Conditions is determined to be
          indefinite, invalid, or otherwise unenforceable, the rest of these
          Terms and Conditions shall continue in full force. Izido reserves the
          right to moderate, restrict, or ban the use of the Izido Quiz
          platform, specifically to any User, or generally, in accordance with
          Izido's policy/policies from time to time, at its sole and absolute
          discretion and without any notice. Izido may, at its sole and absolute
          discretion, permanently close or temporarily suspend any Izido
          Services (including any Contest(s)). Izido may from time to time
          conduct/organize promotions/offers on the Izido Quiz platform. Any two
          or more promotions cannot be clubbed together with any other
          promotions that are running simultaneously on the Izido Quiz platform.
          Also, promotions/offers cannot be clubbed with Cash Bonus available
          with any user.
        </p>
      </section>
      <section>
        <h4>19. Grievance Redressal Mechanism</h4>
        <p>
          If you have any complaints or grievances pertaining to your access to
          Izido Quiz or otherwise pertaining to Izido Quiz, please share the
          same with us by writing to: Inquiry@izido.io In your complaint or
          grievance, please include the following information: Your name and
          contact details: name, address, contact number, and email address;
          Your relation to the subject matter of the complaint, i.e. complainant
          or person acting on behalf of an affected person; The name and age of
          the person aggrieved or affected by the subject matter of the
          complaint, in case you are acting on behalf of such person and a
          statement, under penalty of perjury, that you are authorized to act on
          behalf of such person and to provide such person's personal
          information to us in relation to the complaint/grievance; Description
          of the complaint or grievance with clear identification of the issue
          in relation to which such complaint or grievance is made; A statement
          that you believe, in good faith, that the grievance to be legitimate
          under these Terms and Conditions A statement that the information
          provided in the complaint or grievance is accurate. Izido respects the
          Intellectual Property Rights of others. All names, logos, marks,
          labels, trademarks, copyrights, or intellectual and proprietary rights
          on the Izido Quiz platform belonging to any person (including User),
          entity, or third party are recognized as proprietary to the respective
          owners. You are requested to send us a written notice/intimation if
          you notice any act of infringement on the Izido Quiz platform, which
          must include the following information: A clear identification of the
          copyrighted work allegedly infringed; A clear identification of the
          allegedly infringing material on the Izido Quiz platform; Your contact
          details: name, address, e-mail address, and phone number; A statement
          that you believe, in good faith, that the use of the copyrighted
          material allegedly infringed on the Izido Quiz platform is not
          authorized by your agent or the law; A statement that the information
          provided in the notice is accurate, and under penalty of perjury, that
          the signatory is authorized to act on behalf of the owner of an
          exclusive copyright right that is allegedly infringed; Your signature
          or a signature of your authorized agent. The aforesaid notices can be
          sent to the Company by email at grievanceofficer@izidoquiz.com On
          receiving such complaint, grievance, or notice, the Company reserves
          the right to investigate and/or take such action as the Company may
          deem appropriate. The Company may reach out to you to seek further
          clarification or assistance with the investigation, or verify the
          statements made in your complaint, grievance, or notice, and you
          acknowledge that your timely assistance with the investigation would
          facilitate the redressal of the same. The name and title of the
          Grievance Redressal Officer is as follows: Name: bhavya sethi Email:
          inquiry@izido.io Address: The Grievance Officer identified above
          pursuant to the provisions of applicable laws including but not
          limited to the Information Technology Act, 2000, and the Consumer
          Protection Act, 2019, and the Rules enacted under those laws. The
          Company reserves the right to replace the Grievance Redressal Officer
          at its discretion through publication of the name and title of such
          replacement on the website, which replacement shall come into effect
          immediately upon publication.{" "}
        </p>
      </section>
    </>
  );
}

export default TermsAndCondition;
