import React, { useState } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "./Signup.css";
import FormVanilla from "../../Components/Form/FormVanilla";
import moment from "moment";

const Signup = () => {
  const [values, setValues] = useState({});

  function pulldata(data) {
    setValues(data);
  }
  const pass = values.password;
  const conf = values.confirmpassword;

  const nameValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === "") {
      return `${fieldName} is required`;
    }
    if (/[^a-zA-Z -]/.test(fieldValue)) {
      return "Invalid characters";
    }
    if (fieldValue.trim().length < 3) {
      return `${fieldName} needs to be at least three characters`;
    }
    return null;
  };

  const emailValidation = (email) => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return null;
    }
    if (email.trim() === "") {
      return "Email is required";
    }
    return "Please enter a valid email";
  };

  const passwordValidation = (password) => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,14}$/.test(
        password
      )
    ) {
      return null;
    }
    if (password.trim() == "") {
      return "Password is required";
    }
    // 	if (password.length < 5) {
    // 	   return "Your password must be at least 5 characters";
    // 	 }
    // 	 if (password.search(/[a-z]/i) < 0) {
    // 	  return "Your password must contain at least one letter";
    //   }
    //   if (password.search(/[0-9]/) < 0) {
    // 	 return "Your password must contain at least one digit";
    //   }

    return "Password must be 5-14 characters, with at least one uppercase, one lowercase, one number, and one special character from '@$!%*?&'.";
  };

  const confirmpass = (confirmpassword) => {
    if (confirmpassword.trim() == "") {
      return " Confirm Password is required";
    }
    if (pass != conf) {
      return "Password Doesn't match";
    }
  };

  // const confirmValidation = (confirmpassword,password,pass,conf) => {
  //   if(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,10}$/.test(
  //       confirmpassword
  //     )
  //   ){
  //     return null;
  //   }
  //   if (confirmpassword.trim() == "") {
  //      return "Confirm Password is required";
  //   }
  //   if( pass != conf ){
  //     return "Password Doesn't Match";
  //   }
  //   return "Please enter a valid confirm password"

  // };

  const genderValidation = (gender) => {
    if (gender.trim() == "") {
      return "Gender is required";
    }
  }

  const countryValidation = (country) => {
    if (country.trim() == "") {
      return "Country is required";
    }
  }

  const stateValidation = (state) => {
    if (state.trim() == "") {
      return "State is required";
    }
  }

  const dobValidation = (dob) => {
    const now = moment();
    if (now.diff(dob, 'year') < 18) {
      return `You should be more than ${18} years old to use this platform`
    }
    if (now.diff(dob, 'year') > 100) {
      return "Maximum age allowed is 100"
    }
    if (dob.trim() == "") {
      return "DOB is required";
    }
  }

  const validate = {
    firstName: (name) => nameValidation("First Name", name),
    email: emailValidation,
    dob: dobValidation,
    gender: genderValidation,
    password: passwordValidation,
    confirmpassword: confirmpass,
    country: countryValidation,
    state: stateValidation,
  };

  const initialValues = {
    email: "",
    firstName: "",
    country: "",
    state: "",
    password: "",
    confirmpassword: "",
    dob:"",
    gender:"",
  };

  return (
    <>
      <FormVanilla
        validate={validate}
        initialValues={initialValues}
        func={pulldata}
      />
    </>
  );
};

export default Signup;
