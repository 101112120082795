import React from "react";
import { Link } from "react-router-dom";

const Banner = ({src, adName}) => {
  return (
    <div>
    <aside className="responsive-banner second">
      <div className="container-envelope">
        <svg className="circle cirle-a" height="160" width="160">
          <circle cx="80" cy="80" r="80" />
        </svg>
        <svg className="circle cirle-b" height="60" width="60">
          <circle cx="30" cy="30" r="30" />
        </svg>
        <svg className="circle cirle-c" height="600" width="600">
          <circle cx="300" cy="300" r="300" />
        </svg>
        <svg className="circle cirle-d" height="60" width="60">
          <circle cx="30" cy="30" r="30" />
        </svg>
        <img src={src} />
            
        <div className="col-xs-12 mb-3">
          <p className="mb-2">{adName?.slice(0,10)} {adName?.length > 10 ? "..." : "" } </p>
          <Link
           to="/adslist"
            className="more-link"
          >
            Track brands
          </Link>
        </div>
      </div>
    </aside>
    </div>
  );
};

export default Banner;
