import { useNavigate } from 'react-router-dom';


export default function WinningMsg(props) {
    let winningcoin = props.winningcoin;
    let nextUrl = props.nextUrl;
    let status = props.status;
    let isCashout = props.isCashout;

    const navigate = useNavigate();


    const handleNavigate = (url) => {
        navigate(url, { replace : true })
    }

    return (
        <>
            <div className="inner-page-container">
                <div className="container-fluid">
                    <div className="position-fixed ads-winning-popup">
                        <div className="d-flex align-items-center justify-content-center vh-100">
                            <div className="winning-inner-text bg-white p-5">
                                {/* {status =='success' ?  */}
                                <><h3 className='fw-bold text-dark-orange mb-0'>{ Number(status?.totalcorrectans) == 0  ? "Oh No !" : "Congratulation !"}</h3>
                                <p className='fs-5'>You answered {status?.totalcorrectans} out of {status?.totalquestions} questions correct</p></>
                                { Number(status?.totalcorrectans) > 0 && <p className='fs-3'>You won <span className='me-4 d-inline-block'><i className="coin ps-1"></i></span>{(Math.round(winningcoin * 100) / 100).toFixed(2)}</p> }
                                
                                {/* // : <>
                                // <h3 className='fw-bold text-dark-orange mb-0'>Oh No !</h3>
                                // <p className='fs-3'>You have given wrong answer. You don't win any money. </p></>
                                // } */}
                                
                                <div className="winning-navigation text-end pt-3">  
                                {!isCashout
                                ? <>
                                <button type="button" className="btn btn-sm me-2 btn-gray" onClick={() => handleNavigate("/appuserhome")}>Home</button>
                                    <button type="button" className="btn btn-sm btn-orange" onClick={() => handleNavigate(nextUrl)}>Next</button>
                                </>
                                :<>
                                <button type="button" className="btn btn-sm btn-orange" onClick={() => handleNavigate("/appuserhome")}>Ok</button></>}                                  
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
