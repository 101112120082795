import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const VerifyModal = ({
  show,
  handleClose,
  handleClick,
  loading,
  verifyMsg,
}) => {
  const navigate = useNavigate();

  let bankingName =
    verifyMsg?.match(/(?<=\=)(.*?)(?=\*)/) || verifyMsg?.split("=")[1] || "";

  if (Array.isArray(bankingName) && bankingName.length >= 2) {
    bankingName = bankingName[1].trim();
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h1>Verification Failed</h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="m-0">
          Your profile name and banking name doesn't match. Banking Name:{" "}
          {bankingName}
        </p>
        <p>
          Do you want to update your profile name and save this payment method?
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            handleClose();
            navigate("/payment", { replace: true });
          }}
        >
          Cancel
        </Button>
        <Button variant="success" onClick={handleClick} disabled={loading}>
          {loading ? "Processing..." : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerifyModal;
