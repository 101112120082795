export const DotLoaders = ({ center = false }) => {
  return (
    <div className={`dot_loader ${center ? "flex-center" : ""} `}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

const SplashLoader = () => {
  return (
    <div className="splash_loader">
      <div className="bg-white px-3 py-4" style={{ borderRadius: "55%" }}>
        <img
          src="./assets/images/logo.png"
          alt="App-logo"
          style={{ width: "80px" }}
        />
      </div>
      <DotLoaders center={true} />
    </div>
  );
};

export default SplashLoader;
