import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link } from 'react-router-dom'

import ROUTER_PATHS from '../../Constants/routerPath'

const calculateDifference = (contestTime) => {
  let contestDateTime = moment(contestTime);
  // console.log(contestDateTime)
  let currentdate = new Date();
  // console.log(currentdate)
  let newdifference = contestDateTime - currentdate;
  //console.log(newdifference)
  return Math.floor(newdifference / 1000);
};

const NotificationHeader = ({contest}) => {
  const { contestTime, contestName } = contest;
  const [timer, setTimer] = useState(calculateDifference(contestTime));
  const id = useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    // when user applies for second contest and is early then the previous one so
    setTimer(calculateDifference(contestTime));
  }, [contestTime]);

  useEffect(() => {
    if (timer <= 0 ) {
      // navigate("/singlequizdetail", {state:{contestName: contestName, EntryAmount: contest.EntryAmount, contestTime: contestTime, contestid: contest.id }})
      clear();
    }
  }, [timer]);

  return (
    <Link to={ROUTER_PATHS.SINGLE_QUIZ_DETAIL} state={{ contestName: contestName, EntryAmount: contest.EntryAmount, contestTime: contestTime, contestid: contest.id }}>
    <div className="bg-black text-white text-center py-2">
     
      <strong className="">{contestName}</strong> :{" "}
      <span className="blink_me">
      {timer > 0
        ? `starts in ${timer} seconds`
        : "Is Live"}

      </span>  
    </div>
    </Link>
  );
};

export default NotificationHeader;