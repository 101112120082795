import React, { useEffect, useState } from "react";
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../../Components/BottomNav/BottomNav";
import { useNavigate } from "react-router-dom";
import { load } from "@cashfreepayments/cashfree-js";
// utils and helpers
import CONSTANTS from "../../Constants/global";
import api from "../../Service/apiService";
import toast from "react-hot-toast";
import Loader from "../../Components/Loader";

let cashfree;
var initializeSDK = async function () {
  cashfree = await load({
    mode: process.env.REACT_APP_CASHFREE_PG_MODE,
  });
};
initializeSDK();

const Deposit = () => {
  const [amount, setAmount] = useState("");
  const [contact, setContact] = useState("");
  const [loading, setLoading] = useState(false);
  const [feesData, setFeesData] = useState(null);
  const [feesDataLoading, setFeesDataLoading] = useState(false);

  const navigate = useNavigate();

  const handleDeposit = async () => {
    if (!feesData || feesDataLoading) return;

    if (contact?.trim()?.length != 10)
      return toast.error("Please enter valid contact number");
    if (Number(amount) <= 0) return toast.error("Please enter valid amount");

    try {
      setLoading(true);
      const res = await api.post(CONSTANTS.PAYMENT_DEPOSIT, {
        amount: Number(amount),
        contact,
        platform_fee: 0,
        gst_fee: 0,
      });
      let checkoutOptions = {
        paymentSessionId: res.data?.data?.payment_session_id,
        returnUrl: `${window.location.origin}/payment/deposit/status/${res.data?.data?.order_id}?session_id=${res.data?.data?.payment_session_id}`,
      };

      cashfree.checkout(checkoutOptions).then(function (result) {
        if (result.error) {
          alert(result.error.message);
        }
        if (result.redirect) {
          console.log("Redirection");
        }
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, Try again"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isNaN(amount) || Number(amount) <= 0) {
      setFeesData(null);
      return;
    }

    const getData = setTimeout(() => {
      setFeesDataLoading(true);
      setFeesData(null);
      api
        .post(`${CONSTANTS.PAYMENT_DEPOSIT_FEE_DEDUCTION}`, {
          amount: Number(amount),
        })
        .then((response) => {
          setFeesData(response?.data?.fee_structure);
        })
        .catch((err) =>
          toast.error(
            err.message ||
              "We could not process your transaction, Please try after some time."
          )
        )
        .finally(() => {
          setFeesDataLoading(false);
        });
    }, 500);

    return () => clearTimeout(getData);
  }, [amount]);

  return (
    <div>
      <AppHeader />
      <div className="page-container px-4 h-91 bg-white px-2 overflow-y-scroll">
        <h1 className="mt-3">Deposit</h1> <hr className="m-0 mb-3" />
        <div>
          <div className="form-group mt-4">
            <label>Contact Number:</label>
            <input
              type="number"
              className="form-control w-100"
              placeholder="Enter contact number..."
              value={contact}
              name="phone"
              minLength={10}
              maxLength={10}
              max={10}
              onChange={(e) => setContact(e.target.value)}
              required
            />
          </div>

          <div className="form-group mt-4">
            <label>Amount:</label>
            <input
              type="number"
              className="form-control w-100"
              placeholder="Enter amount:"
              value={amount}
              name="amount"
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>

          {feesDataLoading && <Loader />}

          {feesData && (
            <div
              className="border rounded-4 py-2 px-3 mt-3"
              style={{
                boxShadow:
                  "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" className="text-end">
                      Amount (in Rs)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">Amount requested</td>
                    <td className="text-end">{parseInt(feesData?.amount)}</td>
                  </tr>

                  <tr>
                    <td scope="row">Gateway fees</td>
                    <td className="text-end">{feesData?.gatway_fee}</td>
                  </tr>

                  <tr>
                    <td scope="row">GST fees</td>
                    <td className="text-end">{feesData?.gstfee?.gstfee}</td>
                  </tr>

                  <tr>
                    <td scope="row" className="fw-bold fs-6">
                      Final Amt.
                    </td>
                    <td className="text-end fw-bold fs-6">
                      {feesData?.finalamount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="mt-4 d-flex justify-content-between align-items-center">
          <button
            className="btn bg-primary text-white px-4 py-2"
            onClick={() => navigate("/payment", { replace: "true" })}
          >
            Back
          </button>
          <button
            className="btn btn-success px-4 py-2"
            disabled={loading || feesDataLoading || !feesData}
            onClick={handleDeposit}
          >
            {loading ? "Processing..." : "Deposit"}
          </button>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default Deposit;
