import { NavLink } from "react-router-dom";
import { FaHome, FaUser, FaBolt, FaWallet, FaMoneyCheckAlt } from "react-icons/fa";
import { BsBadgeAd } from "react-icons/bs";

const BottomNav = () => {
  return (
    <div className="container-fluid">
      <nav
        className="navbar fixed-bottom bottom-nav"
        style={{
          background: "#fff",
          boxShadow: "0 -5px 10px 0 rgba(0,0,0,0.1)",
        }}
      >
        <div className="container-fluid text-center">
          <NavLink to="/appuserhome">
            <FaHome className="fa-2x" />
          </NavLink>
          <NavLink to="/adhome">
            <BsBadgeAd className="fa-2x" />
          </NavLink>
          <NavLink to="/userwallet">
            <FaWallet className="fa-2x" />
          </NavLink>
          <NavLink to="/payment">
            {/* <FaBolt className="fa-2x" /> */}
            <FaMoneyCheckAlt className="fa-2x" />
          </NavLink>
          <NavLink to="/userprofile">
            <FaUser className="fa-2x" />
          </NavLink>
          
         
        </div>
      </nav>
    </div>
  );
};

export default BottomNav;
