import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// components
import BottomNav from "../../Components/BottomNav/BottomNav";
import AppHeader from "../../Components/AppHeader/AppHeader";
import KycOtpVerification from "../../Components/KycVerification";
// utils and helpers
import api from "../../Service/apiService";
import CONSTANTS from "../../Constants/global";
import KycCheckModal from "../../Components/Modal/KycCheckModal";

const KYCVerification = () => {
  const [page, setPage] = useState(1);
  const [type, setType] = useState("");

  const [aadharDetails, setAadharDetails] = useState({
    number: "",
    file1: null,
    file2: null,
  });
  const [panDetails, setPanDetails] = useState({
    number: "",
    name: localStorage?.getItem("name") ?? "",
    file1: null,
  });
  const [refId, setRefId] = useState("");
  const [reKyc, setReKyc] = useState(false);
  const [loading, setLoading] = useState(false);

  // kyc check modals
  const [kycModalData, setKycModalData] = useState(null);

  const navigate = useNavigate();

  const handleTypeChange = (type) => {
    setType(type);
    setPage(2);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file.type.startsWith("image/")) {
      return toast.error("Selected file is not an image.");
    } else if (file.size > 5 * 1024 * 1024) {
      return toast.error("Selected image is larger than 5 MB.");
    }

    if (type === "aadhar") {
      setAadharDetails({
        ...aadharDetails,
        [e.target.name]: file,
      });
    } else {
      setPanDetails({
        ...panDetails,
        [e.target.name]: file,
      });
    }
  };

  const checkKYC = ({ type, aadhaar, pan }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(CONSTANTS.KYC_CHECK, {
          type,
          aadhar: aadhaar,
          pan,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleVerifyAadhar = async () => {
    if (
      (aadharDetails?.file1 && !aadharDetails?.file2) ||
      (!aadharDetails?.file1 && aadharDetails?.file2)
    ) {
      return toast.error("Please select front and back image both");
    }

    let checkResponse = await checkKYC({
      type: "aadhaar",
      aadhaar: aadharDetails?.number,
    });
    if (checkResponse?.status === 2) {
      setKycModalData({ ...checkResponse, type: "aadhar" });
      setReKyc(true);
      return;
    }

    handleClick({ type: "aadhar" });
  };

  const handleVerifyPan = async () => {
    setLoading(true);
    if (!panDetails?.number && !panDetails?.file1) {
      return toast.error("Please fill any 1 section");
    }

    let checkResponse = await checkKYC({
      type: "pan",
      pan: panDetails?.number,
    });

    setLoading(false);
    if (checkResponse?.status === 2) {
      setKycModalData({ ...checkResponse, type: "pan" });
      return;
    }

    handleClick({ type: "pan" });
  };

  const handleClick = async ({
    reKyc = false,
    type,
  }) => {
    if (type === "pan") {
      try {
        setLoading(true);
        let formData = {};
        let URL = CONSTANTS.PAN_VERIFICATION;

        if (panDetails?.file1) {
          formData = new FormData();
          formData.append(
            "front_image",
            panDetails.file1,
            panDetails?.file1?.filename
          );
          formData.append(
            "verification_id",
            String(Math.floor(1000 + Math.random() * 9000))
          );
          URL = CONSTANTS.DOC_PAN_VERIFY;
        } else {
          formData = {
            pan: panDetails?.number,
            name: panDetails?.name,
            reKyc,
          };
        }
        const response = await api.post(URL, formData);
        setRefId(response?.data?.ref_id);
        toast.success(response?.data?.message);
        navigate("/appuserhome");
      } catch (error) {
        console.log(error);

        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong, Try again later"
        );
      } finally {
        setKycModalData(null);
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        let formData = {};
        let URL = CONSTANTS.OFFLINE_AADHAR_OTP;

        if (aadharDetails?.file1 && aadharDetails?.file2) {
          formData = new FormData();
          formData.append(
            "front_image",
            aadharDetails?.file1,
            aadharDetails?.file1?.filename
          );
          formData.append(
            "back_image",
            aadharDetails?.file2,
            aadharDetails?.file2?.filename
          );
          formData.append(
            "verification_id",
            String(Math.floor(1000 + Math.random() * 9000))
          );

          URL = CONSTANTS.DOC_AADHAR_VERIFY;
        } else {
          formData = {
            aadhaar_number: aadharDetails?.number,
          };
        }

        const response = await api.post(URL, formData);
        toast.success(response?.data?.message);

        if (response?.data?.ref_id) {
          setRefId(response?.data?.ref_id);
          setPage(3);
        } else {
          navigate("/appuserhome");
        }
      } catch (error) {
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong, Try again later"
        );
      } finally {
        setKycModalData(null);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <AppHeader />
      <div className="page-container h-91 bg-white px-2 overflow-y-scroll">
        <h1 className="mt-3 px-2">
          KYC verification {type && ` - ${type?.toUpperCase()}`}
        </h1>
        <hr className="m-0 mb-3" />
        {page === 1 && (
          <div className="d-flex flex-column gap-4">
            <button
              className="btn btn-primary px-4 py-2"
              onClick={() => handleTypeChange("aadhar")}
            >
              Aadhar
            </button>

            <h4 className="text-center my-0">OR</h4>
            <button
              className="btn btn-success px-4 py-2 d-flex flex-column align-items-center justify-content-center gap-1"
              onClick={() => handleTypeChange("pan")}
            >
              Pan Card
            </button>
          </div>
        )}
        {page === 2 && (
          <>
            <p className="px-2 m-0 text-warning fw-bold">
              Note: Please fill any one section for verification
            </p>
            {type === "aadhar" && (
              <div className="px-2">
                <div
                  className="border rounded-4 px-3 pt-4 pb-4 d-flex flex-column"
                  style={{
                    boxShadow:
                      "0 5px 5px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                  }}
                >
                  <div className="form-group">
                    <label>Aadhar Number: </label>
                    <input
                      type="number"
                      value={aadharDetails?.number}
                      placeholder="Enter aadhar number"
                      className="form-control w-100 mt-2"
                      onChange={(e) =>
                        setAadharDetails({
                          ...aadharDetails,
                          number: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <p className="text-center my-4">OR</p>

                <div
                  className="border rounded-4 px-3 pt-4 pb-2 d-flex flex-column"
                  style={{
                    boxShadow:
                      "0 5px 5px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                  }}
                >
                  <div className="form-group d-flex flex-column">
                    <label>Upload front side of aadhar</label>
                    <input
                      type="file"
                      placeholder="Upload front side of aadhar"
                      className="form-control"
                      accept="image/*"
                      name="file1"
                      value={
                        aadharDetails?.file1
                          ? aadharDetails?.file1?.filename
                          : ""
                      }
                      onChange={handleFileChange}
                    />
                    <p
                      className="ms-auto mb-0 mt-2 text-danger"
                      onClick={() =>
                        setAadharDetails({ ...aadharDetails, file1: null })
                      }
                    >
                      Remove file
                    </p>
                  </div>

                  <div className="form-group d-flex flex-column">
                    <label>Upload back side of aadhar</label>

                    <input
                      type="file"
                      placeholder="Upload back side of aadhar"
                      className="form-control"
                      accept="image/*"
                      name="file2"
                      value={
                        aadharDetails?.file2
                          ? aadharDetails?.file2?.filename
                          : ""
                      }
                      onChange={handleFileChange}
                    />

                    <p
                      className="ms-auto mb-0 mt-2 text-danger"
                      onClick={() =>
                        setAadharDetails({ ...aadharDetails, file2: null })
                      }
                    >
                      Remove file
                    </p>
                  </div>
                </div>
              </div>
            )}

            {type === "pan" && (
              <div className="px-2">
                <div
                  className="border rounded-4 px-3 pt-4 pb-4 d-flex flex-column"
                  style={{
                    boxShadow:
                      "0 5px 5px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                  }}
                >
                  <div className="form-group">
                    <label>Pan Number: </label>
                    <input
                      type="text"
                      value={panDetails?.number}
                      placeholder="Enter pan number"
                      className="form-control w-100 mt-2"
                      onChange={(e) =>
                        setPanDetails({ ...panDetails, number: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label>Name: </label>
                    <input
                      type="text"
                      value={panDetails?.name}
                      placeholder="Enter pan name"
                      className="form-control w-100 mt-2"
                      onChange={(e) =>
                        setPanDetails({ ...panDetails, name: e.target.value })
                      }
                    />
                  </div>
                </div>

                <p className="text-center my-4 fw-bold fs-4">OR</p>

                <div
                  className="rounded-4 border px-3 pt-4 pb-4 d-flex flex-column"
                  style={{
                    boxShadow:
                      "0 5px 5px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                  }}
                >
                  <div className="form-group mt-2 d-flex flex-column">
                    <label>Upload front side of pan card</label>
                    <input
                      type="file"
                      placeholder="Upload front side of pan card"
                      className="form-control"
                      name="file1"
                      accept="image/*"
                      value={
                        panDetails?.file1 ? panDetails?.file1?.filename : ""
                      }
                      onChange={handleFileChange}
                    />
                    <p
                      className="ms-auto mb-0 mt-2 text-danger"
                      onClick={() =>
                        setPanDetails({ ...panDetails, file1: null })
                      }
                    >
                      Remove file
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center mt-5 px-2">
              <button
                className="btn btn-primary px-4 py-2"
                onClick={() => {
                  setPage(1);
                  setType("");
                  setAadharDetails();
                  setPanDetails({
                    name: localStorage.getItem("name") ?? "",
                  });
                }}
              >
                Back
              </button>

              <button
                className="btn btn-deposit text-white px-4 py-2 ms-auto"
                disabled={loading}
                onClick={() =>
                  type === "aadhar" ? handleVerifyAadhar() : handleVerifyPan()
                }
              >
                {loading ? "Processing..." : "Verify"}
              </button>
            </div>
          </>
        )}

        {page === 3 && (
          <KycOtpVerification
            handleBack={() => {
              setPage(2);
              setRefId("");
              setReKyc(false);
            }}
            refId={refId}
            reKyc={reKyc}
            aadhaar_number={aadharDetails?.number}
          />
        )}
      </div>
      <KycCheckModal
        show={kycModalData !== null ? true : false}
        handleClose={() => setKycModalData(null)}
        kycData={kycModalData}
        loading={loading}
        handleClick={handleClick}
      />
      <BottomNav />
    </div>
  );
};

export default KYCVerification;
