import "./firstScreen.css";
function FirstScreen() {
  return (
    <>
      <img className="first-screen-logo" src="/assets/images/logo.png" />
      <img src="/assets/images/human_graph.jpeg" />
      <div className="content">
        <p className="heading">Welcome to Izido</p>
        <p className="brief">Where Quiz Meets Earnings</p>
      </div>
    </>
  );
}

export default FirstScreen;
