import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const DeleteAccountModal = ({
  show,
  message,
  handleClick,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => handleClick(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h1 className="text-danger text-decoration-underline">Confirmation</h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="m-0">{message}</p>
        <p>Do you want to continue?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary"  onClick={() => handleClick(false)}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => handleClick(true)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAccountModal;
