import { useState } from "react";
import { useNavigate  } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "./AdCarousel.css";
import FifthScreen from "./screen/FifthScreen/fifthScreen";
import FourthScreen from "./screen/FourthScreen/fourthScreen";
import ThirdScreen from "./screen/ThirdScreen/thirdScreen";
import FirstScreen from "./screen/FirstScreen/firstScreen";
import SecondScreen from "./screen/SecondScreen/secondScreen";

function AdCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % 5);
  };

  const navigate = useNavigate ();
  const handleSkip = () => {
    // Redirect to the login screen
    navigate("/");
  };

  const renderFooter = () => {
    if (index !== 4) {
      return (
        <div className="footer d-flex justify-content-between">
          <div onClick={handleSkip}>Skip</div>
          <div onClick={handleNext}>Next</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="carousel-container">
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
        <Carousel.Item>
          <div className="carousel-bg-2" />
          <Carousel.Caption>
            <FirstScreen></FirstScreen>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-bg-2" />
          <Carousel.Caption>
            <SecondScreen></SecondScreen>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-bg" />
          <Carousel.Caption>
            <ThirdScreen></ThirdScreen>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-bg" />
          <Carousel.Caption>
            <FourthScreen></FourthScreen>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-bg-2" />
          <Carousel.Caption>
            <FifthScreen></FifthScreen>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      {renderFooter()}
    </div>
  );
}

export default AdCarousel;
