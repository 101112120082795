import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// utils and helpers
import api from "../Service/apiService";
import CONSTANTS from "../Constants/global";

const KycOtpVerification = ({ handleBack, refId, aadhaar_number, reKyc }) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [newRefId, setNewRefId] = useState(null);

  const [disableResentOTP, setDisableResentOTP] = useState(true);
  const [counter, setCounter] = useState(60);

  const navigate = useNavigate();

  const resendOtp = async () => {
    if (disableResentOTP || counter > 0) return;

    try {
      setDisableResentOTP(true);
      setCounter(60);
      const response = await api.post(CONSTANTS.OFFLINE_AADHAR_OTP, {
        aadhaar_number: aadhaar_number,
      });

      if (response?.data?.ref_id) {
        setNewRefId(response?.data?.ref_id);
      }
      toast.success("OTP has been sent to successfully");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, Try again later"
      );
    }
  };

  useEffect(() => {
    let timer;
    if (counter > 0 && disableResentOTP) {
      timer = setInterval(() => {
        setCounter((prevCountdown) => {
          if (prevCountdown <= 0) {
            clearInterval(timer);
            setDisableResentOTP(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setDisableResentOTP(false);
    }
  }, [counter, disableResentOTP]);

  const handleVerifyOTP = async () => {
    if (!otp) return toast.error("Please enter OTP");

    try {
      setLoading(true);
      let formData = {
        ref_id: newRefId || refId,
        otp: otp,
        reKyc: reKyc,
        aadhaar_number
      };
      const response = await api.post(
        CONSTANTS.OFFLINE_AADHAR_VERIFY_OTP,
        formData
      );
      toast.success(response?.data?.message);
      navigate("/appuserhome");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong, Try again later"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-2">
      <h3 className="text-center mb-4">Verify OTP</h3>

      <div className="form-group d-flex flex-column">
        <label>OTP is sent to your linked mobile number</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />

        <span className="ms-auto mt-2 text-info" onClick={resendOtp}>
          Resend OTP{" "}
          {counter > 0 && (
            <span style={{ fontSize: "0.7rem" }}>({counter} seconds)</span>
          )}
        </span>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-5">
        <button className="btn btn-primary px-4 py-2" onClick={handleBack}>
          Back
        </button>

        <button
          className="btn btn-deposit text-white px-4 py-2"
          onClick={handleVerifyOTP}
          disabled={loading}
        >
          {loading ? "Processing..." : "Verify"}
        </button>
      </div>
    </div>
  );
};

export default KycOtpVerification;
