import { Link } from "react-router-dom";
import moment from "moment";

const ContestCard = ({ res }) => {
  return (
    <Link
      to="/singleQuizdetail"
      state={{
        contestName: res.contestName,
        contestImage: res.contestImage,
        EntryAmount: res.EntryAmount,
        contestTime: res.contestTime,
        contestid: res.id,
      }}
    >
      <div className="d-flex align-items-center justify-content-between gap-2 contest-card card-item-box">
        <div className="d-flex align-items-center gap-2">
          <img
            className="contest-card-img"
            // src="./assets/images/quiz-icon.jpg"
            src={`${process.env.REACT_APP_AD_IMAGE_PATH_URL}${res.contestImage}`}
            alt="contest-logo"
          />
          <div className="my-2 align-self-stretch">
            <div className="h6 mb-0 pb-1 word-break-all">
              {res?.contestName}
            </div>
            <div className="fs-6 mb-0">
              Time:{moment(res.contestTime).format("DD/MM HH:mm")}
            </div>
            <div className="">
              <span className="text-gray d-inline-block pe-3 fs-5">
                Entry Fee
              </span>
              <span className="text-yellow fs-5">
                {`₹ ${res.EntryAmount}`} <i className="coin"></i>
              </span>
            </div>
          </div>
        </div>
        <div className="contest-card-3">
          {moment().isBetween(
            moment(res.contestTime),
            moment(res.contestEndTime)
          ) ? (
            <p className="btn btn-sm btn-success btn-live">Live</p>
          ) : moment().isAfter(moment(res.contestTime)) ? (
            <p className="btn btn-sm btn-warning btn-live">Expired</p>
          ) : (
            ""
          )}
          <i className="fas fa-arrow-right pr-2"></i>
        </div>
      </div>
    </Link>
  );
};

export default ContestCard;
