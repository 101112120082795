import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import hidePasswordImg from "./eyesslash.svg";
import showPasswordImg from "./eyes.svg";
import toast from "react-hot-toast";
import CONSTANTS from "../../Constants/global";
import ROUTER_PATHS from "../../Constants/routerPath";
import { ACTIONS, useAppContext } from "../../context/AppContext";
import api from "../../Service/apiService";

const Formlogin = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  backendvalue,
  values,
}) => {
  // const [email, setEmail] = useState(" ");
  // const [password, setPassword] = useState(" ");
  // const [error, setError] = useState({ errorName: "", errorMsg: "" });
  const [backerror, setBackerror] = useState(" ");
  const [showPass, setShowPass] = useState(false);
  // const [showmessage, setshowMesaage] = useState(false);
  // const [toastMessage, setToastMessage] = useState("");
  // const [toastColor, setToastColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const navigate = useNavigate();
  const { updateAppState } = useAppContext();

  const showHidePassword = () => {
    setShowPass(!showPass);
  };

  const getFcmDataFromNative = () => {
    return new Promise((resolve, reject) => {
      // If web doesn't get any response from native then we will throw an error after 20-seconds
      const timeoutId = setTimeout(() => {
        reject({ message: "Something went wrong, Please try again" });
      }, 20000);

      window.sendUserLoginDetail = (fcmData) => {
        clearTimeout(timeoutId);
        resolve(JSON.parse(fcmData));
      };
    });
  };

  const registerFcm = async () => {
    try {
      const fcmData = await getFcmDataFromNative();
      await api.post(CONSTANTS.FCM_REGISTER_TOKEN, fcmData);
    } catch (error) {
      console.error("FCM token error:", error.message);
    }
  };

  async function loginn(e) {
    e.preventDefault();
    const email = values.email;
    const password = values.password;
    handleSubmit();
    const sendData = {
      email,
      password,
    };
    const API_URL = CONSTANTS.USERLOGIN;
    try {
      setLoading(true);
      const data = await axios.post(API_URL, sendData);

      if (data.data.auth) {
        sessionStorage.setItem("token", "Bearer " + data.data.token);
        localStorage.setItem(
          "refreshToken",
          "Bearer " + data.data.refreshToken
        );
        localStorage.setItem("name", data.data.details.name);
        localStorage.setItem("email", data.data.details.email);
        localStorage.setItem(
          "emailVerified",
          data.data.details.isEmailVerified
        );

        if (window.NativeHook) {
          window.NativeHook.userLoggedIn();
          registerFcm();
        } else if (window.webkit && window.webkit.messageHandlers) {
          window.webkit.messageHandlers.userLoggedIn.postMessage({});
          registerFcm();
        }

        updateAppState(
          ACTIONS.IS_VERIFIED,
          data.data.details.isVerified || false
        );
        updateAppState(
          ACTIONS.KYC_EXPIRED,
          data.data.details.isKycExpired || false
        );

        localStorage.setItem("loginStatus", true);

        if (!data.data?.details?.isEmailVerified) {
          navigate("/verify-email", {
            state: {
              email: data.data.details.email,
            },
          });
        } else {
          toast.success("Welcome to Izido");
          setTimeout(() => {
            navigate("/appuserhome");
            window.location.reload();
          }, 1000);
        }
      }
    } catch (e) {
      //console.log(e.response);
      toast.error(e.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  const onCaptchaChange = (value) => {
    if (value) setCaptchaVerified(true);
    else setCaptchaVerified(false);
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    const status = localStorage.getItem("loginStatus");
    const emailVerified = localStorage.getItem("emailVerified");

    if (refreshToken && status && emailVerified === "true")
      navigate("/appuserhome", { replace: true });
  }, []);

  return (
    <>
      <div className="p-4 bg-white h-screen">
        <div className="text-center">
          <img
            src="./assets/images/login.svg"
            alt="login-svg"
            style={{
              height: "200px",
              width: "200px",
            }}
          />
        </div>
        <h1 className="mt-5 mb-3 fw-bold" style={{ fontSize: "2rem" }}>
          Login
        </h1>

        <form onSubmit={loginn} autoComplete="off">
          <div className="select-role">
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              <label className="btn active">
                <input type="radio" name="options" id="admin" />
                <div className="icon">
                  <img
                    src="vendors/images/briefcase.svg"
                    className="svg"
                    alt=""
                  />
                </div>
                <span>I'm</span>
                Admin
              </label>
              <label className="btn">
                <input type="radio" name="options" id="user" />
                <div className="icon">
                  <img src="vendors/images/person.svg" className="svg" alt="" />
                </div>
                <span>I'm</span>
                User
              </label>
            </div>
          </div>
          <div className="input-group custom">
            <div className="input-group-prepend custom">
              <span className="input-group-text">
                <i className="fas fa-user"></i>
              </span>
            </div>
            <input
              type="email"
              className={errors.email ? "form-control red" : "form-control"}
              id="email"
              placeholder="Enter Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              name="email"
            />
            {/* <div className="input-group-prepend custom">
 									<span className="input-group-text"><i className="icon-copy dw dw-user1"></i></span>
 								</div> */}
          </div>
          {errors.email ? (
            <div className="errormsgs" style={{ color: "red" }}>
              {touched.email && errors.email}
            </div>
          ) : (
            <></>
          )}
          <div className="input-group custom">
            <div className="input-group-prepend custom">
              <span className="input-group-text">
                <i className="fas fa-lock"></i>
              </span>
            </div>
            <input
              type={showPass ? "text" : "password"}
              className={
                errors.password
                  ? "form-control form-control red"
                  : "form-control"
              }
              id="password"
              placeholder="*********"
              value={values.password}
              onChange={handleChange}
              // onBlur={handleBlur}
              name="password"
              required
            />
            <div className="input-group-append custom">
              <span className="input-group-text">
                {" "}
                <img
                  className="input-img"
                  src={showPass ? showPasswordImg : hidePasswordImg}
                  alt="Show/Hide icon"
                  onClick={showHidePassword}
                />
              </span>
            </div>
          </div>
          {errors.password ? (
            <div className="errormsgs" style={{ color: "red" }}>
              {touched.password && errors.password}
            </div>
          ) : (
            <></>
          )}
          <div className="row pb-30">
            <div className="col-6">
              <div
                className="custom-control custom-checkbox"
                style={{ display: "none" }}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember
                </label>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 text-right">
              <div className="forgot-password">
                <Link to="/forgetpassword">Forgot Password ?</Link>
              </div>
            </div>
          </div>
          {backerror == " " ? (
            <> </>
          ) : (
            <div className="alert alert-danger" role="alert">
              {backerror}
            </div>
          )}

          <div className="row mb-3 mx-auto">
            <div className="col-sm-12 text-center">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onCaptchaChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="input-group mb-0">
                <button
                  className="btn btn-primary btn-sm w-100 bg-dark-orange text-white d-flex justify-content-between align-items-center border-0 px-4 py-2 border-radius-10px"
                  type="submit"
                  disabled={loading || errors?.email || !captchaVerified}
                >
                  {loading ? (
                    <div className="d-flex mx-auto">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                  ) : (
                    <>
                      <span>Sign In</span>{" "}
                      <i className="fas fa-sign-in-alt"></i>
                    </>
                  )}
                </button>
                {/* <a className="btn btn-primary btn-lg btn-block" href="index.html">Sign In</a> */}
              </div>
              <div
                className="font-16 weight-600 pt-10 pb-10 text-center"
                data-color="#707373"
              >
                OR
              </div>
              <div className="input-group mb-0">
                <Link
                  to="/register"
                  className="btn btn-outline-primary btn-sm btn-block w-100"
                >
                  Register To Create Account
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* <ToastContainer autoClose={3000} /> */}
    </>
  );
};

export default Formlogin;
