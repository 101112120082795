import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import api from "../../Service/apiService";
import CONSTANTS from "../../Constants/global";

const VerifyEmail = () => {
  const [page, setPage] = useState(1); //1: send-OTP, 2: verify-otp
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const verifyOtp = async (e) => {
    e.preventDefault();
    if (!otp || !otp?.trim()?.length) return toast.error("Invalid OTP");

    try {
      setLoading(true);
      await api.post(CONSTANTS.OTP_VERIFICATION, {
        email: location?.state?.email,
        otpCode: otp,
        type: "emailVerified",
      });

      toast.success("Welcome to Izido");
      localStorage.setItem("emailVerified", true);
      navigate("/appuserhome", { replace: true });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error.message ||
          "Something went wrong, Try again"
      );
    } finally {
      setLoading(false);
    }
  };

  const sendOtp = async () => {
    try {
      setLoading(true);
      await api.post(CONSTANTS.SEND_OTP, {
        email: location?.state?.email,
      });
      setPage(2);
      toast.success("OTP has been sent to your registred email");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error.message ||
          "Something went wrong, Try again"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location?.state?.email) navigate("/", { replace: true });
  }, [location?.state]);

  return (
    <>
      <div className="login-wrap min-h-screen bg-white d-flex justify-content-center flex-wrap">
        <div>
          <div
            className="px-5 display-6"
            style={{ marginLeft: "-30px" }}
            onClick={() => {
              if (page === 2) setPage(1);
              else navigate("/", { replace: true });
            }}
          >
            <BsArrowLeft />
          </div>

          <div className="text-center">
            <img
              src="./assets/images/verify_email.svg"
              alt="reset-pass"
              style={{
                height: "200px",
                width: "200px",
              }}
            />
          </div>
          {page === 1 ? (
            <div className="align-items-center">
              <div className="col-sm-6">
                <div className="mt-4 px-5">
                  <div className="login-title">
                    <h1 className="font-bold text-center">
                      Account Verification
                    </h1>
                  </div>
                  <button
                    onClick={sendOtp}
                    className="btn btn-success mt-5 w-100"
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="d-flex justify-content-center mx-auto px-4 py-2">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    ) : (
                      "Click here to verify email"
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="align-items-center">
              <div className="col-sm-6">
                <div className="mt-4 px-5">
                  <div className="login-title">
                    <h1 className="font-bold">Account Verification</h1>
                  </div>
                  <p className="mb-20">
                    Enter the OTP sent to your email address
                  </p>
                  <form onSubmit={verifyOtp}>
                    <div className="input-group custom">
                      <div className="input-group-prepend custom">
                        <span className="input-group-text h-100">
                          <i className="fas fa-envelope" aria-hidden="true"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                      />
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12">
                        <div className="input-group mb-0">
                          <button
                            disabled={loading}
                            className="btn  btn-sm btn-block bg-dark-orange text-white d-flex justify-content-between align-items-center w-100 px-4 py-2"
                            type="submit"
                          >
                            {loading ? (
                              <div className="d-flex mx-auto px-4 py-2">
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            ) : (
                              <>
                                <span>Verify OTP</span>{" "}
                                <i className="fas fa-sign-in-alt"></i>
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div></div>
    </>
  );
};

export default VerifyEmail;
