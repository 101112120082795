import { useState, useContext, createContext } from "react";

const AppContext = createContext(null);

const initialState = {
  user: null,
  wallet: 0,
  nextContest: null,
  isVerified: false,
  isKycExpired: false,
  profileImage: "",
  isPhoneVerified: false,
  phone: ""
};

export const ACTIONS = {
  WALLET: "wallet",
  USER: "user",
  NEXT_CONTEST: "nextContest",
  IS_VERIFIED: "isVerified",
  KYC_EXPIRED: "isKycExpired",
  PROFILE_IMAGE: "profileImage",
  PHONE_VERIFIED: "isPhoneVerified",
  PHONE: "phone"
};

export const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState(initialState);

  const updateAppState = (key, value) => {
    setAppState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <AppContext.Provider value={{ appState, updateAppState }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
