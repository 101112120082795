import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import hidePasswordImg from "./eyesslash.svg";
import showPasswordImg from "./eyes.svg";
import toast from "react-hot-toast";
import CONSTANTS from "../../Constants/global";
import { ACTIONS, useAppContext } from "../../context/AppContext";
import api from "../../Service/apiService";
import moment from "moment";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const Form = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  backendvalue,
  values,
}) => {
  const [backerroremail, setBackerroremail] = useState(" ");
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [backerrorpassword, setBackerrorpassword] = useState(" ");
  const [signupsucess, setSignupsucess] = useState(" ");
  const [showmessage, setshowMesaage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [userProfileInfo, setUserProfileInfo] = useState();
  const [genderList, setGenderList] = useState({
    "MALE": "male",
    "FEMALE": "female",
    "OTHERS": "others"
  });
  const [state, setState] = useState('');
  const navigate = useNavigate();
  const { updateAppState } = useAppContext();

  const showHidePassword = () => {
    setShowPass(!showPass);
  };

  const showHideconfirmPassword = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  // console.log(JSON.stringify(errors))

  const getFcmDataFromNative = () => {
    return new Promise((resolve, reject) => {
      // If web doesn't get any response from native then we will throw an error after 20-seconds
      const timeoutId = setTimeout(() => {
        reject({ message: "Something went wrong, Please try again" });
      }, 20000);

      window.sendUserLoginDetail = (fcmData) => {
        clearTimeout(timeoutId);
        resolve(JSON.parse(fcmData));
      };
    });
  };

  const registerFcm = async () => {
    try {
      const fcmData = await getFcmDataFromNative();
      await api.post(CONSTANTS.FCM_REGISTER_TOKEN, fcmData);
    } catch (error) {
      console.error("FCM token error:", error.message);
    }
  };

  const handleStateChange = (val) => {
    setState(val);
  };

  async function signupp(e) {
    e.preventDefault();
    const API_URL = CONSTANTS.USERSIGNUP;
    const email = values.email;
    const user = values.firstName;
    const password = values.password;
    const organization = values.organization;
    const gender = values.gender;
    const dob = values.dob;
    let sendData = {
      name: user,
      gender,
      dob,
      email,
      password,
      Wallet: 0,
      registeredState: state,
    };
    try {
      setLoading(true);
      if (JSON.stringify(errors) == "{}") {
        const data = await axios.post(API_URL, sendData);

        handleSubmit();
        if (data.data.auth) {
          toast.success(data?.data?.message);
          sessionStorage.setItem("token", "Bearer " + data.data.token);
          localStorage.setItem(
            "refreshToken",
            "Bearer " + data.data.refreshToken
          );
          localStorage.setItem("name", data.data.details.name);
          localStorage.setItem("email", data.data.details.email);
          localStorage.setItem("loginStatus", true);

          if (window.NativeHook) {
            window.NativeHook.userLoggedIn();
            registerFcm();
          } else if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.userLoggedIn.postMessage({});
            registerFcm();
          }

          updateAppState(ACTIONS.IS_VERIFIED, false);
          setTimeout(() => {
            navigate("/verify-email", {
              state: {
                email: data.data.details.email,
              },
            });
          }, 2000);
        }
      }
    } catch (e) {
      toast.error(e.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  const onCaptchaChange = (value) => {
    if (value) setCaptchaVerified(true);
    else setCaptchaVerified(false);
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    const status = localStorage.getItem("loginStatus");
    const emailVerified = localStorage.getItem("emailVerified");

    if (refreshToken && status && emailVerified === "true")
      navigate("/appuserhome", { replace: true });
  }, []);

  return (
    <div className="p-4 bg-white min-h-screen">
      <div className="text-center">
        <img
          src="./assets/images/register.svg"
          alt="register"
          style={{ width: "200px", height: "200px" }}
        />
      </div>

      <h1 className="fw-bold mt-3">Register</h1>

      <form onSubmit={signupp} autoComplete="off">
        <section>
          <div className="form-wrap max-width-600 mx-auto mt-3">
            <div className="row">
              <label className="col-sm-4 col-form-label">
                Full Name<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className={
                    errors.firstName
                      ? "form-control form-control red"
                      : "form-control"
                  }
                  id="first-name-input"
                  placeholder="Enter your full name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="firstName"
                  required
                />
              </div>
            </div>
            {errors.firstName ? (
              <div className="errormsg" style={{ color: "red" }}>
                {touched.firstName && errors.firstName}
              </div>
            ) : (
              <></>
            )}

            <div className="row">
              <label className="col-sm-4 col-form-label">
                DOB<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-8">
                <input
                  className={
                    errors.dob
                      ? "form-control form-control red"
                      : "form-control"
                  }
                  id="dob"
                  name="dob"
                  type={"date"}
                  required
                  value={moment(values.dob).format("YYYY-MM-DD")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.dob ? (
                <div className="errormsg" style={{ color: "red" }}>
                  {touched.dob && errors.dob}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="row">
              <label className="col-sm-4 col-form-label">
                Gender<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-8">
                <select
                  required
                  class="form-select"
                  className={
                    errors.gender
                      ? "form-select form-select red"
                      : "form-select"
                  }
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Gender</option>
                  {Object.keys(genderList).map((key, index) => {
                    return (
                      <option key={index} value={genderList[key]}>
                        {key}
                      </option>
                    );
                  })}
                </select>
                {errors.gender ? (
                  <div className="errormsg" style={{ color: "red" }}>
                    {touched.gender && errors.gender}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row">
              <label className="col-sm-4 col-form-label">Country<span style={{ color: "red" }}>*</span></label>
              <div className="col-sm-8">
                <input
                  className="form-control"
                  id="country"
                  name="country"
                  type="text"
                  value="India"
                  readOnly
                />
              </div>
            </div>

            <div className="row">
              <label className="col-sm-4 col-form-label">
                State<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-8">
                <RegionDropdown
                  className="form-control"
                  country="India"
                  value={state}
                  onChange={handleStateChange}
                  blankOptionLabel="Select State"
                />
              </div>
            </div>

            <div className="row">
              <label className="col-sm-4 col-form-label">
                Email Address<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-8">
                <input
                  type="email"
                  className={
                    errors.email
                      ? "form-control form-control red"
                      : "form-control"
                  }
                  id="email"
                  placeholder="Enter Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  name="email"
                />
              </div>
            </div>
            {errors.email ? (
              <div className="errormsg" style={{ color: "red" }}>
                {touched.email && errors.email}
              </div>
            ) : (
              <></>
            )}
            <div className="row">
              <label className="col-sm-4 col-form-label">
                Password<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-8 position-relative">
                <input
                  type={showPass ? "text" : "password"}
                  className={
                    errors.password
                      ? "form-control form-control red"
                      : "form-control"
                  }
                  id="password"
                  placeholder="*********"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="password"
                  required
                />
                <div className="input-group-append custom">
                  <span className="input-group-texts">
                    {" "}
                    <img
                      className="input-img"
                      src={showPass ? showPasswordImg : hidePasswordImg}
                      alt="Show/Hide icon"
                      onClick={showHidePassword}
                    />
                  </span>
                </div>
              </div>
            </div>
            {errors.password ? (
              <div className="errormsg" style={{ color: "red" }}>
                {touched.password && errors.password}
              </div>
            ) : (
              <></>
            )}
            <div className="row">
              <label className="col-sm-4 col-form-label">
                Confirm Password<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-8 position-relative">
                <input
                  type={showConfirmPass ? "text" : "password"}
                  className={
                    errors.confirmpassword
                      ? "form-control form-control red"
                      : "form-control"
                  }
                  id="confirmpassword"
                  placeholder="*********"
                  value={values.confirmpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="confirmpassword"
                  required
                />
                <div className="input-group-append custom">
                  <span className="input-group-texts">
                    {" "}
                    <img
                      className="input-img"
                      src={showConfirmPass ? showPasswordImg : hidePasswordImg}
                      alt="Show/Hide icon"
                      onClick={showHideconfirmPassword}
                    />
                  </span>
                </div>
              </div>
            </div>
            {errors.confirmpassword ? (
              <div className="errormsg" style={{ color: "red" }}>
                {touched.confirmpassword && errors.confirmpassword}
              </div>
            ) : (
              <></>
            )}

            {backerrorpassword == " " ? (
              <> </>
            ) : (
              <div className="alert alert-danger" role="alert">
                {backerrorpassword}
              </div>
            )}
            {backerroremail == " " ? (
              <> </>
            ) : (
              <div className="alert alert-danger" role="alert">
                {backerroremail}
              </div>
            )}

            <div className="row mt-3 mb-3 mx-auto">
              <div className="col-sm-12 text-center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={onCaptchaChange}
                />
              </div>
            </div>

            <button
              className="btn btn-sm btn-block bg-dark-orange text-white d-flex justify-content-between align-items-center w-100 border-radius-10px mt-4"
              type="submit"
              disabled={loading || !captchaVerified}
            >
              {loading ? (
                <div className="d-flex mx-auto">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                <>
                  <span>Sign Up</span> <i className="fas fa-sign-in-alt"></i>
                </>
              )}
            </button>
            {/* <button type="submit" className="btn btn-primary">Submit</button> */}
          
          <div className="row">
            <div className="col-sm-12 pt-3">
              <p className="m-0 mb-4">
                Already have an account?{" "}
                <Link to="/">
                  <span className="text-dark-orange fw-bold">Sign In</span>
                </Link>
              </p>
            </div>
          </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default Form;
