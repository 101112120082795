
import { Button, Modal } from 'react-bootstrap'

const BackBtnModalAlert = ({ showModal, setShowModal }) => {
  return (
    <Modal
        size="md"
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-danger">
            Warning!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please do not leave the contest in between. All your progress will be
            lost, and you will <b>not be credited with any amount</b>.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-orange" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default BackBtnModalAlert;