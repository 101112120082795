import "./thirdScreen.css";

function ThirdScreen() {
  return (
    <div className="third-screen">
      <img className="logo" src="/assets/images/logo.png" />
      {/* first rounded pill  */}
      <div className="first-rounded-pill">
        <div className="black-circle-icon"></div>
        <div className="content">
          <h5 className="content-text">Active Players</h5>
          <h2 className="act-ply-count">516</h2>
        </div>
        <div className="ply-imgs">
          <img src="/assets/images/profile.png" />
          <img src="/assets/images/profile.png" />
          <img src="/assets/images/profile.png" />
        </div>
      </div>

      {/* second rounded pill */}
      <div className="second-rounded-pill">
        <h5 className="heading">Total Winning Prizes</h5>
        <div className="position-absolute yellow-ball-icon"></div>
        <h5 className="ttl-win-prize">Rs.51600 </h5>
      </div>

      {/* third rounded pill */}
      <div className="third-rounded-pill">
        <h4 className="heading">Enter Competition</h4>
        <div className="info">
          <div className="left-circle">
            <i class="fas fa-times"></i>
          </div>
          <div className="d-flex">
            <div className="position-absolute yellow-ball-icon"></div>
            <div className="comp-entry">Rs.100</div>
          </div>

          <div className="right-circle">
            <i class="fas fa-check" style={{ fontSize: "35px" }}></i>
          </div>
        </div>
      </div>

     <div class="content">
      <p className="how-prizes-grow">How Prizes Grow</p>
        <div className="brief">
          Your entry fee builds the prize pool. More players mean bigger prizes!
          Imagine 516 players each putting in 100 Rs,{" "}
        </div>
      <div className="pool-prize">Creating a 51,600 Rs prize pool!</div>
      </div> 
    </div>
  );
}

export default ThirdScreen;
