import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import hidePasswordImg from "./images/eyesslash.svg";
import showPasswordImg from "./images/eyes.svg";
import axios from "axios";
import CONSTANTS from "../../Constants/global";
import toast from "react-hot-toast";

import { BsArrowLeft } from "react-icons/bs";

function ResetPassword() {
  const [currentPage, setcurrentPage] = useState(2);
  const navigate = useNavigate();
  const location = useLocation();
  //const { email } = location.state;
  const [otpCode, setotpCode] = useState();
  const [newPassword, setnewPassword] = useState("");
  const [confPassword, setconfPassword] = useState("");
  const [error, setError] = useState({ errorName: "", errorMsg: "" });
  const [showPass, setShowPass] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);
  // const [showmessage, setshowMesaage] = useState(false);
  // const [toastMessage, setToastMessage] = useState("");
  // const [toastColor, setToastColor] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOTPVerification = (e) => {
    e.preventDefault();
    if (validations()) {
      handleSubmit();
    }
  };

  function validations() {
    if (!otpCode) {
      setError({
        errorName: "otpCode",
        errorMsg: "Invalid OTP",
      });
      return false;
    }
    return true;
  }

  async function handleSubmit() {
    const { email } = location.state;

    const sendData = {
      email,
      otpCode,
    };

    try {
      setLoading(true);
      const data = await axios.post(CONSTANTS.OTP_VERIFICATION, sendData);
      console.log("ssss", data);
      //navigate("/reset-password");
      //console.log(data);
      // setToastMessage(data.data.message);
      // setshowMesaage(true);
      if (data.data.status === 1) {
        setcurrentPage(2);
        localStorage.setItem("emailVerified", true);
        toast.success(data.data.message);
      } else {
        toast.error(data.data.message);
      }
    } catch (e) {
      if (e.response?.data?.message == "OTP Expired") {
        toast.error(e.response?.data?.message || e.message);
        setTimeout(() => {
          navigate("/forgetpassword");
        }, 1000);
      } else {
        toast.error(e.response?.data?.message || e.message);
      }
    } finally {
      setLoading(false);
    }
  }

  function passwordValidation(password) {
    const reg =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,14}$/;
    return reg.test(password);
  }

  function handleForm() {
    if (!newPassword) {
      setError({
        errorName: "new password",
        errorMsg: "Please enter your password",
      });
      return false;
    }
    if (!passwordValidation(newPassword)) {
      setError({
        errorName: "new password",
        errorMsg:
          'Password must be 5-14 characters, with at least one uppercase, one lowercase, one number, and one special character from "@$!%*?&".',
      });
      return false;
    }
    if (!confPassword) {
      setError({
        errorName: "confirm password",
        errorMsg: "Please re-enter your password",
      });
      return false;
    }

    if (newPassword !== confPassword) {
      setError({
        errorName: "confirm password",
        errorMsg: "Password doesn't match",
      });
      return false;
    }
    return true;
  }

  async function resetPass(e) {
    const { email } = location.state;
    
    if(!otpCode || !otpCode?.trim().length) return toast.error("Please enter OTP code")
    
    const sendData = {
      email,
      newPassword,
      otpCode: otpCode,
    };

    try {
      setLoading(true);
      const data = await axios.post(CONSTANTS.RESET_PASSWORD, sendData);
      //navigate("/reset-password");
      console.log(data);
      // setToastMessage(data.data.message);
      // setshowMesaage(true);

      if (data.data.status === 2) {
        toast.success(data.data.message);
        setTimeout(() => {
          navigate("/");
        }, [2000]);
      } else {
        toast.error(data.data.message);
      }
    } catch (e) {
      if (e.response?.data?.message == "OTP Expired") {
        toast.error(e.response?.data?.message || e.message);
        setTimeout(() => {
          navigate("/forgetpassword");
        }, 1000);
      } else {
        toast.error(e.response?.data?.message || e.message);
      }
    } finally {
      setLoading(false);
    }
  }

  const submitReset = (e) => {
    e.preventDefault();
    if (handleForm()) {
      resetPass();
    }
  };

  const showHidePassword = () => {
    setShowPass(!showPass);
  };
  //console.log(location);

  const showHideConfPassword = () => {
    setShowConfPass(!showConfPass);
  };

  return (
    <div className="login-wrap min-h-screen bg-white">
      {/* <div className="login-header box-shadow">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <div className="brand-logo">
            <Link to="/">
              <img src="vendors/images/money3.png" alt="" />
            </Link>
          </div>
          <div className="login-menu">
            <ul>
              <li>
                <Link to="/">Login</Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="">
        <div className="px-5 display-6" onClick={() => navigate(-1)}>
          <BsArrowLeft />
        </div>

        <div className="text-center">
          <img
            src="./assets/images/reset.svg"
            alt="reset-pass"
            style={{
              height: "200px",
              width: "200px",
            }}
          />
        </div>

        <div className="">
          <div className="align-items-center">
            {/* <div className="col-md-6">
              <img src="vendors/images/forgot-password.png" alt="" />
            </div> */}
            <div className="col-md-12">
              {currentPage === 1 && (
                <div className="mt-4 px-5">
                  <div className="login-title">
                    <h2 className="font-bold">OTP Verification</h2>
                  </div>
                  <h6 className="mb-20">Enter OTP to reset your password</h6>
                  <form>
                    <div className="input-group custom">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        placeholder="Enter OTP"
                        onChange={(e) => {
                          setotpCode(e.target.value);
                          setError({
                            errorName: "",
                            errorMsg: "",
                          });
                        }}
                      />
                      <div className="input-group-append custom"></div>
                    </div>
                    {error && error.errorName === "otpCode" && (
                      <div style={{ color: "red" }}>{error.errorMsg}</div>
                    )}
                    <div className="row align-items-center">
                      <div className="col-5">
                        <div className="input-group mb-0">
                          <button
                            className="btn btn-lg btn-block bg-dark-orange text-white px-4 py-2"
                            disabled={loading}
                            onClick={(e) => handleOTPVerification(e)}
                          >
                            {loading ? (
                              <div className="d-flex mx-auto px-4 py-2">
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>

                          {/* <a className="btn btn-primary btn-lg btn-block" href="index.html">Submit</a> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {currentPage === 2 && (
                <div className="mt-4 px-5">
                  <div className="login-title">
                    <h2 className="font-bold">Reset Password</h2>
                  </div>
                  <h6 className="mb-20">
                    Enter your OTP, new password, confirm and submit
                  </h6>
                  <div className="input-group custom">
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      placeholder="Enter OTP"
                      onChange={(e) => {
                        setotpCode(e.target.value);
                        setError({
                          errorName: "",
                          errorMsg: "",
                        });
                      }}
                    />
                    <div className="input-group-append custom"></div>
                  </div>
                  {error && error.errorName === "otpCode" && (
                    <div style={{ color: "red" }}>{error.errorMsg}</div>
                  )}

                  <form>
                    <div className="input-group custom">
                      <input
                        type={showPass ? "text" : "password"}
                        className="form-control form-control-lg"
                        placeholder="New Password"
                        onChange={(e) => {
                          setnewPassword(e.target.value);
                          setError({
                            errorName: "",
                            errorMsg: "",
                          });
                        }}
                      />
                      <div className="input-group-append custom">
                        <span className="input-group-text h-100">
                          <img
                            className="input-img"
                            src={showPass ? showPasswordImg : hidePasswordImg}
                            alt="Show/Hide icon"
                            onClick={showHidePassword}
                          />
                        </span>
                      </div>
                    </div>
                    {error && error.errorName === "new password" && (
                      <div style={{ color: "red" }}>{error.errorMsg}</div>
                    )}
                    <div className="input-group custom">
                      <input
                        type={showConfPass ? "text" : "password"}
                        className="form-control form-control-lg"
                        placeholder="Confirm New Password"
                        onChange={(e) => {
                          setconfPassword(e.target.value);
                          setError({
                            errorName: "",
                            errorMsg: "",
                          });
                        }}
                      />
                      <div className="input-group-append custom">
                        <span className="input-group-text h-100">
                          <img
                            className="input-img"
                            src={
                              showConfPass ? showPasswordImg : hidePasswordImg
                            }
                            alt="Show/Hide icon"
                            onClick={showHideConfPassword}
                          />
                        </span>
                      </div>
                    </div>
                    {error && error.errorName === "confirm password" && (
                      <div style={{ color: "red" }}>{error.errorMsg}</div>
                    )}
                    <div className="row align-items-center">
                      <div className="col-5">
                        <div className="input-group mb-0">
                          <button
                            className="btn btn-lg btn-block bg-dark-orange text-white px-4 py-2"
                            onClick={(e) => submitReset(e)}
                          >
                            {loading ? (
                              <div className="d-flex mx-auto px-4 py-2">
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>

                          {/* <a className="btn btn-primary btn-lg btn-block" href="index.html">Submit</a> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <ToastContainer position="top-end" style={{paddingTop:"3rem",width:'166px',right:'7px',top:'25px'}} >
          <Toast
            show={showmessage}
            bg={toastColor}
            onClose={() => setshowMesaage(false)}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto"></strong>
              <small className="text-muted"></small>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </ToastContainer> */}
      </div>
    </div>
  );
}

export default ResetPassword;
