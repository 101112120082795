// import React from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
// icons
import { BsFillCircleFill, BsPatchCheckFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import NotificationHeader from "./NotificationHeader";
import ROUTER_PATHS from "../../Constants/routerPath";
import { useAppContext } from "../../context/AppContext";
import { formatNumber } from "../../Utils/Utils";

export default function AppHeader() {
  const { appState } = useAppContext();

  const location = useLocation();

  const disableNavigation =
    location?.pathname?.toLowerCase() === ROUTER_PATHS.LIVE_CONTEST ||
    location?.pathname?.toLowerCase() === ROUTER_PATHS.LIVE_AD;
  // prettier-ignore
  const hasBanner = location?.pathname?.toLowerCase() !== ROUTER_PATHS.LIVE_CONTEST?.toLowerCase() && location?.pathname?.toLowerCase() !== ROUTER_PATHS.SINGLE_QUIZ_DETAIL?.toLowerCase() && location?.pathname?.toLowerCase() !== ROUTER_PATHS.ADS_LIST?.toLowerCase() && location?.pathname?.toLowerCase() !== ROUTER_PATHS.LIVE_AD?.toLowerCase() && location?.pathname?.toLowerCase() !== ROUTER_PATHS.APPLIED_CONTEST?.toLowerCase() && appState?.nextContest?.contestTime && !moment(appState?.nextContest?.contestEndTime).isBefore(new Date());

  return (
    <div className={hasBanner ? "hasBanner" : ""}>
      <div className="w-100 fixed-top">
        <div className="top-nav d-flex justify-content-between align-items-center p-3 position-relative">
          <div className="profile">
            <Link
              to={ROUTER_PATHS.USER_PROFILE}
              style={{ pointerEvents: disableNavigation ? "none" : "" }}
            >
              <img
                src={
                  appState?.profileImage
                    ? `${process.env.REACT_APP_IMAGE_PATH_URL}${appState?.profileImage}`
                    : "/assets/images/profile_pic.svg"
                }
                alt="Profile"
              />
              {appState?.isVerified && !appState?.isKycExpired && (
                <BsPatchCheckFill color="green" className="verified_icon" />
              )}

              {appState?.isKycExpired && (
                <RiErrorWarningFill color="grey" className="verified_icon" />
              )}
            </Link>
          </div>
          <Link
            to={ROUTER_PATHS.USER_HOME}
            style={{ pointerEvents: disableNavigation ? "none" : "" }}
            className="position-absolute start-50 translate-middle-x"
          >
            <div className="logo align-self-center">
              <img src="/assets/images/logo.png" alt="App-logo" />
            </div>
          </Link>
          <div
            className={`${
              location?.pathname?.toLowerCase() === ROUTER_PATHS.LIVE_CONTEST
                ? "invisible"
                : ""
            }`}
          >
            <Link
              to={ROUTER_PATHS.USER_WALLET}
              style={{ pointerEvents: disableNavigation ? "none" : "" }}
            >
              <div className="header_wallet">
                {appState?.wallet ? formatNumber(appState?.wallet) : "0"}
                <BsFillCircleFill color="#ffcc01" className="ms-1" />
                {/* <i className="coin me-4"></i> */}
              </div>
            </Link>
          </div>
        </div>
        {hasBanner && <NotificationHeader contest={appState?.nextContest} />}
      </div>
    </div>
  );
}
