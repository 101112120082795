import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
// components
import AppHeader from "../../Components/AppHeader/AppHeader";
import BottomNav from "../../Components/BottomNav/BottomNav";
// utils and helpers
import CONSTANTS from "../../Constants/global";
import api from "../../Service/apiService";
import {
  accountNoValidation,
  contactNoValidation,
  ifscValidation,
  nameValidation,
} from "../../Utils/Utils";
import { useAppContext } from "../../context/AppContext";
import VerifyModal from "../../Components/Modal/VerifyModal";
import { BsPatchCheckFill } from "react-icons/bs";

const Verify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { appState } = useAppContext();

  const [contact, setContact] = useState(appState?.phone || "");
  const [upiAddress, setUpiAddress] = useState("");
  const [bankDetails, setBankDetails] = useState({
    bank_name: "",
    account: "",
    confAcc: "",
  });
  const [ifsc, setIfsc] = useState("");
  const [ifscInfo, setIfscInfo] = useState({
    error: "",
    info: "",
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [verifyErrMsg, setVerifyErrMsg] = useState("");
  const [showOtp,setShowOtp] = useState(false);
  const [otp,setOtp] = useState("")

  const handleChangeBankDetails = (e) => {
    setBankDetails({
      ...bankDetails,
      [e.target.name]: e.target.value,
    });
  };

  const verifyPaymentMethod = async () => {
    try {
      setLoading(true);
      const data = {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        contact,
        ifsc: ifsc,
        bank_name: bankDetails.bank_name,
        account_number: bankDetails.account,
        address: upiAddress,
        otp: otp
      };

      const res = await api.post(CONSTANTS.PAYMENT_ACCOUNT_VERIFICATION, data);
      setTimeout(() => {
        toast.success(res.data?.message);
      }, 300);

      navigate("/payment", { replace: true });
    } catch (error) {
      if (
        error?.response?.data?.message?.includes("Name at bank is different")
      ) {
        setShowModal(true);
        setVerifyErrMsg(error?.response?.data?.message);
      } else {
        toast.error(
          error.response?.data?.message ||
            error?.message ||
            "Something went wrong, Try again"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSendOtp = async () => {
    try {
      setLoading(true);
      const res = await api.post(CONSTANTS.SEND_MOBILE_OTP, { phone: contact });
      if(res?.data?.status){
        setShowOtp(true);
        toast.success(res.data?.message || "OTP has been sent to your contact successfully")
      }else {
        toast.error(res.data?.message)
      }

    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          error?.message ||
          "Something went wrong, Try again"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = () => {
    if (!appState?.isPhoneVerified && !showOtp) {  
      handleSendOtp();
    } else verifyPaymentMethod();
  };

  const updateNameAndVerify = async () => {
    let bankingName = verifyErrMsg?.split("*")[1] || bankDetails.bank_name;
    let userName =
      verifyErrMsg?.match(/(?<=\=)(.*?)(?=\*)/) ||
      verifyErrMsg?.split("=")[1] ||
      "";

    if (Array.isArray(userName) && userName.length >= 2) {
      userName = userName[1].trim();
    }

    userName = userName || "";

    try {
      setLoading(true);
      const data = {
        name: userName?.trim(),
        email: localStorage.getItem("email"),
        contact,
        ifsc: ifsc,
        bankName: bankingName,
        account_number: bankDetails.account,
        address: upiAddress,
      };

      const res = await api.post(
        CONSTANTS.PAYMENT_ACCOUNT_RE_VERIFICATION,
        data
      );
      localStorage.setItem("name", userName);
      setTimeout(() => {
        toast.success(res.data?.message);
      }, 300);

      navigate("/payment", { replace: true });
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          error?.message ||
          "Something went wrong, Try again"
      );
    } finally {
      setLoading(false);
    }
  };

  const validation = (fn) => {
    const contValidation = contactNoValidation(contact);
    if (contValidation) return toast.error(contValidation);
    if (location?.state?.type === "bank_account") {
      const bankNameValidation = nameValidation(bankDetails?.bank_name);
      const accValidation = accountNoValidation(Number(bankDetails?.account));
      const ifscCodeValidation = ifscValidation(ifsc);
      if (
        bankNameValidation ||
        ifscCodeValidation ||
        accValidation ||
        bankDetails?.account !== bankDetails?.confAcc
      ) {
        toast.error(
          bankNameValidation ||
            accValidation ||
            ifscCodeValidation ||
            "Account number doesn't match"
        );
        return;
      }
    } else {
      if (/^[\w.-]+@[\w.-]+$/i.test(upiAddress) === false)
        return toast.error("Enter valid upi address");
    }

    fn();
  };

  useEffect(() => {
    if (!ifsc) return;
    if (ifscValidation(ifsc))
      return setIfscInfo({ error: "Invalid IFSC code" });

    const verifyInfo = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_IFSC_VERIFICATION_URL}/${ifsc}`
        );
        setIfscInfo({
          info: res.data?.BRANCH,
        });
      } catch (error) {
        setIfscInfo({
          error: "Invalid IFSC code",
        });
        console.log(error);
      }
    };

    verifyInfo();
  }, [ifsc]);

  return (
    <div>
      <AppHeader />
      <div className="page-container px-4 h-91 bg-white px-2 overflow-y-scroll">
        <h1 className="mt-3">Payments</h1> <hr className="m-0 mb-3" />
        <div className="form-group mt-3 ">
          <label>Name:</label>
          <input
            type="text"
            className="form-control w-100"
            placeholder="Enter your name"
            name="name"
            readOnly
            defaultValue={localStorage.getItem("name")}
          />
          {!appState?.isVerified && (
            <span className="text-danger">
              *Please check if name matches your banking name.{" "}
              <span
                className="text-success fw-bold cursor-pointer"
                onClick={() => navigate("/updateprofile", { replace: true })}
              >
                Change here
              </span>
            </span>
          )}
        </div>
        <div className="form-group mt-3 ">
          <label>Email address:</label>
          <input
            type="text"
            className="form-control w-100"
            placeholder="Enter your email"
            name="email"
            defaultValue={localStorage.getItem("email")}
            readOnly
          />
        </div>
        <div className="form-group mt-3">
          <label>Contact Number:</label>
          <div className="input-group">
            <input
              type="number"
              className="form-control position-relative"
              placeholder="Enter contact number"
              value={contact}
              name="contact"
              readOnly={appState?.isPhoneVerified}
              disabled={appState?.isPhoneVerified}
              onChange={(e) => setContact(e.target.value)}
            />
            {appState?.isPhoneVerified && (
              <div className="input-group-append position-absolute end-0 top-50 translate-middle">
                <BsPatchCheckFill color="green" style={{ fontSize: "20px" }} />
              </div>
            )}
          </div>
        </div>
        {location?.state?.type === "vpa" ? (
          <div>
            <div className="form-group mt-4">
              <label>Enter upi address:</label>
              <input
                type="text"
                className="form-control w-100"
                placeholder="Enter upi"
                value={upiAddress}
                name="upiAddress"
                onChange={(e) => setUpiAddress(e.target.value)}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="form-group mt-3 ">
              <label>Bank Name:</label>
              <input
                type="text"
                className="form-control w-100"
                placeholder="Enter your bank name"
                value={bankDetails?.bank_name}
                name="bank_name"
                onChange={handleChangeBankDetails}
              />
            </div>
            <div className="form-group mt-3 ">
              <label>Account Number:</label>
              <input
                type="number"
                className="form-control w-100"
                placeholder="Enter your account number"
                value={bankDetails?.account}
                name="account"
                onChange={handleChangeBankDetails}
              />
            </div>
            <div className="form-group mt-4">
              <label>Confirm Account Number:</label>
              <input
                type="number"
                className="form-control w-100"
                placeholder="Confirm account number"
                value={bankDetails?.confAcc}
                name="confAcc"
                onChange={handleChangeBankDetails}
              />
            </div>
            <div className="form-group mt-4">
              <label>Bank IFSC Code:</label>
              <input
                type="text"
                className="form-control w-100 mb-1"
                placeholder="Enter bank IFSC code"
                value={ifsc}
                name="ifsc"
                onChange={(e) => {
                  setIfscInfo({});
                  setIfsc(e.target.value);
                }}
              />
              {ifscInfo?.error && (
                <span className="text-danger mt-1">{ifscInfo?.error}</span>
              )}
              {ifscInfo?.info && (
                <span className="text-primary">Branch: {ifscInfo?.info}</span>
              )}
            </div>
          </div>
        )}
        {showOtp && (
          <div className="form-group mt-3 ">
            <label>Enter OTP:</label>
            <input
              type="number"
              className="form-control w-100"
              placeholder="Enter contact number"
              value={otp}
              name="otp"
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
        )}
        <div className="mt-4 d-flex justify-content-between align-items-center">
          <button
            className="btn bg-primary text-white px-4 py-2"
            onClick={() => navigate("/payment", { replace: "true" })}
          >
            Back
          </button>
          <button
            className="btn btn-success px-4 py-2"
            disabled={
              loading ||
              (location?.state?.type === "bank_account" && !ifscInfo?.info)
            }
            onClick={() => validation(handleVerify)}
          >
            {loading ? "Processing..." : "Verify"}
          </button>
        </div>
      </div>
      <BottomNav />
      <VerifyModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setVerifyErrMsg("");
        }}
        loading={loading}
        handleClick={updateNameAndVerify}
        verifyMsg={verifyErrMsg}
      />
    </div>
  );
};

export default Verify;
